import { LocalizedAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, EntityProperty, GridState } from "../../_models";

const getInitialValueForStates = (initialValue: any) => {
    const urls = [
        '/localizedalerts',
        '/localizedalerts/summary',
        '/localizedalerts/other/dns',
        '/localizedalerts/other/mfw',
        '/localizedalerts/other/idps',
        '/localizedalerts/other/nac',
        '/localizedalerts/other/waf',
        '/localizedalerts/other/twhunt',
        '/localizedalerts/other/dyt',
        '/localizedalerts/other/beaconing',
    ];

    const result = {};
    urls.forEach((u) => result[u] = initialValue);
    return result;
};

export interface LocalizedAlertsState {
    dateRange: {[key: string]: DateRange};
    dateRangeOption: {[key: string]: DateRangeOption};
    drilldownDateRange: {[key: string]: DateRange};
    filterConditions: {[key: string]: ConfiguredFilter[]};
    summaryDateRange: {[key: string]: DateRange};
    summaryDateRangeOption: {[key: string]: DateRangeOption};
    summaryDrilldownDateRange: {[key: string]: DateRange};
    summaryFilterConditions: {[key: string]: ConfiguredFilter[]};

    count: {[key: string]: number};
    isLoadingCount: {[key: string]: boolean};
    isCountLoaded: {[key: string]: boolean};
    summaryCount: {[key: string]: number};
    isLoadingSummaryCount: {[key: string]: boolean};
    isSummaryCountLoaded: {[key: string]: boolean};

    chartGroupingOption: {[key: string]: ChartGroupingOption};
    chartData: {[key: string]: any[]};
    isLoadingChart: {[key: string]: boolean};
    isChartLoaded: {[key: string]: boolean};

    summaryEntities: {[key: string]: EntityProperty[]};
    summaryData: {[key: string]: any[]};
    isLoadingSummary: {[key: string]: boolean};
    isSummaryLoaded: {[key: string]: boolean};
    summaryGridState: {[key: string]: GridState};

    localizedAlerts: {[key: string]: LocalizedAlert[]};
    isLoadingLocalizedAlerts: {[key: string]: boolean};
    isLocalizedAlertsLoaded: {[key: string]: boolean};
    summaryLocalizedAlerts: {[key: string]: LocalizedAlert[]};
    isLoadingSummaryLocalizedAlerts: {[key: string]: boolean};
    isSummaryLocalizedAlertsLoaded: {[key: string]: boolean};
    gridState: {[key: string]: GridState};

    error: {[key: string]: Error};
};

export const initialLocalizedAlertsState: LocalizedAlertsState = {
    dateRange: getInitialValueForStates(null), // TODO
    dateRangeOption: getInitialValueForStates(DateRangeOption.LastTwentyFourHours),
    drilldownDateRange: getInitialValueForStates(null),
    filterConditions: getInitialValueForStates([]),
    summaryDateRange: getInitialValueForStates(null), // TODO
    summaryDateRangeOption: getInitialValueForStates(DateRangeOption.LastTwentyFourHours),
    summaryDrilldownDateRange: getInitialValueForStates(null),
    summaryFilterConditions: getInitialValueForStates([]),
    
    count: getInitialValueForStates(0),
    isLoadingCount: getInitialValueForStates(false),
    isCountLoaded: getInitialValueForStates(false),
    summaryCount: getInitialValueForStates(0),
    isLoadingSummaryCount: getInitialValueForStates(false),
    isSummaryCountLoaded: getInitialValueForStates(false),

    chartGroupingOption: getInitialValueForStates(null),
    chartData: getInitialValueForStates(undefined),
    isLoadingChart: getInitialValueForStates(false),
    isChartLoaded: getInitialValueForStates(false),

    summaryEntities: getInitialValueForStates([]),
    summaryData: getInitialValueForStates(undefined),
    isLoadingSummary: getInitialValueForStates(false),
    isSummaryLoaded: getInitialValueForStates(false),
    summaryGridState: getInitialValueForStates(null),

    localizedAlerts: getInitialValueForStates(undefined),
    isLoadingLocalizedAlerts: getInitialValueForStates(false),
    isLocalizedAlertsLoaded: getInitialValueForStates(false),
    summaryLocalizedAlerts: getInitialValueForStates(undefined),
    isLoadingSummaryLocalizedAlerts: getInitialValueForStates(false),
    isSummaryLocalizedAlertsLoaded: getInitialValueForStates(false),
    gridState: getInitialValueForStates(null),

    error: getInitialValueForStates(null)
};
