import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError, from, of} from 'rxjs';

import {catchError, switchMap} from 'rxjs/operators';
import {AuthService, LoggerService} from '../../_services';
import {environment} from '../../../environments/environment';

@Injectable()
export class JwtInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private logger: LoggerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const lowercaseUrl = request.url.toLowerCase();
    if (lowercaseUrl.includes('/authenticate/') || lowercaseUrl.includes('/logger/logerror')) {
      return next.handle(request);
    }

    return from(this.authService.getToken()).pipe(switchMap((token) => {

      // add authorization header to request
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
      } else {
        throw new Error('Null Token');
      }

      // set accept and content type headers
      request = request.clone({
        setHeaders: {
          'Accept': 'application/json',
          'Content-Type': request.headers.get('content') || 'application/json'
        }
      });

      const apiRequest = request.clone({url: `${environment.apiEndpoint}${request.url}`});

      return next.handle(apiRequest).pipe(catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status == 401) {
          this.authService.reauthenticateAsync();
        }

        this.logger.handleError(error);

        throw new Error('Unauthorized');
      }));
    }),
      catchError(err => {
        return of(err);
      }));
  }
}
