<div
  class="login-container"
  [class.dark-mode]="themeService.isDarkModeEnabled$ | async"
  [ngClass]="{ 'component-loading-mask': loading }"
>
  <!--<div class="image-container threatwatch-logo"></div>
  <span>Welcome to the Security On-Demand Client Portal.</span>-->
  <div id="loginForm">
    <span>Welcome to DeepSeas Log Analytics</span>
    <form
      role="form"
      (submit)="login($event, username.value, password.value)"
      *ngIf="!showDUOAuthenticationModal"
    >
      <div>
        <label for="Username">Username</label>
        <input
          id="username"
          type="text"
          name="username"
          #username
          placeholder=""
        />
      </div>
      <div>
        <label for="Password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          #password
          placeholder=""
        />
      </div>
      <button id="login" [disabled]="loading" type="submit">Sign in</button>
      <span
        class="error-message"
        *ngIf="errorMessage && !showAuthenticationModal"
        >{{ errorMessage }}</span
      >
      <img src="../../assets/img/deepseas-logo.png" class="logo-container" />
    </form>

    <div class="box">
      <span class="line"></span>
      <span class="text">
        <span class="material-icons icon"> contact_support </span>
        We are excited to announce that we are migrating clients to our Unified
        DeepSeas Portal, where clients will be able to access all applications
        securely and easily.
        <ul>
          <li>
            If you have not been migrated, please look for email communications
            within the next couple of weeks for instructions on how you will
            need to proceed.
          </li>
          <li>
            If you have already been migrated, there is no action required at
            this time.
          </li>
        </ul>
        Soon all clients will no longer need to manually log in to this page and
        will be able to access it via a tile within the Unified DeepSeas Portal.
      </span>
    </div>
  </div>

  <iframe
    class="duo-2FA-iframe"
    id="duo_iframe"
    title="Two-Factor Authentication"
  ></iframe>
</div>

<modal
  [(isVisible)]="showAuthenticationModal"
  (event)="cancelAuthentication()"
  [enableResizing]="false"
>
  <div class="modal-title">Authentication</div>
  <div class="modal-body">
    <div class="input-container">
      <div *ngIf="!needsOptIn">
        <span
          >A verification code was sent to you via text message from our (844)
          335-5519 phone number. You have 5 minutes to enter the code before it
          expires.</span
        >
        <p>
          <input type="text" [(ngModel)]="smsCode" />
        </p>
      </div>

      <div *ngIf="needsOptIn">
        <span
          >By checking this box you are providing authorization to receive a
          text message to your cell phone for authentication purposes.</span
        >
        <p class="flex-row flex-align-center">
          <span>Allow text messaging: </span>
          <input type="checkbox" [(ngModel)]="optIn" />
        </p>
      </div>
    </div>
    <span
      class="error-message"
      *ngIf="errorMessage && showAuthenticationModal"
      >{{ errorMessage }}</span
    >

    <div class="button-container">
      <button
        class="square-button mr-md"
        (click)="needsOptIn ? confirmOptIn() : validateSMSCode()"
      >
        ok
      </button>
      <button class="square-button" (click)="cancelAuthentication()">
        cancel
      </button>
    </div>
  </div>
</modal>
