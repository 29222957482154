import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private viewContainer: ViewContainerRef;

  public setViewContainer(viewContainerRef: ViewContainerRef): void {
    this.viewContainer = viewContainerRef;
  }

  public getViewContainer(): ViewContainerRef {
    return this.viewContainer;
  }

  public clearViewContainer(): void {
    this.viewContainer.clear();
  }
}
