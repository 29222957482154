import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_services';
import { ProductGuard } from "./_services";
import { LoginComponent } from './login';
import { LogoutComponent } from './logout';
import { ErrorComponent } from './error/error.component';
import { SsoLoginComponent } from './sso-login';
import { OktaLoginComponent } from './okta-login';
import { CustomOktaCallbackComponent } from './okta-login/custom-okta-callback-component';
//import { OktaCallbackComponent } from '@okta/okta-angular';
import { PingComponent } from './ping/ping.component';
import { AppResolverService } from './app-resolver.service';

export const ROUTES: Routes = [
  { path: 'error', component: ErrorComponent },
  { path: 'ping', component: PingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'sso/callback', component: SsoLoginComponent },
  { path: 'sso/auth/:clientID', component: SsoLoginComponent },
  { path: 'sso/login/:clientID', component: SsoLoginComponent },
  { path: 'okta/login', component: OktaLoginComponent },
  { path: 'login/callback', component: CustomOktaCallbackComponent },
  { path: '', loadChildren: () => import('./+home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard, ProductGuard], resolve: { data: AppResolverService } }
];
