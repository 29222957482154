import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState, initialAppState } from "../state/app.state";
import { logsReducers } from "./logs.reducers";
import { analyticsReducers } from "./analytics.reducers";
import { localizedAlertsReducers } from "./localized-alerts.reducers";
import { CoreActionTypes } from "../actions/core.actions";
import { reportingReducers } from "./reporting.reducers";
import { userBehaviorReducers } from "./user-behavior.reducers";
import { assetBehaviorReducers } from "./asset-behavior.reducers";
import { alertScansReducers } from "./alert-scans.reducers";
import { Params, RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer, routerReducer, RouterReducerState } from "@ngrx/router-store";
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { url, root: { queryParams }} = routerState;
    const { params } = route;

    return { url, params, queryParams };
  }
}

export const selectReducerState = createFeatureSelector<
  RouterReducerState<RouterStateUrl>
>("router");

export const getRouterInfo = createSelector(
  selectReducerState,
  state => state.state
);

export function resetState(reducer) {
    return ((state, action) => {
        //console.log('[NgRx Action]', action.type);
        // if (state) {
        //     console.log('[NgRx State]', JSON.parse(JSON.stringify(state)));
        // }

        if (action.type === CoreActionTypes.RouteNavigation) {
            //console.log('route nav action', action)
            state = initialAppState;
        }

        return reducer(state, action);
    });
}

export const appReducers: ActionReducerMap<any, any> = {
    analytics: analyticsReducers,
    localizedAlerts: localizedAlertsReducers,
    logs: logsReducers,
    alertScans: alertScansReducers,
    assetBehavior: assetBehaviorReducers,
    userBehavior: userBehaviorReducers,
    reporting: reportingReducers,
    router: routerReducer
}

export const appMetaReducers = [resetState];
