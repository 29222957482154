import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import * as auth0 from 'auth0-js';
import { AuthService, LoggerService } from '../_services';

@Component({
  selector: 'sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.less']
})
export class SsoLoginComponent implements OnInit {

  private shouldAutoRedirect: boolean;
  showLoginButton: boolean;
  private clientID: string;
  errorMessage: string;
  loading: boolean = false;
  private returnUrl: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private logger: LoggerService,
    private location: Location
  ) {
    if (this.authService.loggedIn()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.showLoginButton = this.router.url.startsWith(`/sso/login`);

    this.activatedRoute.params.subscribe(params => {
      let clientID = params['clientID'];
      if (clientID) {
        this.clientID = clientID;
        this.shouldAutoRedirect = this.router.url === `/sso/auth/${this.clientID}`;
        if (this.shouldAutoRedirect) {
          this.login();
        }
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '/';
      if (params && params['clientID']) {
        this.clientID = params['clientID'];
        let code = params['code'];
        this.ssoLogin(code);
      }
    });
  }

  private ssoLogin(code) {
    this.authService.ssoLogin(this.clientID, code).subscribe(
      res => this.handleAuthenticationResult(res),
      error => this.handleError(error)
    );
  }

  private handleAuthenticationResult(response: any) {
    if (response.success) {
      this.authService.addLogoutUrl(`sso/login/${this.clientID}`);
      let data = response.data;
      if (this.isValidUrl(data)) {
        window.location.href = data;
        return;
      } else {
        let queryParams = {};
        this.router.navigate([this.returnUrl], { preserveFragment: true, queryParams: queryParams });
      }
    } else {
      this.location.go(`/sso/login/${this.clientID}`);
      this.showLoginButton = true;
      this.logger.handleError(response.error);
      this.errorMessage = response.error;
      this.loading = false;
    }
  }

  private isValidUrl(input: string) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    return regex.test(input);
  }

  login() {
    this.authService.getSSOInfo(this.clientID).subscribe(
      response => {
        if (response.success) {
          let data = response.data;
          let auth = new auth0.WebAuth({
            domain: data.domain,
            clientID: data.auth0ClientId
          });

          auth.authorize({
            connection: data.connection,
            responseType: 'code',
            redirectUri: `${window.location.protocol}//${window.location.host}/sso/callback?clientID=${
              this.clientID}&returnUrl=${this.returnUrl}`
          });
        }
      },
      error => this.handleError(error)
    );
  }

  private handleError(error: any): void {
    this.location.go(`/sso/login/${this.clientID}`);
    this.showLoginButton = true;
    this.logger.handleError(error);
    this.errorMessage = 'An Unknown SSO Login Failure occurred. Technical support has been notified.  If this continues to happen please contact Customer Support.';
    this.loading = false;
  }

}
