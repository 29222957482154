import { AnalyticsState, initialAnalyticsState } from "../state/analytics.state";
import { AnalyticsActions, AnalyticsActionTypes } from "../actions/analytics.actions";
import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";

export function analyticsReducers(
    state: AnalyticsState = initialAnalyticsState,
    action: AnalyticsActions
) {
    switch (action.type) {
        case AnalyticsActionTypes.PreloadConcierge:
            return {
                ...state,
                ...action.payload
            };

        case AnalyticsActionTypes.SetDateRange:
            return {
                ...state,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
        case AnalyticsActionTypes.SetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: action.payload
            };
        case AnalyticsActionTypes.ResetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: initialAnalyticsState.drilldownDateRange
            };

        case AnalyticsActionTypes.AddFilters:
            const filters = state.filterConditions.concat(action.payload);
            return {
                ...state,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(filters)
            };
        case AnalyticsActionTypes.RemoveFilter:
            return {
                ...state,
                filterConditions: state.filterConditions.filter(c => c !== action.payload)
            };
        case AnalyticsActionTypes.OverwriteFilters:
            return {
                ...state,
                filterConditions: action.payload
            };

        case AnalyticsActionTypes.FetchCount:
            return {
                ...state,
                isLoadingCount: true,
                isCountLoaded: false
            };
        case AnalyticsActionTypes.FetchCountSuccess:
            return { 
                ...state, 
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
        case AnalyticsActionTypes.FetchCountFailure:
            return {
                ...state,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };
        case AnalyticsActionTypes.FetchCounts:
            return {
                ...state,
                isLoadingCounts: true,
                isCountsLoaded: false
            };
        case AnalyticsActionTypes.FetchCountsSuccess:
            return {
                ...state,
                counts: action.payload,
                isLoadingCounts: false,
                isCountsLoaded: true
            };
        case AnalyticsActionTypes.FetchCountsFailure:
            return {
                ...state,
                counts: initialAnalyticsState.counts,
                isLoadingCounts: false,
                isCountsLoaded: false
            };

        case AnalyticsActionTypes.SetChartGroupingOption:
            return {
                ...state,
                chartGroupingOption: action.payload
            };
        case AnalyticsActionTypes.FetchChart:
            return {
                ...state,
                isLoadingChart: true,
                isChartLoaded: false
            };
        case AnalyticsActionTypes.FetchChartSuccess:
            return {
                ...state,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
        case AnalyticsActionTypes.FetchChartFailure:
            return {
                ...state,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };

        case AnalyticsActionTypes.FetchThreatWatchAlerts:
            return { 
                ...state, 
                isLoadingThreatWatchAlerts: true,
                isThreatWatchAlertsLoaded: false
            };
        case AnalyticsActionTypes.FetchThreatWatchAlertsSuccess:
            return { 
                ...state, 
                threatWatchAlerts: action.payload,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: true
            };
        case AnalyticsActionTypes.FetchThreatWatchAlertsFailure:
            return { 
                ...state, 
                error: action.payload,
                threatWatchAlerts: null,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };

        case AnalyticsActionTypes.FetchThreatWatchAlertsDashboard:
            return { 
                ...state, 
                isLoadingThreatWatchAlerts: true,
                isThreatWatchAlertsLoaded: false
            };
        case AnalyticsActionTypes.FetchThreatWatchAlertsDashboardSuccess:
            return { 
                ...state, 
                threatWatchAlerts: action.payload,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: true
            };
        case AnalyticsActionTypes.FetchThreatWatchAlertsDashboardFailure:
            return { 
                ...state, 
                error: action.payload,
                threatWatchAlerts: null,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };

        case AnalyticsActionTypes.ResetFetchedThreatWatchAlertsSuccess:
            return {
                ...state,
                threatWatchAlerts: initialAnalyticsState.threatWatchAlerts,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };
        case AnalyticsActionTypes.SetGridState:
            return {
                ...state,
                gridState: action.payload
            };

        case AnalyticsActionTypes.ResetState:
            return initialAnalyticsState;

        default:
            return state;
    }
}
