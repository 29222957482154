import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";
import { UserBehaviorState, initialUserBehaviorState } from "../state/user-behavior.state";
import { UserBehaviorActions, UserBehaviorActionTypes } from "../actions/user-behavior.actions";
import { initialAssetBehaviorState } from "../state/asset-behavior.state";

export function userBehaviorReducers(
    state: UserBehaviorState = initialUserBehaviorState,
    action: UserBehaviorActions
) {
    let result = { ...state };
    const initialState = JSON.parse(JSON.stringify(initialUserBehaviorState));

    switch (action.type) {
        case UserBehaviorActionTypes.SetSeedResource:
            return {
                ...state,
                seedResource: action.payload,
                behaviors: initialState.behaviors,
                dashboardData: initialState.dashboardData,
                isLoadingDashboard: false,
                isDashboardLoaded: false,
                analytics: initialState.analytics,
                connections: initialState.connections,
                eventsOfInterest: initialState.eventsOfInterest,
                localizedAlerts: initialState.localizedAlerts
            };
        case UserBehaviorActionTypes.SetAssociatedResources:
            return {
                ...state, 
                associatedResources: action.payload
            };
        
        case UserBehaviorActionTypes.FetchAssociatedResources:
            return {
                ...state,
                isLoadingAssociated: true,
                isAssociatedLoaded: false,
                dashboardData: initialState.dashboardData
            };
        case UserBehaviorActionTypes.FetchAssociatedResourcesSuccess:
            return {
                ...state,
                associatedResources: action.payload,
                isLoadingAssociated: false,
                isAssociatedLoaded: true
            };
        case UserBehaviorActionTypes.FetchAssociatedResourcesFailure:
            return {
                ...state,
                associatedResources: null,
                isLoadingAssociated: false,
                isAssociatedLoaded: false
            };

        case UserBehaviorActionTypes.SetDashboardIndex:
            return {
                ...state,
                dashboardIndex: action.payload
            };
        case UserBehaviorActionTypes.FetchDashboard:
            return {
                ...state,
                behaviors: initialState.behaviors,
                dashboardData: initialState.dashboardData,
                isLoadingDashboard: true,
                isDashboardLoaded: false
            };
        case UserBehaviorActionTypes.FetchDashboardSuccess:
            return {
                ...state,
                behaviors: action.payload.behaviors,
                dashboardData: action.payload.dashboardData,
                isLoadingDashboard: false,
                isDashboardLoaded: true
            };
        case UserBehaviorActionTypes.FetchDashboardFailure:
            return {
                ...state,
                behaviors: null,
                dashboardData: null,
                isLoadingDashboard: false,
                isDashboardLoaded: false
        }

        case UserBehaviorActionTypes.SetDateRange:
            result.dateRange = action.payload.dateRange;
            result.dateRangeOption = action.payload.dateRangeOption;
            result.analytics = {
                ...result.analytics,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.analytics.filterConditions.filter(f => f.conditions)
            };
            result.connections = {
                ...result.connections,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.connections.filterConditions.filter(f => f.conditions)
            };
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.eventsOfInterest.filterConditions.filter(f => f.conditions)
            };
            result.localizedAlerts = {
                ...result.localizedAlerts,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.localizedAlerts.filterConditions.filter(f => f.conditions)
            };
            return result;

        case UserBehaviorActionTypes.FetchTimeline:
            return {
                ...state,
                rawTimeline: undefined,
                isLoadingTimeline: true,
                isTimelineLoaded: false
            };
        case UserBehaviorActionTypes.FetchTimelineSuccess:
            return {
                ...state,
                rawTimeline: action.payload,
                isLoadingTimeline: false,
                isTimelineLoaded: true
            };
        case UserBehaviorActionTypes.FetchTimelineFailure:
            return {
                ...state,
                rawTimeline: null,
                isLoadingTimeline: false,
                isTimelineLoaded: false
            };

        case UserBehaviorActionTypes.SetAnalyticsDateRange:
            result.analytics = {
                ...result.analytics,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case UserBehaviorActionTypes.SetAnalyticsDrilldownDateRange:
            result.analytics = {
                ...result.analytics,
                drilldownDateRange: action.payload
            };
            return result;
        case UserBehaviorActionTypes.ResetAnalyticsDrilldownDateRange:
            result.analytics = {
                ...result.analytics,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case UserBehaviorActionTypes.AddAnalyticsFilters:
            const analyticsFilters = state.analytics.filterConditions.concat(action.payload);
            result.analytics = {
                ...result.analytics,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(analyticsFilters)
            };
            return result;
        case UserBehaviorActionTypes.RemoveAnalyticsFilter:
            result.analytics = {
                ...result.analytics,
                filterConditions: state.analytics.filterConditions.filter(c => c !== action.payload)
            };
            return result;
        case UserBehaviorActionTypes.OverwriteAnalyticsFilters:
            result.analytics = {
                ...result.analytics,
                filterConditions: action.payload
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCount:
            result.analytics = {
                ...result.analytics,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCountSuccess:
            result.analytics = {
                ...result.analytics,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCountFailure:
            result.analytics = {
                ...result.analytics,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCounts:
            result.analytics = {
                ...result.analytics,
                counts: initialState.analytics.counts,
                isLoadingCounts: true,
                isCountsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCountsSuccess:
            result.analytics = {
                ...result.analytics,
                counts: action.payload,
                isLoadingCounts: false,
                isCountsLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsCountsFailure:
            result.analytics = {
                ...result.analytics,
                counts: initialState.analytics.counts,
                isLoadingCounts: false,
                isCountsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.SetAnalyticsChartGroupingOption:
            result.analytics = {
                ...result.analytics,
                chartGroupingOption: action.payload
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsChart:
            result.analytics = {
                ...result.analytics,
                chartData: undefined,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsChartSuccess:
            result.analytics = {
                ...result.analytics,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true,
            };
            return result;
        case UserBehaviorActionTypes.FetchAnalyticsChartFailure:
            result.analytics = {
                ...result.analytics,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchThreatWatchAlerts:
            result.analytics = {
                ...result.analytics,
                isLoadingThreatWatchAlerts: true,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchThreatWatchAlertsSuccess:
            result.analytics = {
                ...result.analytics,
                threatWatchAlerts: action.payload,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchThreatWatchAlertsFailure:
            result.analytics = {
                ...result.analytics,
                error: action.payload,
                threatWatchAlerts: null,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetFetchedThreatWatchAlertsSuccess:
            result.analytics = {
                ...result.analytics,
                threatWatchAlerts: initialState.analytics.threatWatchAlerts,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetAnalyticsState:
            result.analytics = {
                ...initialState.analytics,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case UserBehaviorActionTypes.SetConnectionsDateRange:
            result.connections = {
                ...result.connections,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
            };
            return result;
        case UserBehaviorActionTypes.SetConnectionsDrilldownDateRange:
            result.connections = {
                ...result.connections,
                drilldownDateRange: action.payload,
            };
            return result;
        case UserBehaviorActionTypes.ResetConnectionsDrilldownDateRange:
            result.connections = {
                ...result.connections,
                drilldownDateRange: initialState.connections.drilldownDateRange,
            };
            return result;
        case UserBehaviorActionTypes.AddConnectionsFilters:
            const connectionsFilters = state.connections.filterConditions.concat(action.payload);
            result.connections = {
                ...result.connections,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(connectionsFilters),
            };
            return result;
        case UserBehaviorActionTypes.RemoveConnectionsFilter:
            result.connections = {
                ...result.connections,
                filterConditions: state.connections.filterConditions.filter(c => c !== action.payload),
            };
            return result;
        case UserBehaviorActionTypes.OverwriteConnectionsFilters:
            result.connections = {
                ...result.connections,
                filterConditions: action.payload,
            };
            return result;
        case UserBehaviorActionTypes.FetchConnectionsCount:
            result.connections = {
                ...result.connections,
                isLoadingCount: true,
                isCountLoaded: false,
            };
            return result;
        case UserBehaviorActionTypes.FetchConnectionsCountSuccess:
            result.connections = {
                ...result.connections,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
            return result;
        case UserBehaviorActionTypes.FetchConnectionsCountFailure:
            result.connections = {
                ...result.connections,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false,
            };
            return result;
        case UserBehaviorActionTypes.SetConnectionsChartGroupingOption:
            result.connections = {
                ...result.connections,
                chartGroupingOption: action.payload,
            }
            return result;
        case UserBehaviorActionTypes.FetchConnectionsChart:
            result.connections = {
                ...result.connections,
                isLoadingChart: true,
                isChartLoaded: false,
            }
            return result;
        case UserBehaviorActionTypes.FetchConnectionsChartSuccess:
            result.connections = {
                ...result.connections,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true,
            }
            return result;
        case UserBehaviorActionTypes.FetchConnectionsChartFailure:
            result.connections = {
                ...result.connections,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchConnections:
            result.connections = {
                ...result.connections,
                isLoadingLogs: true,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchConnectionsSuccess:
            result.connections = {
                ...result.connections,
                logs: action.payload,
                isLoadingLogs: false,
                isLogsLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchConnectionsFailure:
            result.connections = {
                ...result.connections,
                error: action.payload,
                logs: null,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetFetchedConnectionsSuccess:
            result.connections = {
                ...result.connections,
                logs: initialState.connections.logs,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetConnectionsState:
            result.connections = {
                ...initialState.connections,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case UserBehaviorActionTypes.SetEventsOfInterestDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case UserBehaviorActionTypes.SetEventsOfInterestDrilldownDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                drilldownDateRange: action.payload
            };
            return result;
        case UserBehaviorActionTypes.ResetEventsOfInterestDrilldownDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case UserBehaviorActionTypes.AddEventsOfInterestFilters:
            const eoiFilters = state.eventsOfInterest.filterConditions.concat(action.payload);
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(eoiFilters)
            };
            return result;
        case UserBehaviorActionTypes.RemoveEventsOfInterestFilter:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: state.eventsOfInterest.filterConditions.filter(c => c !== action.payload),
            };
            return result;
        case UserBehaviorActionTypes.OverwriteEventsOfInterestFilters:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: action.payload,
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestCount:
            result.rawTimeline = initialState.rawTimeline;
            result.isLoadingTimeline = false;
            result.isTimelineLoaded = false;
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestCountSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestCountFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false,
            };
            return result;
        case UserBehaviorActionTypes.SetEventsOfInterestChartGroupingOption:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartGroupingOption: action.payload
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestChart:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestChartSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestChartFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterest:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingLogs: true,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                logs: action.payload,
                isLoadingLogs: false,
                isLogsLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchEventsOfInterestFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                error: action.payload,
                logs: null,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetFetchedEventsOfInterestSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                logs: initialState.eventsOfInterest.logs,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetEventsOfInterestState:
            result.eventsOfInterest = {
                ...initialState.eventsOfInterest,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case UserBehaviorActionTypes.SetLocalizedAlertsDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case UserBehaviorActionTypes.SetLocalizedAlertsDrilldownDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                drilldownDateRange: action.payload
            };
            return result;
        case UserBehaviorActionTypes.ResetLocalizedAlertsDrilldownDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case UserBehaviorActionTypes.AddLocalizedAlertsFilters:
            const localizedAlertFilters = state.localizedAlerts.filterConditions.concat(action.payload);
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(localizedAlertFilters)
            };
            return result;
        case UserBehaviorActionTypes.RemoveLocalizedAlertsFilter:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: state.localizedAlerts.filterConditions.filter(c => c !== action.payload)
            };
            return result;
        case UserBehaviorActionTypes.OverwriteLocalizedAlertsFilters:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: action.payload
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsCount:
            result.rawTimeline = initialState.rawTimeline;
            result.isLoadingTimeline = false;
            result.isTimelineLoaded = false;
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: 0,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsCountSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsCountFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.SetLocalizedAlertsChartGroupingOption:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartGroupingOption: action.payload
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsChart:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsChartSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsChartFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlerts:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                isLoadingLocalizedAlerts: true,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                localizedAlerts: action.payload,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: true
            };
            return result;
        case UserBehaviorActionTypes.FetchLocalizedAlertsFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                error: action.payload,
                localizedAlerts: null,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetFetchedLocalizedAlertsSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                localizedAlerts: initialState.localizedAlerts.localizedAlerts,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case UserBehaviorActionTypes.ResetLocalizedAlertsState:
            result.localizedAlerts = {
                ...initialState.localizedAlerts,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        default:
            return state;
    }
}
