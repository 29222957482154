import { Entity } from './entity.model';
import { EntityProperty } from './entity-property';

export class ThreatWatchAlert extends Entity {

  get id(): number {
    return this.data && this.data[ThreatWatchAlert._id.fieldName];
  }

  static get _id(): EntityProperty {
    return { fieldName: 'id', columnName: 'CorrelatedAlertID', filterType: 'number', displayName: 'Correlated ID' };
  }

  get commonality(): string {
    return this.data && this.data[ThreatWatchAlert._commonality.fieldName];
  }

  static get _commonality(): EntityProperty {
    return { fieldName: 'cm', columnName: 'Commonality', filterType: 'string', displayName: 'Commonality' };
  }

  get firstSeen(): string {
    return this.data && this.data[ThreatWatchAlert._firstSeen.fieldName];
  }

  static get _firstSeen(): EntityProperty {
    return { fieldName: 'fst', columnName: 'FirstSeen', filterType: 'string', displayName: 'First Seen {0}' };
  }

  get lastSeen(): string {
    return this.data && this.data[ThreatWatchAlert._lastSeen.fieldName];
  }

  static get _lastSeen(): EntityProperty {
    return { fieldName: 'lst', columnName: 'LastSeen', filterType: 'string', displayName: 'Last Seen {0}' };
  }

  get host(): string {
    return this.data && this.data[ThreatWatchAlert._host.fieldName];
  }

  static get _host(): EntityProperty {
    return { fieldName: 'hst', columnName: 'host', filterType: 'string', displayName: 'Host', placeholder: 'i.e. Machine', fieldLength: 64 };
  }

  get user(): string {
    return this.data && this.data[ThreatWatchAlert._user.fieldName];
  }

  static get _user(): EntityProperty {
    return { fieldName: 'usr', columnName: 'user', filterType: 'string', displayName: 'User', placeholder: 'i.e. john.smith', fieldLength: 64 };
  }

  get ip(): string {
    return this.data && this.data[ThreatWatchAlert._ip.fieldName];
  }

  static get _ip(): EntityProperty {
    return { fieldName: 'ip', columnName: 'IPInt', filterType: 'ipaddress', displayName: 'IP Address', placeholder: 'i.e. 255.255.255.255' };
  }

  get severity(): number {
    return this.data && this.data[ThreatWatchAlert._severity.fieldName];
  }

  static get _severity(): EntityProperty {
    return { fieldName: 'sev', columnName: 'OverallSeverity', filterType: 'severity', displayName: 'Severity', placeholder: 'i.e. Critical' };
  }

  get category(): string {
    return this.data && this.data[ThreatWatchAlert._category.fieldName];
  }

  static get _category(): EntityProperty {
    return { fieldName: 'cat', columnName: 'category', filterType: 'threatWatchAlertCategory', displayName: 'Category', placeholder: 'i.e. Exploit' };
  }

  get count(): number {
    return this.data && this.data[ThreatWatchAlert._count.fieldName];
  }

  static get _count(): EntityProperty {
    return { fieldName: 'cnt', columnName: 'CorrelatedCount', filterType: 'number', displayName: 'Count' };
  }

  get untrustScore(): number {
    return this.data && this.data[ThreatWatchAlert._untrustScore.fieldName];
  }

  static get _untrustScore(): EntityProperty {
    return { fieldName: 'utrst', columnName: 'UntrustScore', filterType: 'number', displayName: 'Untrust Score' };
  }

  get flowScore(): number {
    return this.data && this.data[ThreatWatchAlert._flowScore.fieldName];
  }

  static get _flowScore(): EntityProperty {
    return { fieldName: 'flw', columnName: 'FlowScore', filterType: 'number', displayName: 'Flow Score' };
  }

  get trustScore(): number {
    return this.data && this.data[ThreatWatchAlert._trustScore.fieldName];
  }

  static get _trustScore(): EntityProperty {
    return { fieldName: 'trst', columnName: 'TrustScore', filterType: 'number', displayName: 'Trust Score' };
  }

  get enforcementScore(): number {
    return this.data && this.data[ThreatWatchAlert._enforcementScore.fieldName];
  }

  static get _enforcementScore(): EntityProperty {
    return { fieldName: 'enf', columnName: 'EnforcementScore', filterType: 'number', displayName: 'Enforcement Score' };
  }

  get alertCategory(): string {
    return this.data && this.data[ThreatWatchAlert._alertCategory.fieldName];
  }

  static get _alertCategory(): EntityProperty {
    return { fieldName: 'alct', columnName: 'AlertCategory', filterType: 'string', displayName: 'Security Event Category' };
  }

  get scanDatabase(): string {
    return this.data && this.data[ThreatWatchAlert._scanDatabase.fieldName];
  }

  static get _scanDatabase(): EntityProperty {
    return { fieldName: 'sdb', columnName: 'ScanDatabase', filterType: 'string', displayName: 'Scan Database' };
  }

  get assetPriorityModifyDate(): string {
    return this.data && this.data[ThreatWatchAlert._assetPriorityModifyDate.fieldName];
  }

  static get _assetPriorityModifyDate(): EntityProperty {
    return { fieldName: 'apmd', columnName: 'AssetPriorityModifyDate', filterType: 'string', displayName: 'Asset Priority Modify Date' };
  }

  get canVisualize(): string {
    return this.data && this.data[ThreatWatchAlert._canVisualize.fieldName];
  }

  static get _canVisualize(): EntityProperty {
    return { fieldName: 'cv', columnName: 'IsDirty', filterType: 'boolean', displayName: 'Can Visualize' };
  }

  get ipTrusted(): string {
    return this.data && this.data[ThreatWatchAlert._ipTrusted.fieldName];
  }

  static get _ipTrusted(): EntityProperty {
    return { fieldName: 'ipt', columnName: 'isIPTrusted', filterType: 'boolean', displayName: 'IP Trusted' };
  }

  get clientID(): string {
    return this.data && this.data[ThreatWatchAlert._clientID.fieldName];
  }

  static get _clientID(): EntityProperty {
    return { fieldName: 'cid', columnName: 'clientid', filterType: 'string', displayName: 'Client' };
  }

  get localizedAlertID(): string {
    return this.data && this.data[ThreatWatchAlert._localizedAlertID.fieldName];
  }

  static get _localizedAlertID(): EntityProperty {
    return { fieldName: 'gaid', columnName: 'GlobalAlertID', filterType: 'string', displayName: 'Security Event ID' };
  }

  get caseId(): string {
    return this.data && this.data[ThreatWatchAlert._caseId.fieldName];
  }

  static get _caseId(): EntityProperty {
    return { fieldName: 'csid', columnName: 'CaseId', filterType: 'string', displayName: 'Case Number' };
  }

  get caseSysId(): string {
    return this.data && this.data[ThreatWatchAlert._caseSysId.fieldName];
  }

  static get _caseSysId(): EntityProperty {
    return { fieldName: 'csyid', columnName: 'CaseSysId', filterType: 'string', displayName: 'Case SysId' };
  }

  get investigation(): number {
    return this.data && this.data[ThreatWatchAlert._investigation.fieldName];
  }

  static get _investigation(): EntityProperty {
    return { fieldName: 'inv', columnName: 'CaseSubCategory', filterType: 'string', displayName: 'Investigation' };
  }

  get response(): number {
    return this.data && this.data[ThreatWatchAlert._response.fieldName];
  }

  static get _response(): EntityProperty {
    return { fieldName: 'res', columnName: 'Responded', filterType: 'string', displayName: 'Response' };
  }

  get notified(): number {
    return this.data && this.data[ThreatWatchAlert._notified.fieldName];
  }

  static get _notified(): EntityProperty {
    return { fieldName: 'not', columnName: 'Notified', filterType: 'boolean', displayName: 'Notification' };
  }

  get technique(): number {
    return this.data && this.data[ThreatWatchAlert._technique.fieldName];
  }

  static get _technique(): EntityProperty {
    return { fieldName: 'tec', columnName: 'Technique', filterType: 'string', displayName: 'Techniques' };
  }

  get knownUnknown(): number {
    return this.data && this.data[ThreatWatchAlert._knownUnknown.fieldName];
  }

  static get _knownUnknown(): EntityProperty {
    return { fieldName: 'knownUnknown', columnName: 'Category', filterType: 'threatWatchAlertCategory', displayName: 'Known/Unknown' };
  }
}
