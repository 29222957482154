import { AlertScan, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption } from "../../_models";

export interface AlertScansState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];
    isActiveScansIncluded: boolean;
    isPenTestScansIncluded: boolean;

    count: number;
    isLoadingCount: boolean;
    isCountLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;

    scans: AlertScan[];
    isLoadingScans: boolean;
    isScansLoaded: boolean;
    
    error: Error;
};

export const initialAlertScansState: AlertScansState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],
    isActiveScansIncluded: false,
    isPenTestScansIncluded: false,

    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,

    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,

    scans: undefined,
    isLoadingScans: false,
    isScansLoaded: false,

    error: null
};