import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, LoggerService } from '../_services';
import * as Duo from '../../assets/script/Duo-Web-v2';
import { UserService } from '../+home/_services/user.service';
import { ThemeService } from '../+home/_services/theme.service';

class DuoSettings {
  host: string;
  sig_request: object;
};

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  errorMessage: string;
  loading: boolean = false;
  private returnUrl: string;

  duo: DuoSettings;

  showAuthenticationModal: boolean = false;
  showDUOAuthenticationModal: boolean = false;

  optIn: boolean;
  smsCode: string;
  private clientID: string;
  private userID: string;
  needsOptIn: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private userService: UserService,
    private logger: LoggerService,
    public themeService: ThemeService,
  ) {

  }

  async ngOnInit() {
    if (this.route.snapshot.queryParams['status']) {
      // eventually we might have different statuses, but for now treat as expired session
      this.errorMessage = 'Your session has expired. Please log in again.';
    }

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  twoFactorVerify(response: any) {
    this.authService.verifyDUOResponse(this.clientID, response.elements.sig_response.value).subscribe(
      response => this.handleAuthenticationResult(response),
      error => this.handleError(error));
  }

  private isValidUrl(input: string) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (regex.test(input)) {
      return true;
    } else {
      return false;
    }
  }

  login(event: any, username: string, password: string) {
    event.preventDefault();
    this.loading = true;
    this.errorMessage = '';

    if (username === '' || password === '') {
      this.errorMessage = 'Please input a valid username and password.';
      this.loading = false;
    } else {
      this.userID = username;
      this.userService.setUsername(username);
      this.authService.login(username, password).subscribe(
        response => this.handleAuthenticationResult(response),
        error => this.handleError(error)
      );
    }
  }

  private handleAuthenticationResult(response: any) {
    if (response.success) {
      
      let data = response.data;

      this.clientID = data.clientID;

      if (data.needsOptIn || data.needsSMSCode) {
        this.loading = false;
        this.showAuthenticationModal = true;
        this.needsOptIn = data.needsOptIn;

      } else if (data.needsDUOAuth) {
        this.loading = false;

        this.showDUOAuthenticationModal = true;

        Duo.init({
          iframe: "duo_iframe",
          host: data.duoHost,
          sig_request: data.duoSignedRequest,
          submit_callback: this.twoFactorVerify.bind(this),
        });
      } else if (this.isValidUrl(data)) {
        window.location.href = data;
        return;
      }
      else {
        let queryParams = {};

        for (let param of Object.keys(this.route.snapshot.queryParams)) {
          if (param !== 'returnUrl') {
            queryParams[param] = this.route.snapshot.queryParams[param];
          }
        }
        this.router.navigate([this.returnUrl], { preserveFragment: true, queryParams: queryParams });
      }
    }
    else {
      this.logger.handleError({ "message": response.data.error, "stack": "handleAuthenticationResult" });
      this.errorMessage = response.data.error;
      this.loading = false;
    }
  }

  private handleError(error: any): void {
    this.logger.handleError(error);
    this.errorMessage = 'The server is currently unavailable. Please try again later.';
    this.loading = false;
  }

  private toggleModal() {
    this.showAuthenticationModal = !this.showAuthenticationModal;
  }

  cancelAuthentication() {
    this.toggleModal();
  }

  validateSMSCode() {
    this.loading = true;

    this.authService.validateSMSCode(this.smsCode).subscribe(
      response => {
        this.handleAuthenticationResult(response);
        if (response.success) {
          this.toggleModal();
        }
      },
      error => this.handleError(error)
    );
  }

  confirmOptIn() {
    this.loading = true;

    this.authService.confirmOptIn(this.optIn).subscribe(
      response => {
        this.handleAuthenticationResult(response);
      },
      error => this.handleError(error)
    );
  }
}
