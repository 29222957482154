import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services';


@Component({
  selector: 'logout',
  template: ''
})
export class LogoutComponent {

  constructor(private router: Router, private authService: AuthService) {
    
  }

  async ngOnInit() {
    await this.authService.logoutAsync();
  }
}
