import { Component, Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from '../_services';

@Component({
  selector: 'okta-login',
  templateUrl: './okta-login.component.html',
  styleUrls: ['./okta-login.component.css']
})
export class OktaLoginComponent {
  title = 'okta-hosted-login';

  constructor(private authService: AuthService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {
  }

  ngOnInit() {
    this.authService.removeClassicLoginCache();
    this.oktaAuth.signInWithRedirect();
  }
}
