import { EntityProperty } from './entity-property';
import { DateRange } from './datetime.model';

export enum ConciergeType {
	Basic,
	Advanced,
	Raw
}

export class GroupSettings {
	groupName: string;
	prefix: string;
}

export class FilterConfiguration {
	entity: EntityProperty;
	isAdvancedOnly: boolean = false;
	groupSettings?: GroupSettings;
	suppressSuggest?: boolean;
}

export class GroupSetting {
	prefix: string;
	entity: EntityProperty;
}

export class StoredFilterConfiguration {
	entity?: EntityProperty;
	groupName?: string;
	groupSettings?: GroupSetting[];
	allowedConciergeTypes?: ConciergeType[] = [ConciergeType.Basic];
  suppressSuggest?: boolean;
  allowBulk?: boolean;
}

export class ColumnData {
	display?: string;
	column: string;
}

export class BasicFilterConfiguration {
	display: string;
	placeholder: string;
	filterType: string;
	columns: ColumnData[];
}

export class FilterCondition {
	filterType: string;
	operation: string;
	columns: string[];
	value: string;
}

export class ConfiguredFilter {
	display: string;
	conditions: FilterCondition[];
	extraData?: any;
}

export class ChartGroupingOption {
	value: EntityProperty;
	name: string;
	dynamicColors?: boolean;
}

export class GridState {
	columnState?: any;
	columnGroupState?: any; // note: do not use, this is now integrated into column state
	sortModel?: any; // note: do not use, this is now integrated into column state
}

export class ConciergeFilter {
  configuredFilters: ConfiguredFilter[];
  dateRange: DateRange;
  dateRangeIndex: number;
  chartGroupingOption: ChartGroupingOption;
  reportViewID : number;
}
