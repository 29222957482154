import { ReportViewModel } from "../../_models";

export interface ReportingState {
    analyticsReportingViews: ReportViewModel[];
    isAnalyticsReportingViewsLoading: boolean;
    isAnalyticsReportingViewsLoaded: boolean;

    localizedAlertsReportingViews: ReportViewModel[];
    isLocalizedAlertsReportingViewsLoading: boolean;
    isLocalizedAlertsReportingViewsLoaded: boolean;

    localizedAlertSummaryReportingViews: ReportViewModel[];
    isLocalizedAlertSummaryReportingViewsLoading: boolean;
    isLocalizedAlertSummaryReportingViewsLoaded: boolean;

    logsReportingViews: ReportViewModel[];
    isLogsReportingViewsLoading: boolean;
    isLogsReportingViewsLoaded: boolean;

    logSummaryReportingViews: ReportViewModel[];
    isLogSummaryReportingViewsLoading: boolean;
    isLogSummaryReportingViewsLoaded: boolean;

    logsBetaReportingViews: ReportViewModel[];
    isLogsBetaReportingViewsLoading: boolean;
    isLogsBetaReportingViewsLoaded: boolean;
};

export const initialReportingState: ReportingState = {
    analyticsReportingViews: [],
    isAnalyticsReportingViewsLoading: false,
    isAnalyticsReportingViewsLoaded: false,

    localizedAlertsReportingViews: [],
    isLocalizedAlertsReportingViewsLoading: false,
    isLocalizedAlertsReportingViewsLoaded: false,

    localizedAlertSummaryReportingViews: [],
    isLocalizedAlertSummaryReportingViewsLoading: false,
    isLocalizedAlertSummaryReportingViewsLoaded: false,

    logsReportingViews: [],
    isLogsReportingViewsLoading: false,
    isLogsReportingViewsLoaded: false,

    logSummaryReportingViews: [],
    isLogSummaryReportingViewsLoading: false,
    isLogSummaryReportingViewsLoaded: false,

    logsBetaReportingViews: [],
    isLogsBetaReportingViewsLoading: false,
    isLogsBetaReportingViewsLoaded: false
};