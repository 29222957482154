import { QueryFilterCriteriaWizardData } from "./filter.model";
import { Injectable } from "@angular/core";
import { ChartGroupingOption, GridState, ConfiguredFilter } from "./concierge.model";
import { DateRange } from "./datetime.model";

export class ReportTemplate {
  // Report and ReportView has a 1-1 relationship with ReportTemplate, and extend this class
  description: string;

  reportTypes : string[] = new Array<string>(); // 'Alerts', 'Logs'

  createdBy : string = '';
  modifiedBy : string = '';
  isCanned?: boolean = false;
  reportTemplateName : string = '';
  private ReportTemplateID: number = 0; // NOTE - naming for json 
  get reportTemplateID(): number {
    return this.ReportTemplateID;
  }
  set reportTemplateID(id : number) {
    if(id == null || id == undefined) {
      this.ReportTemplateID = 0;
    }
    else {
      this.ReportTemplateID = id;
    }
  }
  public static IsReportTemplate(obj : any) : boolean{
    var ret : boolean = false;
    if (obj && obj.reportType) {
      ret = obj.reportType == ReportState.Template;
    }
    return ret;
  }

}

export class ReportBase extends ReportTemplate {


  protected static containsPropertyName(obj : any, propertyName : string) : boolean{
    var ret : boolean = false;

    if (obj) {
      var propertyNames : string[] = Object.getOwnPropertyNames(obj);
      ret = propertyNames.includes(propertyName);  
    }
    return ret;
  }
  public reportTypeString?: string;  // "ThreatWatch Alerts", 'Localized Alerts', 'Logs', 'Analyzed Alerts' (not sure about Analyzed Alerts)

  private  ReportCategoryID: number = 0; // NOTE - naming for json 
  get reportCategoryID(): number {
    return this.ReportCategoryID;
  }
  set reportCategoryID(id : number) {
    if(id == null || id == undefined) {
      this.ReportCategoryID = 0;
    }
    else {
      this.ReportCategoryID = id;
    }
  }
  private ReportSubCategoryID: number = 0; // NOTE - naming for json 
  get reportSubCategoryID(): number {
    return this.ReportSubCategoryID;
  }
  set reportSubCategoryID(id : number) {
    if(id == null || id == undefined) {
      this.ReportSubCategoryID = 0;
    }
    else {
      this.ReportSubCategoryID = id;
    }
  }


  reportCategory : string;

  protected InitReportBase() : void {
    this.reportCategoryID = 0;
    this.reportSubCategoryID = 0;
    this.reportTemplateID = 0;
    this.description = "";
    this.reportCategory = "";

  }
  public static getFilterKeyFromReportTypeNameEx(reportTypeName: string): string {
    var filterKey: string;
    if (reportTypeName) {
      var reportTypenameLowerCaseNoSpaces = reportTypeName.toLowerCase().replace(' ', '');
      filterKey = reportTypenameLowerCaseNoSpaces
        .replace('analyzed alerts', 'analytics')
        .replace('threatwatchalerts', 'analytics')
        .replace(' ', '')
        .replace('localizedalertssummary', 'localizedalerts/summary')
        .replace('logssummary', 'logs/summary');
    }
    else {
      throw new Error('getFilterKeyFromReportTypeNameEx(), reportTypeName ==  null');
    }
    return filterKey;
  }
  public static getFilterKeyFromReportTypeName(reportBase : ReportBase) : string {
    var filterKey : string;
    var reportTypeString : string;
    if (reportBase.isCanned) {
      // TODO - figure out the templates logic here...
      filterKey = FilterKeyName.Templates;
    }
    else {
      if (reportBase.reportTypeString) {
        reportTypeString = reportBase.reportTypeString;
      } 
      else {
        throw new Error('getFilterKeyFromReportTypeName(), reportType ==  null');
      }
      filterKey = ReportBase.getFilterKeyFromReportTypeNameEx(reportTypeString);
    }    
    return filterKey;
  }
  public static getReportTypeNameFromFilterKey(filterKey : string) : ReportTypeName {
    var ret : ReportTypeName = ReportTypeName.Undefined;
    
    if (filterKey) {
      if (filterKey.toLowerCase() == FilterKeyName.Analytics.toLowerCase()) {
        ret = ReportTypeName.ThreatWatchAlertsName;
      }
      else if (filterKey.toLowerCase() == FilterKeyName.Logs.toLowerCase()) {
        ret = ReportTypeName.LogsName;
      }
      else if (filterKey.toLowerCase() == FilterKeyName.LocalizedAlerts.toLowerCase()) {
        ret = ReportTypeName.LocalizedAlertsName;
      }
      else if (filterKey.toLowerCase() == FilterKeyName.LogsSummary.toLowerCase()) {
        ret = ReportTypeName.LogsSummaryName;
      }
      else if (filterKey.toLowerCase() == FilterKeyName.LocalizedAlertsSummary.toLowerCase()) {
        ret = ReportTypeName.LocalizedAlertsSummaryName;
      }
      else {
        throw new Error('getReportTypeNameFromFilterKey(), Invalid filterKey=' + filterKey);
      }
    }
    else {
      throw new Error('getReportTypeNameFromFilterKey(), Invalid filterKey=' + filterKey);
    }
    return ret;
  }


}

export class ReportViewBase extends ReportBase { 

  isSharable  : boolean = false;
  filter?: string;

}


export class ReportView extends ReportViewBase {
  public constructor()  {
    super();
    var dummy = false;
  }
  ID?: number;
  name?: string;

  isGlobal?: boolean;

  userId?: number;
  
  public static IsReportView(obj : any) : boolean{
    var ret : boolean = ReportView.containsPropertyName(obj, 'reportViewID');
    return ret;
  }


  public static FilterThreatWatchAlerts<T extends ReportView>(reportViews : T[]) : T[] {
    var filteredReports : T[] = reportViews.filter(r => r.reportTypeString.indexOf('ThreatWatch Alerts') > -1);
    return filteredReports;
  }

  public static FilterLocalizedAlerts<T extends ReportView>(reportViews : T[]) : T[] {
    var filteredReports : T[] = reportViews.filter(r => r.reportTypeString.indexOf('Localized Alerts') > -1);
    return filteredReports;
  }

}


export class ReportViewModel extends ReportView {
  public constructor()  {
    super();
    var dummy = false;
  }
  reportViewID?: number;
  //templateType?: TemplateType;
  overwrite?: boolean;
  shareUsers?: any[];
  conciergeConfiguration?: ReportViewConciergeConfiguration;
  displayFilters?: string;
}

export enum ReportState {
  Scheduled = 0,
  Completed = 1,
  Template = 2,
  Saved = 3
}


export enum EditMode {
  Create = 1 << 0, 
  Modify = 1 << 1, 
  Share = 1 << 2, 
  Schedule = 1 << 3,
}

export enum SaveStatus {
  Default,
  ReportView,
  Failed,
  Success,
  Overwrite,
  Static
}

export class Report extends ReportBase  {
  static readonly RUN_NOW = 0;
  static readonly EVERY_DAY = 1;
  static readonly WEEK = 2;
  static readonly MONTH = 3;

  reportName: string;

  reportInfo : string;

  clientID : string = '';

  format: string;
  recipients: string;
  frequency: number;
  timeZone: string;
  subscriptionID: string;
  path: string;
  reportID: number = 0;


  mostRecentFileName : string = '';
  conciergeFilter : string;

  public constructor() {
    super();
    this.InitReport();
  }

  public static getReportTypesGui(): string[] {
    var ret : string[] = ['Analytics', 'Logs', 'All'];
    return ret;
  }

  public static FilterThreatWatchAlerts<T extends Report>(reports : T[]) : T[] {
    var filteredReports : T[] = reports.filter(r => r.reportTypes.indexOf('ThreatWatch Alerts') > -1);
    return filteredReports;
  }

  public static FilterLocalizedAlerts<T extends Report>(reports : T[]) : T[] {
    var filteredReports : T[] = reports.filter(r => r.reportTypes.indexOf('Localized Alerts') > -1);
    return filteredReports;
  }


  public static IsReport(obj : any) : boolean{
    var ret : boolean = Report.containsPropertyName(obj, 'reportID');

    return ret;
  }

  public static CreateDefaultReport() : Report {
      let report = new Report();
      report.InitReport();
      return report;
  }

  protected InitReport() : void {
    super.InitReportBase();
    this.clientID = '';
    this.reportName = '';
    this.format = 'csv';
    this.recipients = '';
    this.frequency = Report.EVERY_DAY;

    this.conciergeFilter = '';
    this.reportTypes = new Array<string>();
    this.mostRecentFileName = '';
  }
  
  public static getReportCategoriesFromResponse (response : RestResponse) : ReportCategories[] {
    var ret : ReportCategories[] = response.data;

    return ret;
  }

  public static addNoneReportCategory (reportCategories : ReportCategories[]) : ReportCategories[] {
    var ret : ReportCategories[];

    ret = [{
      reportCategory: "None",
      reportCategoryID: 0,
      subCategories : []
    }].concat(reportCategories);
    return ret;

  }
  public static addNoneReportSubCategory (reportSubCategories : ReportSubCategories[]) : ReportSubCategories[] {
    var ret : ReportSubCategories[];

    ret = [{
      reportSubCategory: "None",
      reportSubCategoryID: 0,
    }].concat(reportSubCategories);
    return ret;

  }


}
export class ReportModel  extends Report{
  public constructor() {
    super();
    this.InitReportModel();
  }
  options: string;
  reportType : ReportState;

  protected InitReportModel() : void {
    super.InitReport();
    this.options = "Run Now";

  }

}


export class ReportWizardData  extends ReportModel{

  queryFilterCriteria: QueryFilterCriteriaWizardData; // TODO - this is part of ReportInfo, should be removed
  day: number = -1;
  timeToSend: string;

  public constructor() {
    super();

    this.InitReportWizardData();

  }

   static CreateDefaultReportWizardData() : ReportWizardData {
      let reportWizardData = new ReportWizardData()
      reportWizardData.InitReportWizardData();

      return reportWizardData;
  }

  protected InitReportWizardData() : void {
    super.InitReportModel();
    //reportWizardData.timeSeries = "Last 24 Hours";
    this.queryFilterCriteria = new QueryFilterCriteriaWizardData();
    this.queryFilterCriteria.label = "Last 24 Hours";
    this.day = -1;
    this.timeToSend = "";

  }

}

export class ReportSchedule {
  IsRunNowAndSchedule;
  ReportFrequency;
  DayOfWeek;
  DayOfMonth;
  TimeToRun;
}


export class ReportInfo {
  reportFilter : QueryFilterCriteriaWizardData;
  schedule : ReportSchedule;
  reportData : Report;
  label : string;
}

export class AllReports {
  savedReportViews: ReportViewModel[] = [];
  scheduledReports: ReportModel[] = [];
  completedReports: ReportModel[] = [];
  templates: any[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class ReportSharedData {

  public constructor() {
  }
  public allReports: AllReports = new AllReports();
  public reportCategories: ReportCategories[] = [];


  public get IsReportingSsrsUsed() : boolean {
    return true;
  }
  public get isReportScheduleEditingEnabled() : boolean {
    return false;
  }

}

export enum FilterKeyName {
  Analytics = 'Analytics',
  Logs = 'Logs',
  LocalizedAlerts = 'LocalizedAlerts',
  LogAndAlertSummary = 'Summary',
  LocalizedAlertsSummary = 'LocalizedAlerts/Summary',
  LogsSummary = 'Logs/Summary',
  FullLog = 'Logs/Raw',
  Templates = 'Templates'
};

export enum ReportTypeName {
  Undefined = 'Undefined',
  ThreatWatchAlertsName = 'ThreatWatch Alerts',
  LogsName = 'Logs',
  LocalizedAlertsName = 'Localized Alerts',
  LogAndAlertSummaryName = 'Summary',
  LocalizedAlertsSummaryName = 'Localized Alerts Summary',
  LogsSummaryName = 'Logs Summary',
  FullLogName = 'Full Log'
};




export class ReportCategories
{
    reportCategoryID : number;
    reportCategory : string;
    subCategories : ReportSubCategories[];
}
export class ReportSubCategories
{
    reportSubCategoryID : number;
    reportSubCategory : string;
}

interface ReportCategoryIDToCatgegoryMap {
  [reportCategoryID: number]: ReportCategories;
}

export class RestResponse
{
    success : boolean;
    error;
    status : number;
    data;
}

export interface ReportViewConciergeConfiguration {
  reportId?: number;
  reportName?: string;
  configuredFilters: ConfiguredFilter[];
  dateRange: DateRange;
  dateRangeIndex?: number;
  chartGroupingOption: ChartGroupingOption;
  gridState?: GridState;
  reportViewID?: number;
}
