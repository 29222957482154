import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-045850 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( DeepSeas LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( ThreatWatch )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( ThreatWatch )_need_to_be_licensed___( ThreatWatch )_has_been_granted_a_Deployment_License_Add-on_for_( 2 )_Production_Environments___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 10 October 2024 )____[v2]_MTcyODUxNDgwMDAwMA==255c059d8be516a4ae376f5e7baadc9c');
if (environment.type === 'production')
{
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
