import { ReportingState, initialReportingState } from "../state/reporting.state";
import { ReportingActions, ReportingActionTypes } from "../actions/reporting.actions";

export function reportingReducers(
    state: ReportingState = initialReportingState,
    action: ReportingActions
) {
    switch (action.type) {
        case ReportingActionTypes.FetchAnalyticsReportingViews:
            return {
                ...state,
                analyticsReportingViews: [],
                isAnalyticsReportsLoading: true,
                isAnalyticsReportsLoaded: false
            };
        case ReportingActionTypes.FetchAnalyticsReportingViewsSuccess:
            return {
                ...state,
                analyticsReportingViews: action.payload,
                isAnalyticsReportsLoading: false,
                isAnalyticsReportsLoaded: true
            };
        case ReportingActionTypes.FetchAnalyticsReportingViewsFailure:
            return {
                ...state,
                analyticsReportingViews: [],
                isAnalyticsReportsLoading: false,
                isAnalyticsReportsLoaded: false
            };

        case ReportingActionTypes.FetchLocalizedAlertsReportingViews:
            return {
                ...state,
                localizedAlertsReportingViews: [],
                isLocalizedAlertsReportingViewsLoading: true,
                isLocalizedAlertsReportingViewsLoaded: false
            };
        case ReportingActionTypes.FetchLocalizedAlertsReportingViewsSuccess:
            return {
                ...state,
                localizedAlertsReportingViews: action.payload,
                isLocalizedAlertsReportingViewsLoading: false,
                isLocalizedAlertsReportingViewsLoaded: true
            };
        case ReportingActionTypes.FetchLocalizedAlertsReportingViewsFailure:
            return {
                ...state,
                localizedAlertsReportingViews: [],
                isLocalizedAlertsReportingViewsLoading: false,
                isLocalizedAlertsReportingViewsLoaded: false
            };

        case ReportingActionTypes.FetchLocalizedAlertsReportingViews:
            return {
                ...state,
                localizedAlertSummaryReportingViews: [],
                isLocalizedAlertSummaryReportingViewsLoading: true,
                isLocalizedAlertSummaryReportingViewsLoaded: false
            };
        case ReportingActionTypes.FetchLocalizedAlertSummaryReportingViewsSuccess:
            return {
                ...state,
                localizedAlertSummaryReportingViews: action.payload,
                isLocalizedAlertSummaryReportingViewsLoading: false,
                isLocalizedAlertSummaryReportingViewsLoaded: true
            };
        case ReportingActionTypes.FetchLocalizedAlertSummaryReportingViewsFailure:
            return {
                ...state,
                localizedAlertSummaryReportingViews: [],
                isLocalizedAlertSummaryReportingViewsLoading: false,
                isLocalizedAlertSummaryReportingViewsLoaded: false
            };

        case ReportingActionTypes.FetchLogsReportingViews:
            return {
                ...state,
                logsReportingViews: [],
                isLogsReportingViewsLoading: true,
                isLogsReportingViewsLoaded: false
            };
        case ReportingActionTypes.FetchLogsReportingViewsSuccess:
            return {
                ...state,
                logsReportingViews: action.payload,
                isLogsReportingViewsLoading: false,
                isLogsReportingViewsLoaded: true
            };
        case ReportingActionTypes.FetchLogsReportingViewsFailure:
            return {
                ...state,
                logsReportingViews: [],
                isLogsReportingViewsLoading: false,
                isLogsReportingViewsLoaded: false
            };

        case ReportingActionTypes.FetchLogSummaryReportingViews:
            return {
                ...state,
                logSummaryReportingViews: [],
                isLogSummaryReportingViewsLoading: true,
                isLogSummaryReportingViewsLoaded: false
            };
        case ReportingActionTypes.FetchLogSummaryReportingViewsSuccess:
            return {
                ...state,
                logSummaryReportingViews: action.payload,
                isLogSummaryReportingViewsLoading: false,
                isLogSummaryReportingViewsLoaded: true
            };
        case ReportingActionTypes.FetchLogSummaryReportingViewsFailure:
            return {
                ...state,
                logSummaryReportingViews: [],
                isLogSummaryReportingViewsLoading: false,
                isLogSummaryReportingViewsLoaded: false
            };

        case ReportingActionTypes.FetchLogsBetaReportingViews:
            return {
                ...state,
                logsBetaReportingViews: [],
                isLogsBetaReportingViewsLoading: true,
                isLogsBetaReportingViewsLoaded: false
            };
        case ReportingActionTypes.FetchLogsBetaReportingViewsSuccess:
            return {
                ...state,
                logsBetaReportingViews: action.payload,
                isLogsBetaReportingViewsLoading: false,
                isLogsBetaReportingViewsLoaded: true
            };
        case ReportingActionTypes.FetchLogsBetaReportingViewsFailure:
            return {
                ...state,
                logsBetaReportingViews: [],
                isLogsBetaReportingViewsLoading: false,
                isLogsBetaReportingViewsLoaded: false
            };

        default:
            return state;
    }
}
