import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { NgModule, ErrorHandler } from '@angular/core';

import { ROUTES } from './app.routes';
import { SharedModule } from './+shared/shared.module';

import { AppComponent } from './app.component';
import { PingComponent } from './ping/ping.component';
import { LoginComponent } from './login';
import { LogoutComponent } from './logout';
import { SsoLoginComponent } from './sso-login';
import { OktaLoginComponent } from './okta-login';
import { CustomOktaCallbackComponent } from './okta-login/custom-okta-callback-component';
import { ModalContainerDirective } from './+home/_directives/modal-container.directive';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptorService } from './+home/_services/jwt-interceptor.service';
import { ErrorHandlerService } from './_services/error-handler.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CustomSerializer } from './+home/_store/reducers/app.reducers';

import {
  OKTA_CONFIG,
  OktaAuthModule,
  OktaCallbackComponent,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { ErrorComponent } from './error/error.component';

const oktaAuth = new OktaAuth(window.location.hostname.includes('deepseas') ? environment.oktaOidc : environment.oktaOidc2);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    PingComponent,
    SsoLoginComponent,
    OktaLoginComponent,
    ModalContainerDirective,
    CustomOktaCallbackComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    RouterModule.forRoot(ROUTES, {
    preloadingStrategy: PreloadAllModules
}),
    SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
