import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserRoles } from '../_models/user-roles.model';
import { map } from 'rxjs/operators';

export enum Product
{
    ATLAS = 'ATLAS',
    ALA = 'ALA'
}

// TODO
const usernameKey = 'threatWatchUsername';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userRoles: UserRoles;

  username: string = '';
  clientName: string = '';
  productId: string = '';
  assetBehavior: boolean = false;
  userBehavior: boolean = false;
  serviceCenterUrl: string = '';
  hasPreferredDeviceInactivityThreshold: boolean = false;
  hasIntegrationProviders: boolean = false;

  constructor(private http: HttpClient) {
    this.username = localStorage.getItem(usernameKey);
  }

  getUserRoles(): Observable<UserRoles> {
    if (this.userRoles == null) {
      return this.http.post('/user/GetUserRoles', null)
        .pipe(map((response: any) => {
          this.userRoles = response;
          return response;
        }));
    } else {
      return of(this.userRoles);
    }
  }

  hasTemplateAdminRole(): Observable<any> {
    return this.http.post('/user/GetTemplateAdminPermission', null);
  }

  getActiveUsersForCurrentClient(): Observable<any> {
    return this.http.post('/user/GetActiveUsersForClient', null);
  }

  getUserGroups(): Observable<any> {
    return this.http.post('/user/GetUserGroups', null);
  }

  getExternalReportRecipientPermission(): Observable<any> {
    return this.http.post('/user/GetExternalReportRecipientsPermission', null);
  }

  setClientName(clientName: string) {
    this.clientName = clientName;
  }

  setProductId(productId: string) {
    this.productId = productId;
  }

  getProductId(): Observable<any> {
    return this.http.post('/client/GetClientSettings', null)
  }

  setUsername(username: string) {
    localStorage.setItem(usernameKey, username);
    this.username = username;
  }

  getUserName(): Observable<any> {
    return this.http.post('/user/GetUserName', null);
  }

  getUserTimeZone(): Observable<any> {
    return this.http.post('/user/GetUserTimeZone', null);
  }

  setAssetBehavior(value: boolean): void {
    this.assetBehavior = value;
  }

  setUserBehavior(value: boolean): void {
    this.userBehavior = value;
  }

  setServiceCenterUrl(value: string): void {
    this.serviceCenterUrl = value;
  }

  isAssetBehaviorAvailable(): boolean {
    return this.assetBehavior;
  }

  isUserBehaviorAvailable(): boolean {
    return this.userBehavior;
  }

  isSODUser(): boolean {
    return this.clientName.startsWith("SOD");
  }

  setPreferredDeviceInactivityThreshold(value: boolean): void {
    this.hasPreferredDeviceInactivityThreshold = value;
  }

  setHasIntegrationProviders(value: boolean): void {
    this.hasIntegrationProviders = value;
  }
}
