import { ThreatWatchAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, CategoryCounts, BehaviorData, Behavior, BehavioralResource, Log, EntityProperty, LocalizedAlert } from "../../_models";

export interface AssetBehaviorAnalyticsState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];

    count: number,
    isLoadingCount: boolean;
    isCountLoaded: boolean;
    counts: CategoryCounts;
    isLoadingCounts: boolean;
    isCountsLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;

    threatWatchAlerts: ThreatWatchAlert[];
    isLoadingThreatWatchAlerts: boolean;
    isThreatWatchAlertsLoaded: boolean;

    error: Error;
};

export const initialAssetBehaviorAnalyticsState: AssetBehaviorAnalyticsState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],

    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,
    counts: { categoryCountData: [], totalCount: 0 },
    isLoadingCounts: false,
    isCountsLoaded: false,

    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,

    threatWatchAlerts: undefined,
    isLoadingThreatWatchAlerts: false,
    isThreatWatchAlertsLoaded: false,

    error: null
};

export interface AssetBehaviorConnectionsState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];

    count: number,
    isLoadingCount: boolean;
    isCountLoaded: boolean;
    fullCount: number;
    isLoadingFullCount: boolean;
    isFullCountLoaded: boolean;

    summaryEntities: EntityProperty[];
    summaryData: any[];
    isLoadingSummary: boolean;
    isSummaryLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;
    fullChartData: any[];
    isLoadingFullChart: boolean;
    isFullChartLoaded: boolean;

    logs: Log[];
    isLoadingLogs: boolean;
    isLogsLoaded: boolean;
    
    error: Error;
};

export const initialAssetBehaviorConnectionsState: AssetBehaviorConnectionsState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],
    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,
    fullCount: 0,
    isLoadingFullCount: false,
    isFullCountLoaded: false,
    summaryEntities: [],
    summaryData: undefined,
    isLoadingSummary: false,
    isSummaryLoaded: false,
    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,
    fullChartData: undefined,
    isLoadingFullChart: false,
    isFullChartLoaded: false,
    logs: undefined,
    isLoadingLogs: false,
    isLogsLoaded: false,
    error: null
};

export interface AssetBehaviorEventsOfInterestState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];

    count: number,
    isLoadingCount: boolean;
    isCountLoaded: boolean;
    fullCount: number;
    isLoadingFullCount: boolean;
    isFullCountLoaded: boolean;

    summaryEntities: EntityProperty[];
    summaryData: any[];
    isLoadingSummary: boolean;
    isSummaryLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;
    fullChartData: any[];
    isLoadingFullChart: boolean;
    isFullChartLoaded: boolean;

    logs: Log[];
    isLoadingLogs: boolean;
    isLogsLoaded: boolean;
    
    error: Error;
};

export const initialAssetBehaviorEventsOfInterestState: AssetBehaviorEventsOfInterestState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],
    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,
    fullCount: 0,
    isLoadingFullCount: false,
    isFullCountLoaded: false,
    summaryEntities: [],
    summaryData: undefined,
    isLoadingSummary: false,
    isSummaryLoaded: false,
    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,
    fullChartData: undefined,
    isLoadingFullChart: false,
    isFullChartLoaded: false,
    logs: undefined,
    isLoadingLogs: false,
    isLogsLoaded: false,
    error: null
};

export interface AssetBehaviorLocalizedAlertsState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];

    count: number,
    isLoadingCount: boolean;
    isCountLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;

    summaryEntities: EntityProperty[];
    summaryData: any[];
    isLoadingSummary: boolean;
    isSummaryLoaded: boolean;

    localizedAlerts: LocalizedAlert[];
    isLoadingLocalizedAlerts: boolean;
    isLocalizedAlertsLoaded: boolean;

    error: Error;
};

export const initialAssetBehaviorLocalizedAlertsState: AssetBehaviorLocalizedAlertsState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],
    
    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,

    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,

    summaryEntities: [],
    summaryData: undefined,
    isLoadingSummary: false,
    isSummaryLoaded: false,

    localizedAlerts: undefined,
    isLoadingLocalizedAlerts: false,
    isLocalizedAlertsLoaded: false,

    error: null
};

export interface AssetBehaviorState {
    seedResource: string,
    associatedResources: BehavioralResource[],
    isLoadingAssociated: boolean;
    isAssociatedLoaded: boolean;

    behaviors: Behavior[];
    dashboardIndex: number;
    dashboardData: BehaviorData;
    isLoadingDashboard: boolean;
    isDashboardLoaded: boolean;

    dateRange: DateRange;
    dateRangeOption: DateRangeOption;

    rawTimeline: any[],
    isLoadingTimeline: boolean,
    isTimelineLoaded: boolean,

    analytics: AssetBehaviorAnalyticsState,
    connections: AssetBehaviorConnectionsState,
    eventsOfInterest: AssetBehaviorEventsOfInterestState,
    localizedAlerts: AssetBehaviorLocalizedAlertsState
};

export const initialAssetBehaviorState: AssetBehaviorState = {
    seedResource: undefined,
    associatedResources: undefined,
    isLoadingAssociated: false,
    isAssociatedLoaded: false,

    behaviors: [
        { type: 'Users', delta: {}, count: 0 },
        { type: 'Correlated Security Alerts', delta: {}, count: 0 },
        { type: 'Events of Interest', delta: {}, count: 0 },
        { type: 'Connections', delta: {}, count: 0 }
    ],
    dashboardIndex: 0,
    dashboardData: {
        'Users': [],
        'Correlated Security Alerts': [],
        'Events of Interest': [],
        'Connections': []
    },
    isLoadingDashboard: false,
    isDashboardLoaded: false,

    dateRange: null,
    dateRangeOption: DateRangeOption.LastTwentyFourHours,

    rawTimeline: undefined,
    isLoadingTimeline: false,
    isTimelineLoaded: false,

    analytics: initialAssetBehaviorAnalyticsState,
    connections: initialAssetBehaviorConnectionsState,
    eventsOfInterest: initialAssetBehaviorEventsOfInterestState,
    localizedAlerts: initialAssetBehaviorLocalizedAlertsState
};
