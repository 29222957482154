import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserService } from '../+home/_services/user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductGuard implements CanActivate {

  constructor(private router: Router, private http: HttpClient, private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return true;
    //return this.userService.getProductId()
    //  .pipe(
    //    map(response => {
    //      if (!response) {
    //        this.router.navigateByUrl('/');
    //        return false;
    //      }

    //      var productId = response.productId;
    //      if (productId == 'ALA') {
    //        var routes = ['/alaap', '/logs', '/logs/summary', '/logs/raw', '/localizedalerts', '/localizedalerts/summary', 
    //        '/customalerts', '/reports', '/reports/templates', '/portal/servicecenter', '/asset-table', '/monitoring'];
    //        var result = routes.some(r => state.url.includes(r));

    //        if (!result) {
    //          this.router.navigateByUrl('/alaap');
    //        }

    //        return result;
    //      }
    //      else {
    //        if (state.url.includes('/alaap')) {
    //          this.router.navigateByUrl('/');
    //          return false;
    //        }

    //        return true;
    //      }
    //    },
    //      error => {
    //        this.router.navigateByUrl('/');
    //        return false;
    //      })
    //  );
  }
}
