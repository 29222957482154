import { Entity } from './entity.model';
import { EntityProperty } from './entity-property';

export class Log extends Entity {

  get id(): number {
    return this.data && this.data[Log._id.fieldName];
  }

  static get _id(): EntityProperty {
    return { fieldName: 'id', columnName: 'id', filterType: 'string', displayName: 'Log ID' };
  }

  get time(): string {
    return this.data && this.data[Log._time.fieldName];
  }

  static get _time(): EntityProperty {
    return { fieldName: 'tm', columnName: 'timestamp', filterType: 'epoch', displayName: 'Date Time {0}' };
  }

  get timestamp(): number {
    return this.data && this.data[Log._timestamp.fieldName];
  }

  static get _timestamp(): EntityProperty {
    return { fieldName: 'ts', columnName: 'timestamp', filterType: 'epoch', displayName: 'Timestamp' };
  }

  get sourceIP(): string {
    return this.data && this.data[Log._sourceIP.fieldName];
  }

  static get _sourceIP(): EntityProperty {
    return { fieldName: 'sip', columnName: 'srcipint', filterType: 'ipaddress', displayName: 'Source IP', placeholder: 'i.e. 255.255.255.255' };
  }

  get destinationIP(): string {
    return this.data && this.data[Log._destinationIP.fieldName];
  }

  static get _destinationIP(): EntityProperty {
    return { fieldName: 'dip', columnName: 'dstipint', filterType: 'ipaddress', displayName: 'Destination IP', placeholder: 'i.e. 255.255.255.255' };
  }

  get reportDeviceInt(): string {
    return this.data && this.data[Log._reportDeviceInt.fieldName];
  }

  static get _reportDeviceInt(): EntityProperty {
    return { fieldName: 'rdi', columnName: 'report_deviceint', filterType: 'number', displayName: 'Report Device Integer' };
  }

  get reportDevice(): string {
    return this.data && this.data[Log._reportDevice.fieldName];
  }

  static get _reportDevice(): EntityProperty {
    return { fieldName: 'rd', columnName: 'report_device', filterType: 'string', displayName: 'Report Device', placeholder: 'i.e. IP/Host Name', fieldLength: 32 };
  }

  get deviceType(): string {
    return this.data && this.data[Log._deviceType.fieldName];
  }

  static get _deviceType(): EntityProperty {
    return { fieldName: 'dt', columnName: 'device_type_name', filterType: 'DeviceTypeName', displayName: 'Device Type', placeholder: 'i.e. Firewall', fieldLength: 64 };
  }

  get deviceVendor(): string {
    return this.data && this.data[Log._deviceVendor.fieldName];
  }

  //TODO: This is a hack, there should be a filter type DeviceVendor
  static get _deviceVendor(): EntityProperty {
    return { fieldName: 'dv', columnName: 'device_vendor', filterType: 'DeviceTypeName', displayName: 'Device Vendor', placeholder: 'i.e. Cisco', fieldLength: 32 };
  }

  get sourcePort(): number {
    return this.data && this.data[Log._sourcePort.fieldName];
  }

  static get _sourcePort(): EntityProperty {
    return { fieldName: 'spt', columnName: 'srcport', filterType: 'number', displayName: 'Source Port', placeholder: 'i.e. 80' };
  }

  get destinationPort(): number {
    return this.data && this.data[Log._destinationPort.fieldName];
  }

  static get _destinationPort(): EntityProperty {
    return { fieldName: 'dpt', columnName: 'dstport', filterType: 'number', displayName: 'Destination Port', placeholder: 'i.e. 80' };
  }

  get sourceHostName(): string {
    return this.data && this.data[Log._sourceHostName.fieldName];
  }

  static get _sourceHostName(): EntityProperty {
    return { fieldName: 'shn', columnName: 'srchost', filterType: 'string', displayName: 'Source Host', fieldLength: 64 };
  }

  get destinationHostName(): string {
    return this.data && this.data[Log._destinationHostName.fieldName];
  }

  static get _destinationHostName(): EntityProperty {
    return { fieldName: 'dhn', columnName: 'dsthost', filterType: 'string', displayName: 'Destination Host', fieldLength: 64 };
  }

  get sourceIPRep(): string {
    return this.data && this.data[Log._sourceIPRep.fieldName];
  }

  static get _sourceIPRep(): EntityProperty {
    return { fieldName: 'sipr', columnName: 'source_rep', filterType: 'IPReputation', displayName: 'Source IP Reputation' };
  }

  get destinationIPRep(): string {
    return this.data && this.data[Log._destinationIPRep.fieldName];
  }

  static get _destinationIPRep(): EntityProperty {
    return { fieldName: 'dipr', columnName: 'dest_rep', filterType: 'IPReputation', displayName: 'Destination IP Reputation' };
  }

  get eventName(): string {
    return this.data && this.data[Log._eventName.fieldName];
  }

  static get _eventName(): EntityProperty {
    return { fieldName: 'ev', columnName: 'eventname', filterType: 'string', displayName: 'Event Name', fieldLength: 64 };
  }

  get subjectUser(): string {
    return this.data && this.data[Log._subjectUser.fieldName];
  }

  static get _subjectUser(): EntityProperty {
    return { fieldName: 'sur', columnName: 'subjectuser', filterType: 'string', displayName: 'Subject User', placeholder: 'i.e.john.smith', fieldLength: 64 };
  }

  get targetUser(): string {
    return this.data && this.data[Log._targetUser.fieldName];
  }

  static get _targetUser(): EntityProperty {
    return { fieldName: 'tur', columnName: 'targetuser', filterType: 'string', displayName: 'Target User', placeholder: 'i.e.john.smith', fieldLength: 64 };
  }

  get subjectDomain(): string {
    return this.data && this.data[Log._subjectDomain.fieldName];
  }

  static get _subjectDomain(): EntityProperty {
    return { fieldName: 'sdom', columnName: 'subjectdomain', filterType: 'string', displayName: 'Subject Domain', fieldLength: 64 };
  }

  get targetDomain(): string {
    return this.data && this.data[Log._targetDomain.fieldName];
  }

  static get _targetDomain(): EntityProperty {
    return { fieldName: 'tdom', columnName: 'targetdomain', filterType: 'string', displayName: 'Target Domain', fieldLength: 64 };
  }

  get sourceCountry(): string {
    return this.data && this.data[Log._sourceCountry.fieldName];
  }

  static get _sourceCountry(): EntityProperty {
    return { fieldName: 'sco', columnName: 'srccountrycd', filterType: 'country', displayName: 'Source Country', placeholder: 'i.e. United States' };
  }

  get destinationCountry(): string {
    return this.data && this.data[Log._destinationCountry.fieldName];
  }

  static get _destinationCountry(): EntityProperty {
    return { fieldName: 'dco', columnName: 'dstcountrycd', filterType: 'country', displayName: 'Destination Country', placeholder: 'i.e. United States' };
  }

  get description(): string {
    return this.data && this.data[Log._description.fieldName];
  }

  static get _description(): EntityProperty {
    return { fieldName: 'drp', columnName: 'description', filterType: 'string', displayName: 'Details' };
  }

  get collector(): string {
    return this.data && this.data[Log._collector.fieldName];
  }

  static get _collector(): EntityProperty {
    return { fieldName: 'col', columnName: 'collector', filterType: 'string', displayName: 'Collector', fieldLength: 32 };
  }

  get object(): string {
    return this.data && this.data[Log._object.fieldName];
  }

  static get _object(): EntityProperty {
    return { fieldName: 'obj', columnName: 'object', filterType: 'string', displayName: 'Object', fieldLength: 64 };
  }

  get clientID(): string {
    return this.data && this.data[Log._clientID.fieldName];
  }

  static get _clientID(): EntityProperty {
    return { fieldName: 'cid', columnName: 'clientid', filterType: 'string', displayName: 'Client', fieldLength: 64 };
  }

  get signature(): string {
    return this.data && this.data[Log._signature.fieldName];
  }

  static get _signature(): EntityProperty {
    return { fieldName: 'sign', columnName: 'signature', filterType: 'string', displayName: 'Signature', fieldLength: 64 };
  }

  get direction(): string {
    return this.data && this.data[Log._direction.fieldName];
  }

  static get _direction(): EntityProperty {
    return { fieldName: 'dir', columnName: 'direction', filterType: 'direction', displayName: 'Direction', placeholder: 'i.e. Internal To External' };
  }

  get disposition(): number {
    return this.data && this.data[Log._disposition.fieldName];
  }

  static get _disposition(): EntityProperty {
    return { fieldName: 'disp', columnName: 'disposition', filterType: 'disposition', displayName: 'Allow/Deny', placeholder: 'i.e. Allow' };
  }

  get protocol(): string {
    return this.data && this.data[Log._protocol.fieldName];
  }

  static get _protocol(): EntityProperty {
    return { fieldName: 'prot', columnName: 'protocol', filterType: 'string', displayName: 'Protocol', fieldLength: 10 };
  }

  get logonType(): string {
    return this.data && this.data[Log._logonType.fieldName];
  }

  static get _logonType(): EntityProperty {
    return { fieldName: 'ltyp', columnName: 'logontype', filterType: 'number', displayName: 'Logon Type' };
  }

  get deviceEventID(): string {
    return this.data && this.data[Log._deviceEventID.fieldName];
  }

  static get _deviceEventID(): EntityProperty {
    return { fieldName: 'dei', columnName: 'dev_id', filterType: 'string', displayName: 'Device Event ID', fieldLength: 32 };
  }

  get fullEvent(): string {
    return this.data && this.data[Log._fullEvent.fieldName];
  }

  static get _fullEvent(): EntityProperty {
    return { fieldName: 'fe', columnName: 'event', filterType: 'string', displayName: 'Full Event' };
  }

  get groupName(): string {
    return this.data && this.data[Log._groupName.fieldName];
  }

  static get _groupName(): EntityProperty {
    return { fieldName: 'gn', columnName: 'groupname', filterType: 'string', displayName: 'Group Name', fieldLength: 32 };
  }

  get groupDomain(): string {
    return this.data && this.data[Log._groupDomain.fieldName];
  }

  static get _groupDomain(): EntityProperty {
    return { fieldName: 'gd', columnName: 'groupdomain', filterType: 'string', displayName: 'Group Domain', fieldLength: 32 };
  }

  get groupMember(): string {
    return this.data && this.data[Log._groupMember.fieldName];
  }

  static get _groupMember(): EntityProperty {
    return { fieldName: 'gm', columnName: 'groupmember', filterType: 'string', displayName: 'Group Member', fieldLength: 32 };
  }

  //get hostName(): string {
  //  return this.data && this.data[Log._hostName.fieldName];
  //}

  //static get _hostName(): EntityProperty {
  //  return { fieldName: 'hn', columnName: 'hostname', filterType: 'string', displayName: 'Host Name', fieldLength: 64 };
  //}

  get url(): string {
    return this.data && this.data[Log._url.fieldName];
  }

  static get _url(): EntityProperty {
    return { fieldName: 'url', columnName: 'url', filterType: 'string', displayName: 'URL', fieldLength: 128 };
  }

  get asnid(): string {
    return this.data && this.data[Log._asnid.fieldName];
  }

  static get _asnid(): EntityProperty {
    return { fieldName: 'asn', columnName: 'asnid', filterType: 'number', displayName: 'ASN ID' };
  }

  get ipRepSeverity(): string {
    return this.data && this.data[Log._ipRepSeverity.fieldName];
  }

  static get _ipRepSeverity(): EntityProperty {
    return { fieldName: 'irs', columnName: 'iprepsev', filterType: 'severity', displayName: 'IP Rep Severity' };
  }

  get deviceSeverity(): string {
    return this.data && this.data[Log._deviceSeverity.fieldName];
  }

  static get _deviceSeverity(): EntityProperty {
    return { fieldName: 'dvs', columnName: 'dev_severity', filterType: 'string', displayName: 'Device Severity' };
  }

  get deviceRule(): string {
    return this.data && this.data[Log._deviceRule.fieldName];
  }

  static get _deviceRule(): EntityProperty {
    return { fieldName: 'dvr', columnName: 'dev_rule', filterType: 'string', displayName: 'Device Rule', fieldLength: 32 };
  }

  get eventOfInterest(): string {
    return this.data && this.data[Log._eventOfInterest.fieldName];
  }

  static get _eventOfInterest(): EntityProperty {
    return { fieldName: 'eoi', columnName: 'eventofinterest', filterType: 'string', displayName: 'Event of Interest', placeholder: 'i.e. Logon', fieldLength: 50 };
  }

  get fullLogLine(): string {
    return this.data && this.data[Log._fullLogLine.fieldName];
  }

  static get _fullLogLine(): EntityProperty {
    return { fieldName: 'fll', columnName: 'fll', filterType: 'string', displayName: 'Full Log Line', fieldLength: 500 }
  }

  get originalFullLogLine(): string {
    return this.data && this.data[Log._fullLogLine.fieldName];
  }

  static get _originalFullLogLine(): EntityProperty {
    return { fieldName: 'ofll', columnName: 'ofll', filterType: 'string', displayName: 'Full Log Line'}
  }

  get bytesSent(): string {
    return this.data && this.data[Log._bytesSent.fieldName];
  }

  static get _bytesSent(): EntityProperty {
    return { fieldName: 'byts', columnName: 'bytes_sent', filterType: 'number', displayName: 'Bytes Sent' }
  }

  get bytesReceived(): string {
    return this.data && this.data[Log._bytesReceived.fieldName];
  }

  static get _bytesReceived(): EntityProperty {
    return { fieldName: 'bytr', columnName: 'bytes_received', filterType: 'number', displayName: 'Bytes Received' }
  }

  get packetsSent(): string {
    return this.data && this.data[Log._packetsSent.fieldName];
  }

  static get _packetsSent(): EntityProperty {
    return { fieldName: 'pkts', columnName: 'packets_sent', filterType: 'number', displayName: 'Packets Sent' }
  }

  get packetsReceived(): string {
    return this.data && this.data[Log._packetsReceived.fieldName];
  }

  static get _packetsReceived(): EntityProperty {
    return { fieldName: 'pktr', columnName: 'packets_received', filterType: 'number', displayName: 'Packets Received' }
  }

  get reportDeviceHost(): string {
    return this.data && this.data[Log._reportDeviceHost.fieldName];
  }

  static get _reportDeviceHost(): EntityProperty {
    return { fieldName: 'rdh', columnName: 'reportdevicehost', filterType: 'string', displayName: 'Report Device Host Name', fieldLength: 64 }
  }

  get sourceIPv6(): string {
    return this.data && this.data[Log._sourceIPv6.fieldName];
  }

  static get _sourceIPv6(): EntityProperty {
    return { fieldName: 'sip6', columnName: 'source_ipv6', filterType: 'string', displayName: 'Source IPv6' }
  }

  get destinationIPv6(): string {
    return this.data && this.data[Log._destinationIPv6.fieldName];
  }

  static get _destinationIPv6(): EntityProperty {
    return { fieldName: 'dip6', columnName: 'dest_ipv6', filterType: 'string', displayName: 'Destination IPv6' }
  }

  get count(): string {
    return this.data && this.data[Log._count.fieldName];
  }

  static get _count(): EntityProperty {
    return { fieldName: 'cnt', columnName: 'count', filterType: 'number', displayName: 'Count' };
  }

	static getEntityFromColumnName(column: string): EntityProperty {
		// iterate over each method to find the EntityProperty that matches
		switch (column.toLowerCase().trim()) {
      case 'id':
        return this._id;
      case 'timestamp':
        return this._timestamp;
      case 'srcipint':
        return this._sourceIP;
      case 'dstipint':
        return this._destinationIP;
      case 'report_deviceint':
        return this._reportDeviceInt;
      case 'report_device':
        return this._reportDevice;
      case 'device_type_name':
        return this._deviceType;
      case 'device_vendor':
        return this._deviceVendor;
      case 'srcport':
        return this._sourcePort;
      case 'dstport':
        return this._destinationPort;
      case 'srchost':
        return this._sourceHostName;
      case 'dsthost':
        return this._destinationHostName;
      case 'source_rep':
        return this._sourceIPRep;
      case 'dest_rep':
        return this._destinationIPRep;
      case 'eventname':
        return this._eventName;
      case 'subjectuser':
        return this._subjectUser;
      case 'targetuser':
        return this._targetUser;
      case 'subjectdomain':
        return this._subjectDomain;
      case 'targetdomain':
        return this._targetDomain;
      case 'srccountrycd':
        return this._sourceCountry;
      case 'dstcountrycd':
        return this._destinationCountry;
      case 'description':
        return this._description;
      case 'collector':
        return this._collector;
      case 'clientid':
        return this._clientID;
      case 'signature':
        return this._signature;
      case 'direction':
        return this._direction;
      case 'disposition':
        return this._disposition;
      case 'protocol':
        return this._protocol;
      case 'logontype':
        return this._logonType;
      case 'dev_id':
        return this._deviceEventID;
      case 'event':
        return this._fullEvent;
      case 'groupname':
        return this._groupName;
      case 'groupdomain':
        return this._groupDomain;
      case 'groupmember':
        return this._groupMember;
      //case 'hostname':
      //  return this._hostName;
      case 'url':
        return this._url;
      case 'asnid':
        return this._asnid;
      case 'iprepsev':
        return this._ipRepSeverity;
      case 'dev_severity':
        return this._deviceSeverity;
      case 'deviceRule':
        return this._deviceRule;
      case 'eventofinterest':
        return this._eventOfInterest;
      case 'fll':
        return this._fullLogLine;
      case 'ofll':
        return this._originalFullLogLine;
      case 'bytes_sent':
        return this._bytesSent;
      case 'bytes_received':
        return this._bytesReceived;
      case 'packets_sent':
        return this._packetsSent;
      case 'packets_received':
        return this._packetsReceived;
      case 'reportdevicehost':
        return this._reportDeviceHost;
      case 'source_ipv6':
        return this._sourceIPv6;
      case 'dest_ipv6':
        return this._destinationIPv6;
      case 'count':
        return this._count;
    }

		return null;
	}
}
