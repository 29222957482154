import { Action } from "@ngrx/store";
import { ReportViewModel } from "../../_models";

export enum ReportingActionTypes {
    FetchAnalyticsReportingViews = '[Reporting] Fetch Analytics Reporting Views',
    FetchAnalyticsReportingViewsSuccess = '[Reporting] Fetch Analytics Reporting Views SUCCESS',
    FetchAnalyticsReportingViewsFailure = '[Reporting] Fetch Analytics Reporting Views FAILURE',

    FetchLocalizedAlertsReportingViews = '[Reporting] Fetch Localized Alerts Reporting Views',
    FetchLocalizedAlertsReportingViewsSuccess = '[Reporting] Fetch Localized Alerts Reporting Views SUCCESS',
    FetchLocalizedAlertsReportingViewsFailure = '[Reporting] Fetch Localized Alerts Reporting Views FAILURE',

    FetchLocalizedAlertSummaryReportingViews = '[Reporting] Fetch Localized Alert Summary Reporting Views',
    FetchLocalizedAlertSummaryReportingViewsSuccess = '[Reporting] Fetch Localized Alert Summary Reporting Views SUCCESS',
    FetchLocalizedAlertSummaryReportingViewsFailure = '[Reporting] Fetch Localized Alert Summary Reporting Views FAILURE',

    FetchLogsReportingViews = '[Reporting] Fetch Logs Reporting Views',
    FetchLogsReportingViewsSuccess = '[Reporting] Fetch Logs Reporting Views SUCCESS',
    FetchLogsReportingViewsFailure = '[Reporting] Fetch Logs Reporting Views FAILURE',

    FetchLogSummaryReportingViews = '[Reporting] Fetch Log Summary Reporting Views',
    FetchLogSummaryReportingViewsSuccess = '[Reporting] Fetch Log Summary Reporting Views SUCCESS',
    FetchLogSummaryReportingViewsFailure = '[Reporting] Fetch Log Summary Reporting Views FAILURE',

    FetchLogsBetaReportingViews = '[Reporting] Fetch Logs Beta Reporting Views',
    FetchLogsBetaReportingViewsSuccess = '[Reporting] Fetch Logs Beta Reporting Views SUCCESS',
    FetchLogsBetaReportingViewsFailure = '[Reporting] Fetch Logs Beta Reporting Views FAILURE',

    ResetState = '[Reporting] Clear State'
};

export class FetchAnalyticsReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchAnalyticsReportingViews;
};
export class FetchAnalyticsReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchAnalyticsReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchAnalyticsReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchAnalyticsReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class FetchLocalizedAlertsReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertsReportingViews;
};
export class FetchLocalizedAlertsReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertsReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchLocalizedAlertsReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertsReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class FetchLocalizedAlertSummaryReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertSummaryReportingViews;
};
export class FetchLocalizedAlertSummaryReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertSummaryReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchLocalizedAlertSummaryReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchLocalizedAlertSummaryReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class FetchLogsReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchLogsReportingViews;
};
export class FetchLogsReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchLogsReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchLogsReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchLogsReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class FetchLogSummaryReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchLogSummaryReportingViews;
};
export class FetchLogSummaryReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchLogSummaryReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchLogSummaryReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchLogSummaryReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class FetchLogsBetaReportingViews implements Action {
    public readonly type = ReportingActionTypes.FetchLogsBetaReportingViews;
};
export class FetchLogsBetaReportingViewsSuccess implements Action {
    public readonly type = ReportingActionTypes.FetchLogsBetaReportingViewsSuccess;
    constructor(readonly payload: ReportViewModel[]) {}
};
export class FetchLogsBetaReportingViewsFailure implements Action {
    public readonly type = ReportingActionTypes.FetchLogsBetaReportingViewsFailure;
    constructor(readonly payload: any) {}
};

export class ResetState implements Action {
    public readonly type = ReportingActionTypes.ResetState;
};

export type ReportingActions = FetchAnalyticsReportingViews | FetchAnalyticsReportingViewsSuccess | FetchAnalyticsReportingViewsFailure
    | FetchLocalizedAlertsReportingViews | FetchLocalizedAlertsReportingViewsSuccess | FetchLocalizedAlertsReportingViewsFailure
    | FetchLocalizedAlertSummaryReportingViews | FetchLocalizedAlertSummaryReportingViewsSuccess | FetchLocalizedAlertSummaryReportingViewsFailure
    | FetchLogsReportingViews | FetchLogsReportingViewsSuccess | FetchLogsReportingViewsFailure 
    | FetchLogSummaryReportingViews | FetchLogSummaryReportingViewsSuccess | FetchLogSummaryReportingViewsFailure 
    | FetchLogsBetaReportingViews | FetchLogsBetaReportingViewsSuccess | FetchLogsBetaReportingViewsFailure 
    | ResetState;
