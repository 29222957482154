import { ThreatWatchAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, CategoryCounts, GridState } from "../../_models";

export interface AnalyticsState {
    dateRange: DateRange;
    dateRangeOption: DateRangeOption;
    drilldownDateRange: DateRange;
    filterConditions: ConfiguredFilter[];

    count: number,
    isLoadingCount: boolean;
    isCountLoaded: boolean;
    counts: CategoryCounts;
    isLoadingCounts: boolean;
    isCountsLoaded: boolean;

    chartGroupingOption: ChartGroupingOption;
    chartData: any[];
    isLoadingChart: boolean;
    isChartLoaded: boolean;

    threatWatchAlerts: ThreatWatchAlert[];
    isLoadingThreatWatchAlerts: boolean;
    isThreatWatchAlertsLoaded: boolean;
    gridState: GridState;

    error: Error;
};

export const initialAnalyticsState: AnalyticsState = {
    dateRange: null, // TODO
    dateRangeOption: DateRangeOption.LastTwentyFourHours,
    drilldownDateRange: null,
    filterConditions: [],

    count: 0,
    isLoadingCount: false,
    isCountLoaded: false,
    counts: { categoryCountData: [], totalCount: 0 },
    isLoadingCounts: false,
    isCountsLoaded: false,

    chartGroupingOption: null,
    chartData: undefined,
    isLoadingChart: false,
    isChartLoaded: false,

    threatWatchAlerts: undefined,
    isLoadingThreatWatchAlerts: false,
    isThreatWatchAlertsLoaded: false,
    gridState: null,

    error: null
};
