import {Inject, Injectable} from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {firstValueFrom, Observable, of, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators'
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import {IDToken, OktaAuth} from '@okta/okta-auth-js';
import {OKTA_AUTH} from '@okta/okta-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LoggerService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (!this.authService.tokenRequiresRefresh()) {
      return true;
    } else if (this.authService.tokenExists()) {
      return this.authService.refreshToken().pipe(
        map(
          response => response ? true : false,
          error => false
        ),
        catchError(error => {
          this.logger.handleError(error);
          this.authService.reauthenticateAsync();
          return throwError(null); // TODO
        })
      );
    } else {
      return new Promise(
          (resolve, reject) => {
            this.authService.VerifyOktaIDTokenAsync().then(() => resolve(true)).catch((error) => {
              // Always return true. The token may not exist yet.
              resolve(true);
            });
          });
    }
  }

}
