export const environment = {
    tokenName: 'access_token',
    refreshTokenName: 'refresh_token',
    type: 'staging',
    apiEndpoint: 'https://prqb.securityondemand.com',
    oktaOidc: {
      clientId: '0oat81wqdaqpWDNeb357',
      issuer: 'https://sso.deepseas.com/oauth2/default',
      redirectUri: 'https://portalbeta.securityondemand.com/login/callback',
      scopes: ['openid', 'profile', 'email'],
      testing: {
        disableHttpsCheck: 'true'
      }
    },
    oktaOidc2: {
      clientId: '0oat81wqdaqpWDNeb357',
      issuer: 'https://sso.deepseas.com/oauth2/default',
      redirectUri: 'https://portalbeta.securityondemand.com/login/callback',
      scopes: ['openid', 'profile', 'email'],
      testing: {
        disableHttpsCheck: 'true'
      }
    }
};
