import { GridState } from ".";

export class QueryFilterCriteria {
    // TODO - strongly type these fields
    startDateUtc;
    endDateUtc;
    
//    baseStartDateUtc;
//    baseEndDateUtc;
    
//    timePeriod;
    timeZone?;
    columns;
    columnsToOrderBy?;
    columnsToGroupBy;
  }
  
  
  export class QueryFilterCriteriaWizardData extends QueryFilterCriteria {
    // TODO - strongly type these fields
    gridState : GridState;
    label;
    columnDisplayNames?;
  
  }


