import { Entity } from './entity.model';
import { EntityProperty } from './entity-property';

export class AlertScan extends Entity {

    get id(): number {
        return this.data && this.data[AlertScan._id.fieldName];
    }

    static get _id(): EntityProperty {
        return { fieldName: 'id', columnName: 'GUID', filterType: 'number', displayName: 'ID' };
    }

    get sourceIP(): string {
        return this.data && this.data[AlertScan._sourceIP.fieldName];
    }

    static get _sourceIP(): EntityProperty {
        return { fieldName: 'sip', columnName: 'srcipint', filterType: 'ipaddress', displayName: 'Source IP', placeholder: 'i.e. 255.255.255.255' };
    }

    get clientID(): string {
    return this.data && this.data[AlertScan._clientID.fieldName];
    }

    static get _clientID(): EntityProperty {
        return { fieldName: 'cid', columnName: 'ClientID', filterType: 'string', displayName: 'Client' };
    }

    get scanCategory(): string {
        return this.data && this.data[AlertScan._scanCategory.fieldName];
    }

    static get _scanCategory(): EntityProperty {
        return { fieldName: 'typ', columnName: 'AlertType', filterType: 'string', displayName: 'Scan Category', placeholder: 'i.e. Attacker Scan' };
    }

    get scanType(): string {
        return this.data && this.data[AlertScan._scanType.fieldName];
    }

    static get _scanType(): EntityProperty {
        return { fieldName: 'styp', columnName: 'ScanType', filterType: 'string', displayName: 'Scan Type', placeholder: 'Internal or External' }
    }

    get alertText(): string {
        return this.data && this.data[AlertScan._alertText.fieldName];
    }

    static get _alertText(): EntityProperty {
        return { fieldName: 'atxt', columnName: 'AlertText', filterType: 'string', displayName: 'Description' };
    }

    get sourceCountry(): string {
        return this.data && this.data[AlertScan._sourceCountry.fieldName];
    }

    static get _sourceCountry(): EntityProperty {
        return { fieldName: 'sco', columnName: 'srcCountrycd', filterType: 'country', displayName: 'Source Country', placeholder: 'i.e. United States' };
    }

    get status(): string {
        return this.data && this.data[AlertScan._status.fieldName];
    }

    static get _status(): EntityProperty {
        return { fieldName: 'sta', columnName: 'Status', filterType: 'string', displayName: 'Status' };
    }
    
    get firstSeen(): string {
        return this.data && this.data[AlertScan._firstSeen.fieldName];
    }

    static get _firstSeen(): EntityProperty {
        return { fieldName: 'fst', columnName: 'FirstSeenTime', filterType: 'string', displayName: 'First Seen {0}' };
    }

    get lastSeen(): string {
        return this.data && this.data[AlertScan._lastSeen.fieldName];
    }

    static get _lastSeen(): EntityProperty {
        return { fieldName: 'lst', columnName: 'LastSeenTime', filterType: 'string', displayName: 'Last Seen {0}' };
    }

    get severity(): string {
        return this.data && this.data[AlertScan._severity.fieldName];
    }

    static get _severity(): EntityProperty {
        return { fieldName: 'irs', columnName: 'ThreatSource', filterType: 'string', displayName: 'Source IP Reputation' };
    }

    get count(): string {
        return this.data && this.data[AlertScan._count.fieldName];
    }

    static get _count(): EntityProperty {
        return { fieldName: 'cnt', columnName: 'Count', filterType: 'number', displayName: 'Scan Count' };
    }

    get ipOrPortScan(): string {
        return this.data && this.data[AlertScan._ipOrPortScan.fieldName];
    }

    static get _ipOrPortScan(): EntityProperty {
        return { fieldName: 'ippr', columnName: 'IPOrPortScan', filterType: 'iporport', displayName: 'IP/Port Scan' };
    }

    get vertical(): boolean {
        return this.data && this.data[AlertScan._vertical.fieldName];
    }

    static get _vertical(): EntityProperty {
        return { fieldName: 'vert', columnName: 'Vertical', filterType: 'boolean', displayName: 'Port Scan' };
    }

    get horizontal(): boolean {
        return this.data && this.data[AlertScan._horizontal.fieldName];
    }

    static get _horizontal(): EntityProperty {
        return { fieldName: 'hrtz', columnName: 'Horizontal', filterType: 'boolean', displayName: 'IP Scan' };
    }

    get allows(): string {
        return this.data && this.data[AlertScan._allows.fieldName];
    }

    static get _allows(): EntityProperty {
        return { fieldName: 'alls', columnName: 'Allows', filterType: 'number', displayName: 'Allowed' };
    }

    get direction(): string {
        return this.data && this.data[AlertScan._direction.fieldName];
    }

    static get _direction(): EntityProperty {
        return { fieldName: 'dir', columnName: 'Direction', filterType: 'direction', displayName: 'Direction', placeholder: 'i.e. Internal To External' };
    }

    get created(): string {
        return this.data && this.data[AlertScan._created.fieldName];
    }

    static get _created(): EntityProperty {
        return { fieldName: 'crd', columnName: 'Created', filterType: 'string', displayName: 'Created', placeholder: 'Created' };
    }

    get historicalCount(): number {
        return this.data && this.data[AlertScan._historicalCount.fieldName];
    }

    static get _historicalCount(): EntityProperty {
        return { fieldName: 'hstc', columnName: 'historicalCount', filterType: 'string', displayName: 'Historical Count' };
    }

    get knownMappedEventCount(): number {
        return this.data && this.data[AlertScan._knownMappedEventCount.fieldName];
    }

    static get _knownMappedEventCount(): EntityProperty {
        return { fieldName: 'kmec', columnName: 'KnownMappedEventCount', filterType: 'number', displayName: 'Known Mapped Event Count' };
    }

    get internal(): boolean {
        return this.data && this.data[AlertScan._internal.fieldName];
    }

    static get _internal(): EntityProperty {
        return { fieldName: 'nt', columnName: 'Internal', filterType: 'ScanAlertType', displayName: 'Scan Type' };
    }

    get escalationDate(): boolean {
        return this.data && this.data[AlertScan._escalationDate.fieldName];
    }

    static get _escalationDate(): EntityProperty {
        return { fieldName: 'ed', columnName: 'EscalationDate', filterType: 'string', displayName: 'Escalation Date' };
    }
}
