import { RouterReducerState } from '@ngrx/router-store';
import { LogsState, initialLogsState } from './logs.state';
import { AnalyticsState, initialAnalyticsState } from './analytics.state';
import { LocalizedAlertsState, initialLocalizedAlertsState } from './localized-alerts.state';
import { ReportingState, initialReportingState } from './reporting.state';
import { UserBehaviorState, initialUserBehaviorState } from './user-behavior.state';
import { AssetBehaviorState, initialAssetBehaviorState } from './asset-behavior.state';
import { AlertScansState, initialAlertScansState } from './alert-scans.state';
import { RouterStateUrl } from '../reducers/app.reducers';

export interface AppState {
    router?: RouterReducerState<RouterStateUrl>;
    analytics: AnalyticsState;
    localizedAlerts: LocalizedAlertsState;
    logs: LogsState;
    alertScans: AlertScansState;
    assetBehavior: AssetBehaviorState,
    userBehavior: UserBehaviorState,
    reporting: ReportingState;
}

export const initialAppState: AppState = {
    analytics: initialAnalyticsState,
    localizedAlerts: initialLocalizedAlertsState,
    logs: initialLogsState,
    alertScans: initialAlertScansState,
    assetBehavior: initialAssetBehaviorState,
    userBehavior: initialUserBehaviorState,
    reporting: initialReportingState,
};

export function getInitialAppState(): AppState {
    return initialAppState;
}