import { initialLogsState, LogsState } from "../state/logs.state";
import { LogsActionTypes, LogsActions } from "../actions/logs.actions";
import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";

export function logsReducers(
    state: LogsState = initialLogsState,
    action: LogsActions
) {
    const currentSliceKey = location.pathname;
    switch (action.type) {
        case LogsActionTypes.PreloadConcierge:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                },
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.drilldownDateRange,
                },
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload.filterConditions,
                },
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.chartGroupingOption,
                }
            };
            
        case LogsActionTypes.SetDateRange:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                }
            };
        case LogsActionTypes.SetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload,
                }
            };
        case LogsActionTypes.ResetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.drilldownDateRange[action.sliceKey ?? currentSliceKey]
                }
            };    
        case LogsActionTypes.SetSummaryDateRange:
            return {
                ...state,
                summaryDateRange: {
                    ...state.summaryDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                summaryDateRangeOption: {
                    ...state.summaryDateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                }
            };
        case LogsActionTypes.SetSummaryDrilldownDateRange:
            return {
                ...state,
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload,
                }
            };
        case LogsActionTypes.ResetSummaryDrilldownDateRange:
            return {
                ...state,
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryDrilldownDateRange[action.sliceKey ?? currentSliceKey],
                }
            };   

        case LogsActionTypes.AddFilters:
                const filters = state.filterConditions[action.sliceKey ?? currentSliceKey].concat(action.payload);
                return {
                    ...state,
                    filterConditions: {
                        ...state.filterConditions,
                        [action.sliceKey ?? currentSliceKey]: StoredConciergeService.getDistinctConfiguredFilters(filters),
                    }
                };
        case LogsActionTypes.RemoveFilter:
            return {
                ...state,
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: state.filterConditions[action.sliceKey ?? currentSliceKey].filter(c => c !== action.payload)
                }
            };
        case LogsActionTypes.OverwriteFilters:
            return {
                ...state,
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };
        case LogsActionTypes.AddSummaryFilters:
            const summaryFilters = state.summaryFilterConditions[action.sliceKey ?? currentSliceKey].concat(action.payload);
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: StoredConciergeService.getDistinctConfiguredFilters(summaryFilters),
                }
            };
        case LogsActionTypes.RemoveSummaryFilter:
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: state.summaryFilterConditions[action.sliceKey ?? currentSliceKey].filter(c => c !== action.payload)
                }
            };
        case LogsActionTypes.OverwriteSummaryFilters:
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };

        case LogsActionTypes.SetBehaviorType:
            return {
                ...state,
                behaviorType: action.payload.toLowerCase()
            };
        case LogsActionTypes.SetUsers:
            return {
                ...state,
                users: action.payload
            };
        case LogsActionTypes.SetAssets:
            return {
                ...state,
                assets: action.payload
            };
        case LogsActionTypes.ResetBehaviorProperties:
            return {
                ...state,
                behaviorType: null,
                assets: initialLogsState.assets,
                users: initialLogsState.users
            };

        case LogsActionTypes.FetchCount:
            return {
                ...state,
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchCountSuccess:
            return { 
                ...state, 
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                },
            };
        case LogsActionTypes.FetchCountFailure:
            return {
                ...state,
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchSummaryCount:
            return {
                ...state,
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchSummaryCountSuccess:
            return { 
                ...state, 
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                },
            };
        case LogsActionTypes.FetchSummaryCountFailure:
            return {
                ...state,
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchFullCount:
            return {
                ...state,
                isLoadingFullCount: {
                    ...state.isLoadingFullCount,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isFullCountLoaded: {
                    ...state.isFullCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchFullCountSuccess:
            return { 
                ...state, 
                fullCount: {
                    ...state.fullCount,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingFullCount: {
                    ...state.isLoadingFullCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullCountLoaded: {
                    ...state.isFullCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                },
            };
        case LogsActionTypes.FetchFullCountFailure:
            return {
                ...state,
                fullCount: {
                    ...state.fullCount,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingFullCount: {
                    ...state.isLoadingFullCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullCountLoaded: {
                    ...state.isFullCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };

        case LogsActionTypes.SetSummaryEntities:
            return {
                ...state,
                summaryEntities: {
                    ...state.summaryEntities,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };
        case LogsActionTypes.FetchSummary:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchSummarySuccess:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                }
            };
        case LogsActionTypes.FetchSummaryFailure:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.SetSummaryGridState:
            return {
                ...state,
                summaryGridState: {
                    ...state.summaryGridState,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                } 
            };

        case LogsActionTypes.SetChartGroupingOption:
            return {
                ...state,
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                } 
            };
        case LogsActionTypes.FetchChart:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.chartData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };
        case LogsActionTypes.FetchChartSuccess:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LogsActionTypes.FetchChartFailure:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };
        case LogsActionTypes.FetchFullChart:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.chartData[action.sliceKey ?? currentSliceKey]
                } ,
                isLoadingFullChart: {
                    ...state.isLoadingFullChart,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isFullChartLoaded: {
                    ...state.isFullChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };
        case LogsActionTypes.FetchFullChartSuccess:
            return {
                ...state,
                fullChartData: {
                    ...state.fullChartData,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingFullChart: {
                    ...state.isLoadingFullChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullChartLoaded: {
                    ...state.isFullChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LogsActionTypes.FetchFullChartFailure:
            return {
                ...state,
                fullChartData: {
                    ...state.fullChartData,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingFullChart: {
                    ...state.isLoadingFullChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullChartLoaded: {
                    ...state.isFullChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };

        case LogsActionTypes.FetchLogs:
            return { 
                ...state,
                isLoadingLogs: {
                    ...state.isLoadingLogs,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isLogsLoaded: {
                    ...state.isLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchLogsSuccess:
            return { 
                ...state, 
                logs: {
                    ...state.logs,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingLogs: {
                    ...state.isLoadingLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLogsLoaded: {
                    ...state.isLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LogsActionTypes.FetchLogsFailure:
            return { 
                ...state, 
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                logs: {
                    ...state.logs,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingLogs: {
                    ...state.isLoadingLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLogsLoaded: {
                    ...state.isLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };
        case LogsActionTypes.FetchSummaryLogs:
            return { 
                ...state,
                isLoadingSummaryLogs: {
                    ...state.isLoadingSummaryLogs,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryLogsLoaded: {
                    ...state.isSummaryLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchSummaryLogsSuccess:
            return { 
                ...state, 
                summaryLogs: {
                    ...state.summaryLogs,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummaryLogs: {
                    ...state.isLoadingSummaryLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLogsLoaded: {
                    ...state.isSummaryLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LogsActionTypes.FetchSummaryLogsFailure:
            return { 
                ...state, 
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                summaryLogs: {
                    ...state.summaryLogs,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingSummaryLogs: {
                    ...state.isLoadingSummaryLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLogsLoaded: {
                    ...state.isSummaryLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };

        case LogsActionTypes.FetchFullLogs:
            return { 
                ...state,
                isLoadingFullLogs: {
                    ...state.isLoadingFullLogs,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isFullLogsLoaded: {
                    ...state.isFullLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LogsActionTypes.FetchFullLogsSuccess:
            return { 
                ...state, 
                fullLogs: {
                    ...state.fullLogs,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingFullLogs: {
                    ...state.isLoadingFullLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullLogsLoaded: {
                    ...state.isFullLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LogsActionTypes.FetchFullLogsFailure:
            return { 
                ...state, 
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                fullLogs: {
                    ...state.fullLogs,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingFullLogs: {
                    ...state.isLoadingFullLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullLogsLoaded: {
                    ...state.isFullLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };

        case LogsActionTypes.SetGridState:
            return {
                ...state,
                gridState: {
                    ...state.gridState,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                } 
            };
        case LogsActionTypes.SetMasterDetailExpanded:
            return {
                ...state,
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: action.isExpanded
                }
            };
        case LogsActionTypes.ResetFetchedLogsSuccess:
            return {
                ...state,
                logs: {
                    ...state.logs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.logs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingLogs: {
                    ...state.isLoadingLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLogsLoaded: {
                    ...state.isLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isMasterDetailExpanded[action.sliceKey ?? currentSliceKey]
                }
            };
        case LogsActionTypes.ResetFetchedSummaryLogsSuccess:
            return {
                ...state,
                summaryLogs: {
                    ...state.summaryLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.logs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryLogs: {
                    ...state.isLoadingSummaryLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLogsLoaded: {
                    ...state.isSummaryLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isMasterDetailExpanded[action.sliceKey ?? currentSliceKey]
                } 
            };
        case LogsActionTypes.ResetFetchedFullLogs:
            return {
                ...state,
                fullLogs: {
                    ...state.fullLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.fullLogs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingFullLogs: {
                    ...state.isLoadingFullLogs,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isFullLogsLoaded: {
                    ...state.isFullLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isMasterDetailExpanded[action.sliceKey ?? currentSliceKey]
                } 
            };

        case LogsActionTypes.ResetState:
            const val = {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.dateRange[action.sliceKey ?? currentSliceKey]
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.dateRangeOption[action.sliceKey ?? currentSliceKey]
                },
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.drilldownDateRange[action.sliceKey ?? currentSliceKey]
                },
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.filterConditions[action.sliceKey ?? currentSliceKey]
                },
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.count[action.sliceKey ?? currentSliceKey]
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingCount[action.sliceKey ?? currentSliceKey]
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isCountLoaded[action.sliceKey ?? currentSliceKey]
                },
                fullCount: {
                    ...state.fullCount,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.fullCount[action.sliceKey ?? currentSliceKey]
                },
                isLoadingFullCount: {
                    ...state.isLoadingFullCount,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingFullCount[action.sliceKey ?? currentSliceKey]
                },
                isFullCountLoaded: {
                    ...state.isFullCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isFullCountLoaded[action.sliceKey ?? currentSliceKey]
                },
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.chartGroupingOption[action.sliceKey ?? currentSliceKey]
                },
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.chartData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingChart[action.sliceKey ?? currentSliceKey]
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isChartLoaded[action.sliceKey ?? currentSliceKey]
                },
                fullChartData: {
                    ...state.fullChartData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.fullChartData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingFullChart: {
                    ...state.isLoadingFullChart,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingFullChart[action.sliceKey ?? currentSliceKey]
                },
                isFullChartLoaded: {
                    ...state.isFullChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isFullChartLoaded[action.sliceKey ?? currentSliceKey]
                },
                logs: {
                    ...state.logs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.logs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingLogs: {
                    ...state.isLoadingLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingLogs[action.sliceKey ?? currentSliceKey]
                },
                isLogsLoaded: {
                    ...state.isLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLogsLoaded[action.sliceKey ?? currentSliceKey]
                },
                fullLogs: {
                    ...state.fullLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.fullLogs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingFullLogs: {
                    ...state.isLoadingFullLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingFullLogs[action.sliceKey ?? currentSliceKey]
                },
                isFullLogsLoaded: {
                    ...state.isFullLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isFullLogsLoaded[action.sliceKey ?? currentSliceKey]
                },
                gridState: {
                    ...state.gridState,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.gridState[action.sliceKey ?? currentSliceKey]
                },
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isMasterDetailExpanded[action.sliceKey ?? currentSliceKey]
                },
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.error[action.sliceKey ?? currentSliceKey]
                }
            };

            if (!action.payload) {
                val.behaviorType = initialLogsState.behaviorType;
                val.users = initialLogsState.users;
                val.assets = initialLogsState.assets;
            }

            return val;
        case LogsActionTypes.ResetSummaryState:
            return {
                ...state,
                summaryDateRange: {
                    ...state.summaryDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryDateRange[action.sliceKey ?? currentSliceKey]
                },
                summaryDateRangeOption: {
                    ...state.summaryDateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryDateRangeOption[action.sliceKey ?? currentSliceKey]
                },
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryDrilldownDateRange[action.sliceKey ?? currentSliceKey]
                },
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryFilterConditions[action.sliceKey ?? currentSliceKey]
                },
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryCount[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingSummaryCount[action.sliceKey ?? currentSliceKey]
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isSummaryCountLoaded[action.sliceKey ?? currentSliceKey]
                },
                summaryEntities: {
                    ...state.summaryEntities,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryEntities[action.sliceKey ?? currentSliceKey]
                },
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingSummary[action.sliceKey ?? currentSliceKey]
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isSummaryLoaded[action.sliceKey ?? currentSliceKey]
                },
                summaryGridState: {
                    ...state.summaryGridState,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryGridState[action.sliceKey ?? currentSliceKey]
                },
                summaryLogs: {
                    ...state.summaryLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.summaryLogs[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryLogs: {
                    ...state.isLoadingSummaryLogs,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isLoadingSummaryLogs[action.sliceKey ?? currentSliceKey]
                },
                isSummaryLogsLoaded: {
                    ...state.isSummaryLogsLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isSummaryLogsLoaded[action.sliceKey ?? currentSliceKey]
                },
                isMasterDetailExpanded: {
                    ...state.isMasterDetailExpanded,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.isMasterDetailExpanded[action.sliceKey ?? currentSliceKey]
                },
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: initialLogsState.error[action.sliceKey ?? currentSliceKey]
                },
            };
            
        default:
            return state;
    }
}
