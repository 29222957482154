import { FilterCondition } from ".";
import { Condition } from "../_components/condition-builder/condition-builder.component";

interface BaseCustomAlert {
    alertTypeId?: number;
    name: string;
    description: string;
    decayMinutes: number;
    expirationMinutes: number;
    severityId: number;
    // categoryId?: number;
    clientIds?: string[];
    status: number;
    applyIPWhitelist: boolean,
    type?: string; // aka tag
    correlationFields: string[],
    createdDate?: string;
    updatedDate?: string;
    createdBy?: string,
    updatedBy?: string,
    isReadOnly?: boolean,
    tacticId?: string,
    techniqueIds?: string,
}

export interface CustomAlert extends BaseCustomAlert {
    filters: FilterCondition[][];
}

export interface ConditionCustomAlert extends BaseCustomAlert {
    condition: Condition;
}

export interface SeverityMethod {
    id: number;
    method: string;
    description: string;
};

export interface AlertCategory {
    alertCategoryID: number;
    categoryName: string;
    description: string;
    rank: number;
}

export interface AlertClient {
    clientID: string;
    clientName: string;
    selected: boolean;
}

export interface MitreTactic {
    tacticID: string;
    name: string;
    mitreTechniques: MitreTechnique[];
}

export interface MitreTechnique {
    techniqueID: string;
    name: string;
}
