import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";
import { AssetBehaviorState, initialAssetBehaviorState } from "../state/asset-behavior.state";
import { AssetBehaviorActions, AssetBehaviorActionTypes } from "../actions/asset-behavior.actions";

export function assetBehaviorReducers(
    state: AssetBehaviorState = initialAssetBehaviorState,
    action: AssetBehaviorActions
) {
    let result = { ...state };
    const initialState = JSON.parse(JSON.stringify(initialAssetBehaviorState));

    switch (action.type) {
        case AssetBehaviorActionTypes.SetSeedResource:
            return {
                ...state,
                seedResource: action.payload,
                behaviors: initialState.behaviors,
                dashboardData: initialState.dashboardData,
                isLoadingDashboard: false,
                isDashboardLoaded: false,
                analytics: initialState.analytics,
                connections: initialState.connections,
                eventsOfInterest: initialState.eventsOfInterest,
                localizedAlerts: initialState.localizedAlerts
            };
        case AssetBehaviorActionTypes.SetAssociatedResources:
            return {
                ...state, 
                associatedResources: action.payload
            };
        
        case AssetBehaviorActionTypes.FetchAssociatedResources:
            return {
                ...state,
                isLoadingAssociated: true,
                isAssociatedLoaded: false,
                dashboardData: initialState.dashboardData
            };
        case AssetBehaviorActionTypes.FetchAssociatedResourcesSuccess:
            return {
                ...state,
                associatedResources: action.payload,
                isLoadingAssociated: false,
                isAssociatedLoaded: true
            };
        case AssetBehaviorActionTypes.FetchAssociatedResourcesFailure:
            return {
                ...state,
                associatedResources: null,
                isLoadingAssociated: false,
                isAssociatedLoaded: false
            };

        case AssetBehaviorActionTypes.SetDashboardIndex:
            return {
                ...state,
                dashboardIndex: action.payload
            };
        case AssetBehaviorActionTypes.FetchDashboard:
            return {
                ...state,
                behaviors: initialState.behaviors,
                dashboardData: initialState.dashboardData,
                isLoadingDashboard: true,
                isDashboardLoaded: false
            };
        case AssetBehaviorActionTypes.FetchDashboardSuccess:
            return {
                ...state,
                behaviors: action.payload.behaviors,
                dashboardData: action.payload.dashboardData,
                isLoadingDashboard: false,
                isDashboardLoaded: true
            };
        case AssetBehaviorActionTypes.FetchDashboardFailure:
            return {
                ...state,
                behaviors: null,
                dashboardData: null,
                isLoadingDashboard: false,
                isDashboardLoaded: false
            }

        case AssetBehaviorActionTypes.SetDateRange:
            result.dateRange = action.payload.dateRange;
            result.dateRangeOption = action.payload.dateRangeOption;
            result.analytics = {
                ...result.analytics,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.analytics.filterConditions.filter(f => f.conditions)
            };
            result.connections = {
                ...result.connections,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.connections.filterConditions.filter(f => f.conditions)
            };
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.eventsOfInterest.filterConditions.filter(f => f.conditions)
            };
            result.localizedAlerts = {
                ...result.localizedAlerts,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
                drilldownDateRange: initialState.analytics.drilldownDateRange,
                filterConditions: result.localizedAlerts.filterConditions.filter(f => f.conditions)
            };
            return result;

        case AssetBehaviorActionTypes.FetchTimeline:
            return {
                ...state,
                rawTimeline: undefined,
                isLoadingTimeline: true,
                isTimelineLoaded: false
            };
        case AssetBehaviorActionTypes.FetchTimelineSuccess:
            return {
                ...state,
                rawTimeline: action.payload,
                isLoadingTimeline: false,
                isTimelineLoaded: true
            };
        case AssetBehaviorActionTypes.FetchTimelineFailure:
            return {
                ...state,
                rawTimeline: null,
                isLoadingTimeline: false,
                isTimelineLoaded: false
            };

        case AssetBehaviorActionTypes.SetAnalyticsDateRange:
            result.analytics = {
                ...result.analytics,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case AssetBehaviorActionTypes.SetAnalyticsDrilldownDateRange:
            result.analytics = {
                ...result.analytics,
                drilldownDateRange: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.ResetAnalyticsDrilldownDateRange:
            result.analytics = {
                ...result.analytics,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case AssetBehaviorActionTypes.AddAnalyticsFilters:
            const analyticsFilters = state.analytics.filterConditions.concat(action.payload);
            result.analytics = {
                ...result.analytics,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(analyticsFilters)
            };            return result;
        case AssetBehaviorActionTypes.RemoveAnalyticsFilter:
            result.analytics = {
                ...result.analytics,
                filterConditions: state.analytics.filterConditions.filter(c => c !== action.payload)
            };
            return result;
        case AssetBehaviorActionTypes.OverwriteAnalyticsFilters:
            result.analytics = {
                ...result.analytics,
                filterConditions: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCount:
            result.analytics = {
                ...result.analytics,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCountSuccess:
            result.analytics = {
                ...result.analytics,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCountFailure:
            result.analytics = {
                ...result.analytics,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCounts:
            result.analytics = {
                ...result.analytics,
                counts: initialState.analytics.counts,
                isLoadingCounts: true,
                isCountsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCountsSuccess:
            result.analytics = {
                ...result.analytics,
                counts: action.payload,
                isLoadingCounts: false,
                isCountsLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsCountsFailure:
            result.analytics = {
                ...result.analytics,
                counts: initialState.analytics.counts,
                isLoadingCounts: false,
                isCountsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.SetAnalyticsChartGroupingOption:
            result.analytics = {
                ...result.analytics,
                chartGroupingOption: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsChart:
            result.analytics = {
                ...result.analytics,
                chartData: undefined,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsChartSuccess:
            result.analytics = {
                ...result.analytics,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true,
            };
            return result;
        case AssetBehaviorActionTypes.FetchAnalyticsChartFailure:
            result.analytics = {
                ...result.analytics,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchThreatWatchAlerts:
            result.analytics = {
                ...result.analytics,
                isLoadingThreatWatchAlerts: true,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchThreatWatchAlertsSuccess:
            result.analytics = {
                ...result.analytics,
                threatWatchAlerts: action.payload,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchThreatWatchAlertsFailure:
            result.analytics = {
                ...result.analytics,
                error: action.payload,
                threatWatchAlerts: null,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetFetchedThreatWatchAlertsSuccess:
            result.analytics = {
                ...result.analytics,
                threatWatchAlerts: initialState.analytics.threatWatchAlerts,
                isLoadingThreatWatchAlerts: false,
                isThreatWatchAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetAnalyticsState:
            result.analytics = {
                ...initialState.analytics,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case AssetBehaviorActionTypes.SetConnectionsDateRange:
            result.connections = {
                ...result.connections,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption,
            };
            return result;
        case AssetBehaviorActionTypes.SetConnectionsDrilldownDateRange:
            result.connections = {
                ...result.connections,
                drilldownDateRange: action.payload,
            };
            return result;
        case AssetBehaviorActionTypes.ResetConnectionsDrilldownDateRange:
            result.connections = {
                ...result.connections,
                drilldownDateRange: initialState.connections.drilldownDateRange,
            };
            return result;
        case AssetBehaviorActionTypes.AddConnectionsFilters:
            const connectionsFilters = state.connections.filterConditions.concat(action.payload);
            result.connections = {
                ...result.connections,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(connectionsFilters),
            };
            return result;
        case AssetBehaviorActionTypes.RemoveConnectionsFilter:
            result.connections = {
                ...result.connections,
                filterConditions: state.connections.filterConditions.filter(c => c !== action.payload),
            };
            return result;
        case AssetBehaviorActionTypes.OverwriteConnectionsFilters:
            result.connections = {
                ...result.connections,
                filterConditions: action.payload,
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsCount:
            result.connections = {
                ...result.connections,
                isLoadingCount: true,
                isCountLoaded: false,
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsCountSuccess:
            result.connections = {
                ...result.connections,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsCountFailure:
            result.connections = {
                ...result.connections,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false,
            };
            return result;
        case AssetBehaviorActionTypes.SetConnectionsChartGroupingOption:
            result.connections = {
                ...result.connections,
                chartGroupingOption: action.payload,
            }
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsChart:
            result.connections = {
                ...result.connections,
                isLoadingChart: true,
                isChartLoaded: false,
            }
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsChartSuccess:
            result.connections = {
                ...result.connections,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true,
            }
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsChartFailure:
            result.connections = {
                ...result.connections,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnections:
            result.connections = {
                ...result.connections,
                isLoadingLogs: true,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsSuccess:
            result.connections = {
                ...result.connections,
                logs: action.payload,
                isLoadingLogs: false,
                isLogsLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchConnectionsFailure:
            result.connections = {
                ...result.connections,
                error: action.payload,
                logs: null,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetFetchedConnectionsSuccess:
            result.connections = {
                ...result.connections,
                logs: initialState.connections.logs,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetConnectionsState:
            result.connections = {
                ...initialState.connections,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case AssetBehaviorActionTypes.SetEventsOfInterestDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case AssetBehaviorActionTypes.SetEventsOfInterestDrilldownDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                drilldownDateRange: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.ResetEventsOfInterestDrilldownDateRange:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case AssetBehaviorActionTypes.AddEventsOfInterestFilters:
            const eoiFilters = state.eventsOfInterest.filterConditions.concat(action.payload);
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(eoiFilters)
            };
            return result;
        case AssetBehaviorActionTypes.RemoveEventsOfInterestFilter:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: state.eventsOfInterest.filterConditions.filter(c => c !== action.payload),
            };
            return result;
        case AssetBehaviorActionTypes.OverwriteEventsOfInterestFilters:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                filterConditions: action.payload,
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestCount:
            result.rawTimeline = initialState.rawTimeline;
            result.isLoadingTimeline = false;
            result.isTimelineLoaded = false;
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestCountSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestCountFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false,
            };
            return result;
        case AssetBehaviorActionTypes.SetEventsOfInterestChartGroupingOption:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartGroupingOption: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestChart:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestChartSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestChartFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterest:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                isLoadingLogs: true,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                logs: action.payload,
                isLoadingLogs: false,
                isLogsLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchEventsOfInterestFailure:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                error: action.payload,
                logs: null,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetFetchedEventsOfInterestSuccess:
            result.eventsOfInterest = {
                ...result.eventsOfInterest,
                logs: initialState.eventsOfInterest.logs,
                isLoadingLogs: false,
                isLogsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetEventsOfInterestState:
            result.eventsOfInterest = {
                ...initialState.eventsOfInterest,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        case AssetBehaviorActionTypes.SetLocalizedAlertsDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
            return result;
        case AssetBehaviorActionTypes.SetLocalizedAlertsDrilldownDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                drilldownDateRange: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.ResetLocalizedAlertsDrilldownDateRange:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                drilldownDateRange: initialState.analytics.drilldownDateRange
            };
            return result;
        case AssetBehaviorActionTypes.AddLocalizedAlertsFilters:
            const localizedAlertFilters = state.localizedAlerts.filterConditions.concat(action.payload);
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(localizedAlertFilters)
            };
            return result;
        case AssetBehaviorActionTypes.RemoveLocalizedAlertsFilter:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: state.localizedAlerts.filterConditions.filter(c => c !== action.payload)
            };
            return result;
        case AssetBehaviorActionTypes.OverwriteLocalizedAlertsFilters:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                filterConditions: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsCount:
            result.rawTimeline = initialState.rawTimeline;
            result.isLoadingTimeline = false;
            result.isTimelineLoaded = false;
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: 0,
                isLoadingCount: true,
                isCountLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsCountSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsCountFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.SetLocalizedAlertsChartGroupingOption:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartGroupingOption: action.payload
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsChart:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                isLoadingChart: true,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsChartSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsChartFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlerts:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                isLoadingLocalizedAlerts: true,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                localizedAlerts: action.payload,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: true
            };
            return result;
        case AssetBehaviorActionTypes.FetchLocalizedAlertsFailure:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                error: action.payload,
                localizedAlerts: null,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetFetchedLocalizedAlertsSuccess:
            result.localizedAlerts = {
                ...result.localizedAlerts,
                localizedAlerts: initialState.localizedAlerts.localizedAlerts,
                isLoadingLocalizedAlerts: false,
                isLocalizedAlertsLoaded: false
            };
            return result;
        case AssetBehaviorActionTypes.ResetLocalizedAlertsState:
            result.localizedAlerts = {
                ...initialState.localizedAlerts,
                dateRange: result.dateRange,
                dateRangeOption: result.dateRangeOption
            };
            return result;

        default:
            return state;
    }
}
