import { AlertScansState, initialAlertScansState } from "../state/alert-scans.state";
import { AlertScansActions, AlertScansActionTypes } from "../actions/alert-scans.actions";
import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";

export function alertScansReducers(
    state: AlertScansState = initialAlertScansState,
    action: AlertScansActions
) {
    switch (action.type) {
        case AlertScansActionTypes.SetDateRange:
            return {
                ...state,
                dateRange: action.payload.dateRange,
                dateRangeOption: action.payload.dateRangeOption
            };
        case AlertScansActionTypes.SetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: action.payload
            };
        case AlertScansActionTypes.ResetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: initialAlertScansState.drilldownDateRange
            };

        case AlertScansActionTypes.AddFilters:
            const filters = state.filterConditions.concat(action.payload);
            return {
                ...state,
                filterConditions: StoredConciergeService.getDistinctConfiguredFilters(filters)
            };
        case AlertScansActionTypes.RemoveFilter:
            return {
                ...state,
                filterConditions: state.filterConditions.filter(c => c !== action.payload)
            };
        case AlertScansActionTypes.OverwriteFilters:
            return {
                ...state,
                filterConditions: action.payload
            };
        case AlertScansActionTypes.IncludeActiveScans:
            return {
                ...state,
                isActiveScansIncluded: action.payload
            };
        case AlertScansActionTypes.IncludePenTestScans:
            return {
                ...state,
                isPenTestScansIncluded: action.payload
            };

        case AlertScansActionTypes.FetchCount:
            return {
                ...state,
                isLoadingCount: true,
                isCountLoaded: false
            };
        case AlertScansActionTypes.FetchCountSuccess:
            return { 
                ...state, 
                count: action.payload,
                isLoadingCount: false,
                isCountLoaded: true,
            };
        case AlertScansActionTypes.FetchCountFailure:
            return {
                ...state,
                count: 0,
                isLoadingCount: false,
                isCountLoaded: false
            };

        case AlertScansActionTypes.SetChartGroupingOption:
            return {
                ...state,
                chartGroupingOption: action.payload
            };
        case AlertScansActionTypes.FetchChart:
            return {
                ...state,
                isLoadingChart: true,
                isChartLoaded: false
            };
        case AlertScansActionTypes.FetchChartSuccess:
            return {
                ...state,
                chartData: action.payload,
                isLoadingChart: false,
                isChartLoaded: true
            };
        case AlertScansActionTypes.FetchChartFailure:
            return {
                ...state,
                chartData: null,
                isLoadingChart: false,
                isChartLoaded: false
            };

        case AlertScansActionTypes.FetchAlertScans:
            return { 
                ...state, 
                isLoadingScans: true,
                isScansLoaded: false
            };
        case AlertScansActionTypes.FetchAlertScansSuccess:
            return { 
                ...state, 
                scans: action.payload,
                isLoadingScans: false,
                isScansLoaded: true
            };
        case AlertScansActionTypes.FetchAlertScansFailure:
            return { 
                ...state, 
                error: action.payload,
                scans: null,
                isLoadingScans: false,
                isScansLoaded: false
            };
        case AlertScansActionTypes.ResetFetchedAlertScansSuccess:
            return {
                ...state,
                scans: initialAlertScansState.scans,
                isLoadingScans: false,
                isScansLoaded: false
            };

        case AlertScansActionTypes.ResetState:
            return initialAlertScansState;

        default:
            return state;
    }
}
