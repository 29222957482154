import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {firstValueFrom, from, Observable} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoggerService } from './logger.service';
import { HEADERS } from './app.headers';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { IDToken, OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private jwtHelper: JwtHelperService = new JwtHelperService();
  private tokenName: string = environment.tokenName;
  private refreshTokenName: string = environment.refreshTokenName;
  private ssoLogoutUrlName: string = 'ssoLogoutUrl';
  private options = ({ headers: HEADERS });
  private lastActivityName: string = 'last_activity';
  private classicLoginName: string = 'classic';

  constructor(private http: HttpClient,
              private router: Router,
              private route: ActivatedRoute,
              private logger: LoggerService,
              @Inject(OKTA_AUTH) public oktaAuth: OktaAuth
  ) {

  }

  login(username: string, password: string): Observable<any> {
    let body = JSON.stringify({ 'UserName': username, 'Password': password });
    return this.http.post(`${environment.apiEndpoint}/authenticate/Login`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        this.addClassicLoginCache();

        return response;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  loggedIn(): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem(this.tokenName));
    } catch (error) {
      this.logger.handleError(error);
      return false;
    }
  }

  addTokens(tokenData: any): void {
    if (tokenData) {
      if (tokenData.id_token) {
        localStorage.setItem(this.tokenName, tokenData.id_token);
      }
      if (tokenData.refresh_token) {
        localStorage.setItem(this.refreshTokenName, tokenData.refresh_token);
      }
    }
  }

  refreshToken(): Observable<{} | any> {
    let activity = localStorage.getItem(this.lastActivityName);
    let token: any = localStorage.getItem(this.refreshTokenName);
    let body = JSON.stringify({'Token': token, 'Activity': activity});
    return this.http.post(`${environment.apiEndpoint}/authenticate/Refresh`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        return response;
        },
        error => {
          this.logger.handleError(error);
          throw error;
        }
      )
    );
  }

  async getToken(): Promise<string> {
    if (this.tokenRequiresRefresh()) {
      if (this.tokenExists()) {
        await this.refreshToken().toPromise();
        return localStorage.getItem(this.tokenName);
      } else {
        await this.reauthenticateAsync();
      }
    }
    else {
      return localStorage.getItem(this.tokenName);
    }
  }

  tokenExists(): boolean {
    let token = localStorage.getItem(this.tokenName);
    return token != null;
  }

  tokenRequiresRefresh(): boolean {
    let loggedIn = this.loggedIn();
    if (loggedIn) {
      let activity = new Date().toUTCString();
      localStorage.setItem(this.lastActivityName, activity);
    }

    return !loggedIn;
  }

  logout(statusCode?: number): void {

    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.refreshTokenName);

    this.clearOldNonces();

    let queryParams = [];

    if (window.location.pathname.length
      && !window.location.pathname.includes('login')
      && !window.location.pathname.includes('logout')
    ) {
      queryParams.push('returnUrl=' + window.location.pathname);
    }

    if (window.location.search.length) {
      let existingParams = window.location.search.substring(1).split('&');
      queryParams = queryParams.concat(existingParams);
    }

    let ssoLogoutUrl = localStorage.getItem(this.ssoLogoutUrlName);
    if (ssoLogoutUrl) {
      localStorage.removeItem(this.ssoLogoutUrlName);
      window.location.href = ssoLogoutUrl;
    } else {
      window.location.href = '/login' + (queryParams.length ? '?' + queryParams.join('&') + window.location.hash : '');
    }
  }

  updateClient(clientId: string): Observable<any> {
    let activity = localStorage.getItem(this.lastActivityName);
    let token: any = localStorage.getItem(this.refreshTokenName);
    let body = JSON.stringify({ 'ClientID': clientId, "RefreshToken": { "Activity": activity, 'Token': token } });
    return this.http.post(`${environment.apiEndpoint}/authenticate/UpdateClient`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        return response;
      })
    );
  }

  validateSMSCode(smsCode: string): Observable<any> {
    let token: any = localStorage.getItem(this.refreshTokenName);
    let body = JSON.stringify({ 'RefreshToken': { 'Token': token }, 'smsCode': smsCode });
    return this.http.post(`${environment.apiEndpoint}/authenticate/ValidateSmsCode`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        return response;
      })
    );
  }

  confirmOptIn(optIn: boolean): Observable<any> {
    let token: any = localStorage.getItem(this.refreshTokenName);
    let body = JSON.stringify({ 'RefreshToken': { Token: token }, 'optIn': optIn });
    return this.http.post(`${environment.apiEndpoint}/authenticate/ConfirmOptIn`, body, this.options).pipe( // TODO: confirmoptin???
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        return response;
      })
    );
  }

  getSSOInfo(clientID: string): Observable<any> {
    let body = JSON.stringify(clientID);
    return this.http.post(`${environment.apiEndpoint}/authenticate/GetSSOInfo`, body, this.options);
  }

  ssoLogin(clientID: string, code: string) {
    let body = JSON.stringify({ clientID: clientID, code: code, url: window.location.href });
    return this.http.post(`${environment.apiEndpoint}/authenticate/SSOLogin`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        if (response.success) {
          this.addTokens(data.token);
        }
        return response;
      })
    );
  }

  addLogoutUrl(url: string) {
    if (url) {
      localStorage.setItem(this.ssoLogoutUrlName, url);
    }
  }

  VerifyOktaIDToken(idToken: string): Observable<any> {
    let body = JSON.stringify( idToken );
    return this.http.post(`${environment.apiEndpoint}/authenticate/VerifyOktaIDToken`, body, this.options).pipe(
      map((res: any) => {
        const response = res;

        if (response.success) {
          const data = response.data;
          this.addTokens(data.token);
          return response;
        } else {
          throw new Error(response.data.error);
        }
      }),
      catchError(error => {
        if (error instanceof Error) {
          throw error;
        } else {
          throw new Error(error.statusText ?? "Internal Server Error");
        }
      })
    );
  }

  verifyDUOResponse(clientID: string, signedResponse: string): Observable<any> {
    let body = JSON.stringify({ clientID: clientID, signedResponse: signedResponse });
    return this.http.post(`${environment.apiEndpoint}/authenticate/VerifyDUOResponse`, body, this.options).pipe(
      map((res: any) => {
        const response = res;
        const data = response.data;
        this.addTokens(data.token);
        return response;
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  private clearOldNonces() {
    //cleanup trash left behind by auth0
    Object.keys(localStorage).forEach(key => {
      if (!key.startsWith('com.auth0.auth')) {
        return;
      }
      localStorage.removeItem(key);
    });
  }

  addClassicLoginCache() {
    localStorage.setItem(this.classicLoginName, 'true');
  }

  removeClassicLoginCache() {
    localStorage.removeItem(this.classicLoginName);
  }

  isClassicLogin(): boolean {
    return (localStorage.getItem(this.classicLoginName) != null)
  }

  async logoutAsync() {
    localStorage.removeItem(this.tokenName);
    localStorage.removeItem(this.refreshTokenName);

    await this.oktaAuth.tokenManager.clear();

    if (this.isClassicLogin()) {
      this.removeClassicLoginCache();
      this.router.navigate(["/login"]);
    }
    else {
      await this.oktaAuth.signOut();
      //this.router.navigate(["/okta/login"]);
    }
  }

  async VerifyOktaIDTokenAsync() {
    const oktaIdToken: IDToken = await this.oktaAuth.tokenManager.get('idToken') as IDToken;

    return firstValueFrom(this.VerifyOktaIDToken(oktaIdToken.idToken));

  }

  async reauthenticateAsync() {
    if (this.isClassicLogin()) {
      this.removeClassicLoginCache();
      this.router.navigate(["/login"]);
    }
    else {
      const oktaIdToken: IDToken = await this.oktaAuth.tokenManager.get('idToken') as IDToken;

      if (oktaIdToken != null) {
        this.VerifyOktaIDToken(oktaIdToken.idToken).subscribe(
          {
            next: (response) => {
              let queryParams = {};
              let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

              for (let param of Object.keys(this.route.snapshot.queryParams)) {
                if (param !== 'returnUrl') {
                  queryParams[param] = this.route.snapshot.queryParams[param];
                }
              }
              this.router.navigate([returnUrl], { preserveFragment: true, queryParams: queryParams });
            },
            error: (error) => {
              this.router.navigate(["/okta/login"]);
            }
          }
        );
      }
      else {
        this.router.navigate(["/okta/login"]);
      }
    }
  }
}
