import { EntityProperty } from './entity-property';
import { DateTimeService } from '../_services/datetime.service';

export class Entity {
	protected data: any = null;
    static clientData:any;

	constructor(data?: any) {
		this.data = data;
	}

	set blob(data: any) {
		this.data = data;
	}

	static formatDateLabel(label: string, dateTimeService: DateTimeService): string {
		const token: string = "{0}";

		let replacement = '';
		if (dateTimeService.getTimeZoneAbbreviation().length > 0) {
		replacement = '(' + dateTimeService.getTimeZoneAbbreviation() + ')';
		}

		return label.replace(token, replacement);
  }

  static severityQuickFilter(params: any): string {
    const severityValues = {
      0: 'No Threat',
      1: 'Informational',
      2: 'Minor',
      3: 'Medium',
      4: 'Major',
      5: 'Critical'
    };
    return severityValues[params.value || 0];
  }
}
