import { Action } from "@ngrx/store";
import { ThreatWatchAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, CategoryCounts, LocalizedAlert, Log, BehavioralResource, BehaviorData, Behavior } from "../../_models";

export enum AssetBehaviorActionTypes {
    SetSeedResource = '[Asset Behavior] Set Seed Resource',
    SetAssociatedResources = '[Asset Behavior] Set Associated Resources',
    FetchAssociatedResources = '[Asset Behavior] Fetch Associated Resources',
    FetchAssociatedResourcesSuccess = '[Asset Behavior] Fetch Associated Resources SUCCESS',
    FetchAssociatedResourcesFailure = '[Asset Behavior] Fetch Associated Resources FAILURE',
    
    SetDashboardIndex = '[Asset Behavior] Set Dashboard Index',
    FetchDashboard = '[Asset Behavior] Fetch Dashboard',
    FetchDashboardSuccess = '[Asset Behavior] Fetch Dashboard SUCCESS',
    FetchDashboardFailure = '[Asset Behavior] Fetch Dashboard Failure',
    
    SetDateRange = '[Asset Behavior] Set Date Range',

    FetchTimeline = '[Asset Behavior] Fetch Timeline',
    FetchTimelineSuccess = '[Asset Behavior] Fetch Timeline SUCCESS',
    FetchTimelineFailure = '[Asset Behavior] Fetch Timeline FAILURE',

    SetAnalyticsDateRange = '[Asset Behavior] Set Analytics Date Range',
    SetAnalyticsDrilldownDateRange = '[Asset Behavior] Set Analytics Drilldown Date Range',
    ResetAnalyticsDrilldownDateRange = '[Asset Behavior] Reset Analytics Drilldown Date Range',
    AddAnalyticsFilters = '[Asset Behavior] Add Analytics Filters',
    RemoveAnalyticsFilter = '[Asset Behavior] Remove Analytics Filter',
    OverwriteAnalyticsFilters = '[Asset Behavior] Overwrite Analytics Filters',
    FetchAnalyticsCount = '[Asset Behavior] Fetch Analytics Count',
    FetchAnalyticsCountSuccess = '[Asset Behavior] FetchAnalytics  Count SUCCESS',
    FetchAnalyticsCountFailure = '[Asset Behavior] Fetch Analytics Count FAILURE',
    FetchAnalyticsCounts = '[Asset Behavior] Fetch Analytics Counts',
    FetchAnalyticsCountsSuccess = '[Asset Behavior] Fetch Analytics Counts SUCCESS',
    FetchAnalyticsCountsFailure = '[Asset Behavior] Fetch Analytics Counts FAILURE',
    SetAnalyticsChartGroupingOption = '[Asset Behavior] Set Analytics Chart Grouping Option',
    FetchAnalyticsChart = '[Asset Behavior] Fetch Analytics Chart',
    FetchAnalyticsChartSuccess = '[Asset Behavior] Fetch Analytics Chart SUCCESS',
    FetchAnalyticsChartFailure = '[Asset Behavior] Fetch Analytics Chart FAILURES',
    FetchThreatWatchAlerts = '[Asset Behavior] Fetch ThreatWatch Alerts',
    FetchThreatWatchAlertsSuccess = '[Asset Behavior] Fetch ThreatWatch Alerts SUCCESS',
    FetchThreatWatchAlertsFailure = '[Asset Behavior] Fetch ThreatWatch Alerts FAILURE',
    ResetFetchedThreatWatchAlerts = '[Asset Behavior] Clear Fetched ThreatWatch Alerts',
    ResetFetchedThreatWatchAlertsSuccess = '[Asset Behavior] Clear Fetched ThreatWatch Alerts SUCCESS',
    ResetAnalyticsState = '[Asset Behavior] Clear Analytics State',

    SetLocalizedAlertsDateRange = '[Asset Behavior] Set Localized Alerts Date Range',
    SetLocalizedAlertsDrilldownDateRange = '[Asset Behavior] Set Localized Alerts Drilldown Date Range',
    ResetLocalizedAlertsDrilldownDateRange = '[Asset Behavior] Reset Localized Alerts Drilldown Date Range',
    AddLocalizedAlertsFilters = '[Asset Behavior] Add Localized Alerts Filters',
    RemoveLocalizedAlertsFilter = '[Asset Behavior] Remove Localized Alerts Filter',
    OverwriteLocalizedAlertsFilters = '[Asset Behavior] Overwrite Localized Alerts Filters',
    FetchLocalizedAlertsCount = '[Asset Behavior] Fetch Localized Alerts Count',
    FetchLocalizedAlertsCountSuccess = '[Asset Behavior] Fetch Localized Alerts Count SUCCESS',
    FetchLocalizedAlertsCountFailure = '[Asset Behavior] Fetch Localized Alerts Count FAILURE',
    SetLocalizedAlertsChartGroupingOption = '[Asset Behavior] Set Localized Alerts Chart Grouping Option',
    FetchLocalizedAlertsChart = '[Asset Behavior] Fetch Localized Alerts Chart',
    FetchLocalizedAlertsChartSuccess = '[Asset Behavior] Fetch Localized Alerts Chart SUCCESS',
    FetchLocalizedAlertsChartFailure = '[Asset Behavior] Fetch Localized Alerts Chart FAILURES',
    FetchLocalizedAlerts = '[Asset Behavior] Fetch Localized Alerts',
    FetchLocalizedAlertsSuccess = '[Asset Behavior] Fetch Localized Alerts SUCCESS',
    FetchLocalizedAlertsFailure = '[Asset Behavior] Fetch Localized Alerts FAILURE',
    ResetFetchedLocalizedAlerts = '[Asset Behavior] Clear Fetched Localized Alerts',
    ResetFetchedLocalizedAlertsSuccess = '[Asset Behavior] Clear Fetched Localized Alerts SUCCESS',
    ResetLocalizedAlertsState = '[Asset Behavior] Clear Localized Alerts State',

    SetConnectionsDateRange = '[Asset Behavior] Set Connections Date Range',
    SetConnectionsDrilldownDateRange = '[Asset Behavior] Set Connections Drilldown Date Range',
    ResetConnectionsDrilldownDateRange = '[Asset Behavior] Reset Connections Drilldown Date Range',
    AddConnectionsFilters = '[Asset Behavior] Add Connections Filters',
    RemoveConnectionsFilter = '[Asset Behavior] Remove Connections Filter',
    OverwriteConnectionsFilters = '[Analytics] Overwrite Connections Filters',
    FetchConnectionsCount = '[Asset Behavior] Fetch Connections Count',
    FetchConnectionsCountSuccess = '[Asset Behavior] Fetch Connections Count SUCCESS',
    FetchConnectionsCountFailure = '[Asset Behavior] Fetch Connections Count FAILURE',
    SetConnectionsChartGroupingOption = '[Asset Behavior] Set Connections Chart Grouping Option',
    FetchConnectionsChart = '[Asset Behavior] Fetch Connections Chart',
    FetchConnectionsChartSuccess = '[Asset Behavior] Fetch Connections Chart SUCCESS',
    FetchConnectionsChartFailure = '[Asset Behavior] Fetch Connections Chart FAILURES',
    FetchConnections = '[Asset Behavior] Fetch Connections',
    FetchConnectionsSuccess = '[Asset Behavior] Fetch Connections SUCCESS',
    FetchConnectionsFailure = '[Asset Behavior] Fetch Connections FAILURE',
    ResetFetchedConnections = '[Asset Behavior] Clear Fetched Connections',
    ResetFetchedConnectionsSuccess = '[Asset Behavior] Clear Fetched Connections SUCCESS',
    ResetConnectionsState = '[Asset Behavior] Clear Connections State',

    SetEventsOfInterestDateRange = '[Asset Behavior] Set Events of Interest Date Range',
    SetEventsOfInterestDrilldownDateRange = '[Asset Behavior] Set Events of Interest Drilldown Date Range',
    ResetEventsOfInterestDrilldownDateRange = '[Asset Behavior] Reset Events of Interest Drilldown Date Range',
    AddEventsOfInterestFilters = '[Asset Behavior] Add Events of Interest Filters',
    RemoveEventsOfInterestFilter = '[Asset Behavior] Remove Events of Interest Filter',
    OverwriteEventsOfInterestFilters = '[Analytics] Overwrite Events of Interest Filters',
    FetchEventsOfInterestCount = '[Asset Behavior] Fetch Events of Interest Count',
    FetchEventsOfInterestCountSuccess = '[Asset Behavior] Fetch Events of Interest Count SUCCESS',
    FetchEventsOfInterestCountFailure = '[Asset Behavior] Fetch Events of Interest Count FAILURE',
    SetEventsOfInterestChartGroupingOption = '[Asset Behavior] Set Events of Interest Chart Grouping Option',
    FetchEventsOfInterestChart = '[Asset Behavior] Fetch Events of Interest Chart',
    FetchEventsOfInterestChartSuccess = '[Asset Behavior] Fetch Events of Interest Chart SUCCESS',
    FetchEventsOfInterestChartFailure = '[Asset Behavior] Fetch Events of Interest Chart FAILURES',
    FetchEventsOfInterest = '[Asset Behavior] Fetch Events Of Interest',
    FetchEventsOfInterestSuccess = '[Asset Behavior] Fetch Events Of Interest SUCCESS',
    FetchEventsOfInterestFailure = '[Asset Behavior] Fetch Events Of Interest FAILURE',
    ResetFetchedEventsOfInterest = '[Asset Behavior] Clear Fetched Events Of Interest',
    ResetFetchedEventsOfInterestSuccess = '[Asset Behavior] Clear Fetched Events Of Interest SUCCESS',
    ResetEventsOfInterestState = '[Asset Behavior] Clear Events of Interest State',
};

export class SetSeedResource implements Action {
    public readonly type = AssetBehaviorActionTypes.SetSeedResource;
    constructor(readonly payload: string) {}
}
export class SetAssociatedResources implements Action {
    public readonly type = AssetBehaviorActionTypes.SetAssociatedResources;
    constructor(readonly payload: BehavioralResource[]) {}
}

export class FetchAssociatedResources implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAssociatedResources;
    constructor(readonly payload: boolean) {}
}
export class FetchAssociatedResourcesSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAssociatedResourcesSuccess;
    constructor(readonly payload: BehavioralResource[]) {}
}
export class FetchAssociatedResourcesFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAssociatedResourcesFailure;
    constructor(readonly payload: any) {}
}

export class SetDashboardIndex implements Action {
    public readonly type = AssetBehaviorActionTypes.SetDashboardIndex;
    constructor(readonly payload: number) {}
}
export class FetchDashboard implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchDashboardSuccess;
    constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) {}
}
export class FetchDashboardFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchDashboardFailure;
    constructor(readonly payload: any) {}
}

export class FetchDashboardAlerts implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardAlertsSuccess implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardAlertsFailure implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}

export class FetchDashboardEvents implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardEventsSuccess implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardEventsFailure implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}

export class FetchDashboardConnections implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardConnectionsSuccess implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardConnectionsFailure implements Action {
  public readonly type = AssetBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}
export class SetDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}

export class FetchTimeline implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchTimeline;
}
export class FetchTimelineSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchTimelineSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchTimelineFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchTimelineFailure;
    constructor(readonly error: any[]) {}
}

export class SetAnalyticsDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetAnalyticsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetAnalyticsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetAnalyticsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetAnalyticsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetAnalyticsDrilldownDateRange;
}
export class AddAnalyticsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.AddAnalyticsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveAnalyticsFilter implements Action {
    public readonly type = AssetBehaviorActionTypes.RemoveAnalyticsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteAnalyticsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.OverwriteAnalyticsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchAnalyticsCount implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCount;
}
export class FetchAnalyticsCountSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchAnalyticsCountFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCountFailure;
    constructor(readonly payload: Error) {}
}
export class FetchAnalyticsCounts implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCounts;
}
export class FetchAnalyticsCountsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCountsSuccess;
    constructor(readonly payload: CategoryCounts) {}
}
export class FetchAnalyticsCountsFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsCountsFailure;
    constructor(readonly payload: Error) {}
}
export class SetAnalyticsChartGroupingOption implements Action {
    public readonly type = AssetBehaviorActionTypes.SetAnalyticsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchAnalyticsChart implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsChart;
}
export class FetchAnalyticsChartSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchAnalyticsChartFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchAnalyticsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchThreatWatchAlerts implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchThreatWatchAlerts;
    constructor(readonly payload?: boolean) {}
}
export class FetchThreatWatchAlertsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchThreatWatchAlertsSuccess;
    constructor(readonly payload: ThreatWatchAlert[]) {}
}
export class FetchThreatWatchAlertsFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchThreatWatchAlertsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedThreatWatchAlerts implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedThreatWatchAlerts;
}
export class ResetFetchedThreatWatchAlertsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedThreatWatchAlertsSuccess;
}
export class ResetAnalyticsState implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetAnalyticsState;
}

export class SetLocalizedAlertsDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetLocalizedAlertsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetLocalizedAlertsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetLocalizedAlertsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetLocalizedAlertsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetLocalizedAlertsDrilldownDateRange;
}
export class AddLocalizedAlertsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.AddLocalizedAlertsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveLocalizedAlertsFilter implements Action {
    public readonly type = AssetBehaviorActionTypes.RemoveLocalizedAlertsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteLocalizedAlertsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.OverwriteLocalizedAlertsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchLocalizedAlertsCount implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsCount;
}
export class FetchLocalizedAlertsCountSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchLocalizedAlertsCountFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetLocalizedAlertsChartGroupingOption implements Action {
    public readonly type = AssetBehaviorActionTypes.SetLocalizedAlertsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchLocalizedAlertsChart implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsChart;
}
export class FetchLocalizedAlertsChartSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchLocalizedAlertsChartFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchLocalizedAlerts implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlerts;
    constructor(readonly payload?: boolean) {}
}
export class FetchLocalizedAlertsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsSuccess;
    constructor(readonly payload: LocalizedAlert[]) {}
}
export class FetchLocalizedAlertsFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchLocalizedAlertsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedLocalizedAlerts implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedLocalizedAlerts;
}
export class ResetFetchedLocalizedAlertsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedLocalizedAlertsSuccess;
}
export class ResetLocalizedAlertsState implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetLocalizedAlertsState;
}

export class SetConnectionsDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetConnectionsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetConnectionsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetConnectionsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetConnectionsDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetConnectionsDrilldownDateRange;
}
export class AddConnectionsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.AddConnectionsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveConnectionsFilter implements Action {
    public readonly type = AssetBehaviorActionTypes.RemoveConnectionsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteConnectionsFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.OverwriteConnectionsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchConnectionsCount implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsCount;
}
export class FetchConnectionsCountSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchConnectionsCountFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetConnectionsChartGroupingOption implements Action {
    public readonly type = AssetBehaviorActionTypes.SetConnectionsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchConnectionsChart implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsChart;
}
export class FetchConnectionsChartSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchConnectionsChartFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchConnections implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnections;
    constructor(readonly payload?: boolean) {}
}
export class FetchConnectionsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsSuccess;
    constructor(readonly payload: Log[]) {}
}
export class FetchConnectionsFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchConnectionsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedConnections implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedConnections;
}
export class ResetFetchedConnectionsSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedConnectionsSuccess;
}

export class ResetConnectionsState implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetConnectionsState;
}

export class SetEventsOfInterestDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetEventsOfInterestDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetEventsOfInterestDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.SetEventsOfInterestDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetEventsOfInterestDrilldownDateRange implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetEventsOfInterestDrilldownDateRange;
}
export class AddEventsOfInterestFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.AddEventsOfInterestFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveEventsOfInterestFilter implements Action {
    public readonly type = AssetBehaviorActionTypes.RemoveEventsOfInterestFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteEventsOfInterestFilters implements Action {
    public readonly type = AssetBehaviorActionTypes.OverwriteEventsOfInterestFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchEventsOfInterestCount implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestCount;
}
export class FetchEventsOfInterestCountSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchEventsOfInterestCountFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetEventsOfInterestChartGroupingOption implements Action {
    public readonly type = AssetBehaviorActionTypes.SetEventsOfInterestChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchEventsOfInterestChart implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestChart;
}
export class FetchEventsOfInterestChartSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchEventsOfInterestChartFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchEventsOfInterest implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterest;
    constructor(readonly payload?: boolean) {}
}
export class FetchEventsOfInterestSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestSuccess;
    constructor(readonly payload: Log[]) {}
}
export class FetchEventsOfInterestFailure implements Action {
    public readonly type = AssetBehaviorActionTypes.FetchEventsOfInterestFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedEventsOfInterest implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedEventsOfInterest;
}
export class ResetFetchedEventsOfInterestSuccess implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetFetchedEventsOfInterestSuccess;
}

export class ResetEventsOfInterestState implements Action {
    public readonly type = AssetBehaviorActionTypes.ResetEventsOfInterestState;
}

export type AssetBehaviorActions = SetSeedResource | SetAssociatedResources
    | FetchAssociatedResources | FetchAssociatedResourcesSuccess | FetchAssociatedResourcesFailure
    | SetDashboardIndex | FetchDashboard | FetchDashboardSuccess | FetchDashboardFailure
    | SetDateRange
    | FetchTimeline | FetchTimelineSuccess | FetchTimelineFailure

    | SetAnalyticsDateRange | SetAnalyticsDrilldownDateRange | ResetAnalyticsDrilldownDateRange
    | AddAnalyticsFilters | RemoveAnalyticsFilter | OverwriteAnalyticsFilters
    | FetchAnalyticsCount | FetchAnalyticsCountSuccess | FetchAnalyticsCountFailure 
    | FetchAnalyticsCounts | FetchAnalyticsCountsSuccess | FetchAnalyticsCountsFailure
    | SetAnalyticsChartGroupingOption | FetchAnalyticsChart | FetchAnalyticsChartSuccess | FetchAnalyticsChartFailure
    | FetchThreatWatchAlerts | FetchThreatWatchAlertsSuccess | FetchThreatWatchAlertsFailure
    | ResetFetchedThreatWatchAlerts | ResetFetchedThreatWatchAlertsSuccess
    | ResetAnalyticsState
    
    | SetLocalizedAlertsDateRange | SetLocalizedAlertsDrilldownDateRange | ResetLocalizedAlertsDrilldownDateRange
    | AddLocalizedAlertsFilters | RemoveLocalizedAlertsFilter | OverwriteLocalizedAlertsFilters
    | FetchLocalizedAlertsCount | FetchLocalizedAlertsCountSuccess | FetchLocalizedAlertsCountFailure
    | SetLocalizedAlertsChartGroupingOption | FetchLocalizedAlertsChart | FetchLocalizedAlertsChartSuccess | FetchLocalizedAlertsChartFailure
    | FetchLocalizedAlerts | FetchLocalizedAlertsSuccess | FetchLocalizedAlertsFailure
    | ResetFetchedLocalizedAlerts | ResetFetchedLocalizedAlertsSuccess
    | ResetLocalizedAlertsState
    
    | SetConnectionsDateRange | SetConnectionsDrilldownDateRange | ResetConnectionsDrilldownDateRange
    | AddConnectionsFilters | RemoveConnectionsFilter | OverwriteConnectionsFilters
    | FetchConnectionsCount | FetchConnectionsCountSuccess | FetchConnectionsCountFailure
    | SetConnectionsChartGroupingOption | FetchConnectionsChart | FetchConnectionsChartSuccess | FetchConnectionsChartFailure
    | FetchConnections | FetchConnectionsSuccess | FetchConnectionsFailure
    | ResetFetchedConnections | ResetFetchedConnectionsSuccess
    | ResetConnectionsState
    
    | SetEventsOfInterestDateRange | SetEventsOfInterestDrilldownDateRange | ResetEventsOfInterestDrilldownDateRange
    | AddEventsOfInterestFilters | RemoveEventsOfInterestFilter | OverwriteEventsOfInterestFilters
    | FetchEventsOfInterestCount | FetchEventsOfInterestCountSuccess | FetchEventsOfInterestCountFailure
    | SetEventsOfInterestChartGroupingOption | FetchEventsOfInterestChart | FetchEventsOfInterestChartSuccess | FetchEventsOfInterestChartFailure
    | FetchEventsOfInterest | FetchEventsOfInterestSuccess | FetchEventsOfInterestFailure
    | ResetFetchedEventsOfInterest | ResetFetchedEventsOfInterestSuccess
    | ResetEventsOfInterestState;
