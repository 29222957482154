import { Action } from "@ngrx/store";
import { ThreatWatchAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, CategoryCounts, GridState } from "../../_models";

export enum AnalyticsActionTypes {
    PreloadConcierge = '[Analytics] Preload Concierge',

    SetDateRange = '[Analytics] Set Date Range',
    SetDrilldownDateRange = '[Analytics] Set Drilldown Date Range',
    ResetDrilldownDateRange = '[Analytics] Reset Drilldown Date Range',
    
    AddFilters = '[Analytics] Add Filters',
    RemoveFilter = '[Analytics] Remove Filter',
    OverwriteFilters = '[Analytics] Overwrite Filters',

    FetchCount = '[Analytics] Fetch Count',
    FetchCountSuccess = '[Analytics] Fetch Count SUCCESS',
    FetchCountFailure = '[Analytics] Fetch Count FAILURE',
    FetchCounts = '[Analytics] Fetch Counts',
    FetchCountsSuccess = '[Analytics] Fetch Counts SUCCESS',
    FetchCountsFailure = '[Analytics] Fetch Counts FAILURE',

    SetChartGroupingOption = '[Analytics] Set Chart Grouping Option',
    FetchChart = '[Analytics] Fetch Chart',
    FetchChartSuccess = '[Analytics] Fetch Chart SUCCESS',
    FetchChartFailure = '[Analytics] Fetch Chart FAILURES',

    FetchThreatWatchAlerts = '[Analytics] Fetch ThreatWatch Alerts',
    FetchThreatWatchAlertsSuccess = '[Analytics] Fetch ThreatWatch Alerts SUCCESS',
    FetchThreatWatchAlertsFailure = '[Analytics] Fetch ThreatWatch Alerts FAILURE',

    FetchThreatWatchAlertsDashboard = '[Analytics] Fetch ThreatWatch Alerts Dashboard',
    FetchThreatWatchAlertsDashboardSuccess = '[Analytics] Fetch ThreatWatch Alerts Dashboard SUCCESS',
    FetchThreatWatchAlertsDashboardFailure = '[Analytics] Fetch ThreatWatch Alerts Dashboard FAILURE',

    ResetFetchedThreatWatchDashboardAlerts = '[Analytics] Clear Fetched ThreatWatch Dashboard Alerts',
    ResetFetchedThreatWatchDashboardAlertsSuccess = '[Analytics] Clear Fetched ThreatWatch Alerts Dashboard SUCCESS',
    
    ResetFetchedThreatWatchAlerts = '[Analytics] Clear Fetched ThreatWatch Alerts',
    ResetFetchedThreatWatchAlertsSuccess = '[Analytics] Clear Fetched ThreatWatch Alerts SUCCESS',
    SetGridState = '[Analytics] Set Grid State',

    ResetState = '[Analytics] Clear State'
};

export class PreloadConcierge implements Action {
    public readonly type = AnalyticsActionTypes.PreloadConcierge;
    constructor(readonly payload: { 
        dateRange: DateRange, 
        dateRangeOption: DateRangeOption,
        drilldownDateRange?: DateRange,
        filterConditions: ConfiguredFilter[],
        chartGroupingOption?: ChartGroupingOption
    }) {}
}

export class SetDateRange implements Action {
    public readonly type = AnalyticsActionTypes.SetDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetDrilldownDateRange implements Action {
    public readonly type = AnalyticsActionTypes.SetDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetDrilldownDateRange implements Action {
    public readonly type = AnalyticsActionTypes.ResetDrilldownDateRange;
}

export class AddFilters implements Action {
    public readonly type = AnalyticsActionTypes.AddFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveFilter implements Action {
    public readonly type = AnalyticsActionTypes.RemoveFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteFilters implements Action {
    public readonly type = AnalyticsActionTypes.OverwriteFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}

export class FetchCount implements Action {
    public readonly type = AnalyticsActionTypes.FetchCount;
}
export class FetchCountSuccess implements Action {
    public readonly type = AnalyticsActionTypes.FetchCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchCountFailure implements Action {
    public readonly type = AnalyticsActionTypes.FetchCountFailure;
    constructor(readonly payload: Error) {}
}
export class FetchCounts implements Action {
    public readonly type = AnalyticsActionTypes.FetchCounts;
}
export class FetchCountsSuccess implements Action {
    public readonly type = AnalyticsActionTypes.FetchCountsSuccess;
    constructor(readonly payload: CategoryCounts) {}
}
export class FetchCountsFailure implements Action {
    public readonly type = AnalyticsActionTypes.FetchCountsFailure;
    constructor(readonly payload: Error) {}
}

export class SetChartGroupingOption implements Action {
    public readonly type = AnalyticsActionTypes.SetChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchChart implements Action {
    public readonly type = AnalyticsActionTypes.FetchChart;
}
export class FetchChartSuccess implements Action {
    public readonly type = AnalyticsActionTypes.FetchChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchChartFailure implements Action {
    public readonly type = AnalyticsActionTypes.FetchChartFailure;
    constructor(readonly payload: Error) {}
}

export class FetchThreatWatchAlerts implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlerts;
    constructor(readonly payload?: boolean) {}
}
export class FetchThreatWatchAlertsSuccess implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlertsSuccess;
    constructor(readonly payload: ThreatWatchAlert[]) {}
}
export class FetchThreatWatchAlertsFailure implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlertsFailure;
    constructor(readonly payload: Error) {}
}

export class FetchThreatWatchAlertsDashboard implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlertsDashboard;
    constructor(readonly payload?: boolean) {}
}
export class FetchThreatWatchAlertsDashboardSuccess implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlertsDashboardSuccess;
    constructor(readonly payload: ThreatWatchAlert[]) {}
}
export class FetchThreatWatchAlertsDashboardFailure implements Action {
    public readonly type = AnalyticsActionTypes.FetchThreatWatchAlertsDashboardFailure;
    constructor(readonly payload: Error) {}
}

export class ResetFetchedThreatWatchAlertsDashboard implements Action {
    public readonly type = AnalyticsActionTypes.ResetFetchedThreatWatchAlerts;
}
export class ResetFetchedThreatWatchAlertsDashboardSuccess implements Action {
    public readonly type = AnalyticsActionTypes.ResetFetchedThreatWatchAlertsSuccess;
}

export class ResetFetchedThreatWatchAlerts implements Action {
    public readonly type = AnalyticsActionTypes.ResetFetchedThreatWatchAlerts;
}
export class ResetFetchedThreatWatchAlertsSuccess implements Action {
    public readonly type = AnalyticsActionTypes.ResetFetchedThreatWatchAlertsSuccess;
}
export class SetGridState implements Action {
    public readonly type = AnalyticsActionTypes.SetGridState;
    constructor(readonly payload: GridState) {}
}

export class ResetState implements Action {
    public readonly type = AnalyticsActionTypes.ResetState;
}

export type AnalyticsActions = PreloadConcierge
    | SetDateRange | SetDrilldownDateRange | ResetDrilldownDateRange
    | AddFilters | RemoveFilter | OverwriteFilters
    | FetchCount | FetchCountSuccess | FetchCountFailure 
    | FetchCounts | FetchCountsSuccess | FetchCountsFailure
    | SetChartGroupingOption | FetchChart | FetchChartSuccess | FetchChartFailure
    | FetchThreatWatchAlerts | FetchThreatWatchAlertsSuccess | FetchThreatWatchAlertsFailure
    | FetchThreatWatchAlertsDashboard | FetchThreatWatchAlertsDashboardSuccess | FetchThreatWatchAlertsDashboardFailure
    | ResetFetchedThreatWatchAlerts | ResetFetchedThreatWatchAlertsSuccess | SetGridState
    | ResetState;
