import { Action } from "@ngrx/store";
import { LocalizedAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, EntityProperty, GridState } from "../../_models";

export enum LocalizedAlertsActionTypes {
    PreloadConcierge = '[Localized Alerts] Preload Concierge',

    SetDateRange = '[Localized Alerts] Set Date Range',
    SetDrilldownDateRange = '[Localized Alerts] Set Drilldown Date Range',
    ResetDrilldownDateRange = '[Localized Alerts] Reset Drilldown Date Range',

    SetSummaryDateRange = '[Localized Alerts] Set Summary Date Range',
    SetSummaryDrilldownDateRange = '[Localized Alerts] Set Summary Drilldown Date Range',
    ResetSummaryDrilldownDateRange = '[Localized Alerts] Reset Summary Drilldown Date Range',    
    
    AddFilters = '[Localized Alerts] Add Filters',
    RemoveFilter = '[Localized Alerts] Remove Filter',
    OverwriteFilters = '[Localized Alerts] Overwrite Filters',
    AddSummaryFilters = '[Localized Alerts] Add Summary Filters',
    RemoveSummaryFilter = '[Localized Alerts] Remove Summary Filter',
    OverwriteSummaryFilters = '[Localized Alerts] Overwrite Summary Filters',

    FetchCount = '[Localized Alerts] Fetch Count',
    FetchCountSuccess = '[Localized Alerts] Fetch Count SUCCESS',
    FetchCountFailure = '[Localized Alerts] Fetch Count FAILURE',
    FetchSummaryCount = '[Localized Alerts] Fetch Summary Count',
    FetchSummaryCountSuccess = '[Localized Alerts] Fetch Summary Count SUCCESS',
    FetchSummaryCountFailure = '[Localized Alerts] Fetch Summary Count FAILURE',
    
    SetChartGroupingOption = '[Localized Alerts] Set Chart Grouping Option',
    FetchChart = '[Localized Alerts] Fetch Chart',
    FetchChartSuccess = '[Localized Alerts] Fetch Chart SUCCESS',
    FetchChartFailure = '[Localized Alerts] Fetch Chart FAILURES',

    SetSummaryEntities = '[Localized Alerts] Set Summary Entities',
    FetchSummary = '[Localized Alerts] Fetch Summary',
    FetchSummarySuccess = '[Localized Alerts] Fetch Summary SUCCESS',
    FetchSummaryFailure = '[Localized Alerts] Fetch Summary FAILURE',
    SetSummaryGridState = '[Localized Alerts] Set Summary Grid State',

    FetchLocalizedAlerts = '[Localized Alerts] Fetch Localized Alerts',
    FetchLocalizedAlertsSuccess = '[Localized Alerts] Fetch Localized Alerts SUCCESS',
    FetchLocalizedAlertsFailure = '[Localized Alerts] Fetch Localized Alerts FAILURE',
    ResetFetchedLocalizedAlerts = '[Localized Alerts] Clear Fetched Localized Alerts',
    ResetFetchedLocalizedAlertsSuccess = '[Localized Alerts] Clear Fetched Localized Alerts SUCCESS',
    FetchSummaryLocalizedAlerts = '[Localized Alerts] Fetch SummaryLocalized Alerts',
    FetchSummaryLocalizedAlertsSuccess = '[Localized Alerts] Fetch SummaryLocalized Alerts SUCCESS',
    FetchSummaryLocalizedAlertsFailure = '[Localized Alerts] Fetch SummaryLocalized Alerts FAILURE',
    ResetFetchedSummaryLocalizedAlerts = '[Localized Alerts] Clear Fetched SummaryLocalized Alerts',
    ResetFetchedSummaryLocalizedAlertsSuccess = '[Localized Alerts] Clear Fetched SummaryLocalized Alerts SUCCESS',
    SetGridState = '[Localized Alerts] Set Grid State',

    ResetState = '[Localized Alerts] Clear State',
    ResetSummaryState = '[Localized Alerts] Clear Summary State'
};

export class PreloadConcierge implements Action {
    public readonly type = LocalizedAlertsActionTypes.PreloadConcierge;
    constructor(
        readonly payload: { 
            dateRange: DateRange, 
            dateRangeOption: DateRangeOption,
            drilldownDateRange?: DateRange,
            filterConditions: ConfiguredFilter[],
            chartGroupingOption?: ChartGroupingOption
        },
        readonly sliceKey?: string
    ) {}
}

export class SetDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetDateRange;
    constructor(
        readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption},
        readonly sliceKey?: string
    ) {}
}
export class SetDrilldownDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetDrilldownDateRange;
    constructor(readonly payload: DateRange, readonly sliceKey?: string) {}
}
export class ResetDrilldownDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetDrilldownDateRange;
    constructor(readonly sliceKey?: string) {}
}

export class SetSummaryDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetSummaryDateRange;
    constructor(
        readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption},
        readonly sliceKey?: string
    ) {}
}
export class SetSummaryDrilldownDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetSummaryDrilldownDateRange;
    constructor(readonly payload: DateRange, readonly sliceKey?: string) {}
}
export class ResetSummaryDrilldownDateRange implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetSummaryDrilldownDateRange;
    constructor(readonly sliceKey?: string) {}
}

export class AddFilters implements Action {
    public readonly type = LocalizedAlertsActionTypes.AddFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}
export class RemoveFilter implements Action {
    public readonly type = LocalizedAlertsActionTypes.RemoveFilter;
    constructor(readonly payload: ConfiguredFilter, readonly sliceKey?: string) {}
}
export class OverwriteFilters implements Action {
    public readonly type = LocalizedAlertsActionTypes.OverwriteFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}
export class AddSummaryFilters implements Action {
    public readonly type = LocalizedAlertsActionTypes.AddSummaryFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}
export class RemoveSummaryFilter implements Action {
    public readonly type = LocalizedAlertsActionTypes.RemoveSummaryFilter;
    constructor(readonly payload: ConfiguredFilter, readonly sliceKey?: string) {}
}
export class OverwriteSummaryFilters implements Action {
    public readonly type = LocalizedAlertsActionTypes.OverwriteSummaryFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}

export class FetchCount implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchCount;
    constructor(readonly sliceKey?: string) {}
}
export class FetchCountSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchCountSuccess;
    constructor(readonly payload: number, readonly sliceKey?: string) {}
}
export class FetchCountFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchCountFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class FetchSummaryCount implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryCount;
    constructor(readonly sliceKey?: string) {}
}
export class FetchSummaryCountSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryCountSuccess;
    constructor(readonly payload: number, readonly sliceKey?: string) {}
}
export class FetchSummaryCountFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryCountFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}

export class SetChartGroupingOption implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption, readonly sliceKey?: string) {}
}
export class FetchChart implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchChart;
    constructor(readonly sliceKey?: string) {}
}
export class FetchChartSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchChartSuccess;
    constructor(readonly payload: any[], readonly sliceKey?: string) {}
}
export class FetchChartFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchChartFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}

export class SetSummaryEntities implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetSummaryEntities;
    constructor(readonly payload: EntityProperty[], readonly sliceKey?: string) {}
}
export class FetchSummary implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummary;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchSummarySuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummarySuccess;
    constructor(readonly payload: any[], readonly sliceKey?: string) {}
}
export class FetchSummaryFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class SetSummaryGridState implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetSummaryGridState;
    constructor(readonly payload: GridState, readonly sliceKey?: string) {}
}

export class FetchLocalizedAlerts implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchLocalizedAlerts;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchLocalizedAlertsSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchLocalizedAlertsSuccess;
    constructor(readonly payload: LocalizedAlert[], readonly sliceKey?: string) {}
}
export class FetchLocalizedAlertsFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchLocalizedAlertsFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class ResetFetchedLocalizedAlerts implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetFetchedLocalizedAlerts;
    constructor(readonly sliceKey?: string) {}
}
export class ResetFetchedLocalizedAlertsSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetFetchedLocalizedAlertsSuccess;
    constructor(readonly sliceKey?: string) {}
}
export class FetchSummaryLocalizedAlerts implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryLocalizedAlerts;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchSummaryLocalizedAlertsSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryLocalizedAlertsSuccess;
    constructor(readonly payload: LocalizedAlert[], readonly sliceKey?: string) {}
}
export class FetchSummaryLocalizedAlertsFailure implements Action {
    public readonly type = LocalizedAlertsActionTypes.FetchSummaryLocalizedAlertsFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class ResetFetchedSummaryLocalizedAlerts implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetFetchedSummaryLocalizedAlerts;
    constructor(readonly sliceKey?: string) {}
}
export class ResetFetchedSummaryLocalizedAlertsSuccess implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetFetchedSummaryLocalizedAlertsSuccess;
    constructor(readonly sliceKey?: string) {}
}
export class SetGridState implements Action {
    public readonly type = LocalizedAlertsActionTypes.SetGridState;
    constructor(readonly payload: GridState, readonly sliceKey?: string) {}
}

export class ResetState implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetState;
    constructor(readonly sliceKey?: string) {}
}
export class ResetSummaryState implements Action {
    public readonly type = LocalizedAlertsActionTypes.ResetSummaryState;
    constructor(readonly sliceKey?: string) {}
}

export type LocalizedAlertsActions = PreloadConcierge
    | SetDateRange | SetDrilldownDateRange | ResetDrilldownDateRange 
    | SetSummaryDateRange | SetSummaryDrilldownDateRange | ResetSummaryDrilldownDateRange 
    | AddFilters | RemoveFilter | OverwriteFilters
    | AddSummaryFilters | RemoveSummaryFilter | OverwriteSummaryFilters
    | FetchCount | FetchCountSuccess | FetchCountFailure
    | FetchSummaryCount | FetchSummaryCountSuccess | FetchSummaryCountFailure
    | SetChartGroupingOption | FetchChart | FetchChartSuccess | FetchChartFailure
    | SetSummaryEntities | FetchSummary | FetchSummarySuccess | FetchSummaryFailure | SetSummaryGridState
    | FetchLocalizedAlerts | FetchLocalizedAlertsSuccess | FetchLocalizedAlertsFailure
    | ResetFetchedLocalizedAlerts | ResetFetchedLocalizedAlertsSuccess | SetGridState
    | FetchSummaryLocalizedAlerts | FetchSummaryLocalizedAlertsSuccess | FetchSummaryLocalizedAlertsFailure
    | ResetFetchedSummaryLocalizedAlerts | ResetFetchedSummaryLocalizedAlertsSuccess
    | ResetState | ResetSummaryState;
