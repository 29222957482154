import { Entity } from './entity.model';
import { EntityProperty } from './entity-property';

export class LocalizedAlert extends Entity {

    get id(): number {
        return this.data && this.data[LocalizedAlert._id.fieldName];
    }

    static get _id(): EntityProperty {
        return { fieldName: 'id', columnName: 'GlobalAlertID', filterType: 'number', displayName: 'Security Event ID' };
    }

    get alertType(): string {
        return this.data && this.data[LocalizedAlert._alertType.fieldName];
    }

    static get _alertType(): EntityProperty {
      return { fieldName: 'typ', columnName: 'AlertType', filterType: 'string', displayName: 'Security Event Type', placeholder: 'i.e. Asset Anomaly', fieldLength: 255 };
    }

    get firstSeen(): string {
        return this.data && this.data[LocalizedAlert._firstSeen.fieldName];
    }

    static get _firstSeen(): EntityProperty {
        return { fieldName: 'fst', columnName: 'FirstEventSeen', filterType: 'string', displayName: 'First Seen {0}' };
    }

    get lastSeen(): string {
        return this.data && this.data[LocalizedAlert._lastSeen.fieldName];
    }

    static get _lastSeen(): EntityProperty {
        return { fieldName: 'lst', columnName: 'LastEventSeen', filterType: 'string', displayName: 'Last Seen {0}' };
    }

    get subjectUser(): string {
        return this.data && this.data[LocalizedAlert._subjectUser.fieldName];
    }

    static get _subjectUser(): EntityProperty {
        return { fieldName: 'sur', columnName: 'UserName', filterType: 'string', displayName: 'Subject User', placeholder: 'i.e.john.smith', fieldLength: 64 };
    }

    get targetUser(): string {
        return this.data && this.data[LocalizedAlert._targetUser.fieldName];
    }

    static get _targetUser(): EntityProperty {
        return { fieldName: 'tur', columnName: 'TargetUser', filterType: 'string', displayName: 'Target User' , placeholder: 'i.e.john.smith', fieldLength: 64 };
    }

    get sourceIP(): string {
        return this.data && this.data[LocalizedAlert._sourceIP.fieldName];
    }

    static get _sourceIP(): EntityProperty {
        return { fieldName: 'sip', columnName: 'srcipint', filterType: 'ipaddress', displayName: 'Source IP', placeholder: 'i.e. 255.255.255.255' };
    }

    get destinationIP(): string {
        return this.data && this.data[LocalizedAlert._destinationIP.fieldName];
    }

    static get _destinationIP(): EntityProperty {
        return { fieldName: 'dip', columnName: 'dstipint', filterType: 'ipaddress', displayName: 'Destination IP',  placeholder: 'i.e. 255.255.255.255' };
    }

    get sourcePort(): number {
        return this.data && this.data[LocalizedAlert._sourcePort.fieldName];
    }

    static get _sourcePort(): EntityProperty {
        return { fieldName: 'spt', columnName: 'srcport', filterType: 'number', displayName: 'Source Port', placeholder: 'i.e. 80' };
    }

    get destinationPort(): number {
        return this.data && this.data[LocalizedAlert._destinationPort.fieldName];
    }

    static get _destinationPort(): EntityProperty {
        return { fieldName: 'dpt', columnName: 'dstport', filterType: 'number', displayName: 'Destination Port', placeholder: 'i.e. 80' };
    }

    get dhcp(): number {
        return this.data && this.data[LocalizedAlert._dhcp.fieldName];
    }

    static get _dhcp(): EntityProperty {
        return { fieldName: 'dhcp', columnName: 'isDHCP', filterType: 'number', displayName: 'DHCP' };
    }

    get sourceCountry(): string {
        return this.data && this.data[LocalizedAlert._sourceCountry.fieldName];
    }

    static get _sourceCountry(): EntityProperty {
        return { fieldName: 'sco', columnName: 'srcCountrycd', filterType: 'country', displayName: 'Source Country', placeholder: 'i.e. United States' };
    }

    get destinationCountry(): string {
        return this.data && this.data[LocalizedAlert._destinationCountry.fieldName];
    }

    static get _destinationCountry(): EntityProperty {
        return { fieldName: 'dco', columnName: 'dstCountrycd', filterType: 'country', displayName: 'Destination Country', placeholder: 'i.e. United States' };
    }

    get direction(): string {
        return this.data && this.data[LocalizedAlert._direction.fieldName];
    }

    static get _direction(): EntityProperty {
        return { fieldName: 'dir', columnName: 'Direction', filterType: 'direction', displayName: 'Direction', placeholder: 'i.e. Internal To External' };
    }

    get alertTime(): string {
        return this.data && this.data[LocalizedAlert._alertTime.fieldName];
    }

    static get _alertTime(): EntityProperty {
        return { fieldName: 'alt', columnName: 'AlertTime', filterType: 'string', displayName: 'Analytics Time' };
    }

    get description(): string {
        return this.data && this.data[LocalizedAlert._description.fieldName];
    }

    static get _description(): EntityProperty {
        return { fieldName: 'des', columnName: 'Description', filterType: 'string', displayName: 'Description' };
    }

    get signature(): string {
        return this.data && this.data[LocalizedAlert._signature.fieldName];
    }

    static get _signature(): EntityProperty {
        return { fieldName: 'sgn', columnName: 'Signature', filterType: 'string', displayName: 'Signature', placeholder: 'i.e. Adware', fieldLength: 64 };
    }

    get severity(): string {
        return this.data && this.data[LocalizedAlert._severity.fieldName];
    }

    static get _severity(): EntityProperty {
        return { fieldName: 'sev', columnName: 'Severity', filterType: 'severity', displayName: 'Severity', placeholder: 'i.e. Critical' };
    }

    get status(): string {
        return this.data && this.data[LocalizedAlert._status.fieldName];
    }

    static get _status(): EntityProperty {
        return { fieldName: 'sta', columnName: 'Status', filterType: 'string', displayName: 'Status' };
    }

    get deviceType(): string {
        return this.data && this.data[LocalizedAlert._deviceType.fieldName];
    }

    static get _deviceType(): EntityProperty {
        return { fieldName: 'dvt', columnName: 'DeviceType', filterType: 'DeviceTypeName', displayName: 'Device Type', placeholder: 'i.e. Firewall', fieldLength: 32 };
    }

    get deviceVendor(): string {
        return this.data && this.data[LocalizedAlert._deviceVendor.fieldName];
    }

    static get _deviceVendor(): EntityProperty {
        return { fieldName: 'dvv', columnName: 'DeviceVendor', filterType: 'string', displayName: 'Device Vendor', placeholder: 'i.e. Cisco', fieldLength: 32 };
    }

    get reportingDevice(): string {
        return this.data && this.data[LocalizedAlert._reportingDevice.fieldName];
    }

    static get _reportingDevice(): EntityProperty {
        return { fieldName: 'rpd', columnName: 'ReportingDevice', filterType: 'string', displayName: 'Reporting Device', placeholder: 'i.e. IP/Host Name', fieldLength: 32 };
    }

    get domain(): string {
        return this.data && this.data[LocalizedAlert._domain.fieldName];
    }

    static get _domain(): EntityProperty {
      return { fieldName: 'dmn', columnName: 'Domain', filterType: 'string', displayName: 'Subject Domain', fieldLength: 64 };
    }

    get targetDomain(): string {
        return this.data && this.data[LocalizedAlert._targetDomain.fieldName];
    }

    static get _targetDomain(): EntityProperty {
      return { fieldName: 'tdm', columnName: 'TargetDomain', filterType: 'string', displayName: 'Target Domain', fieldLength: 64 };
    }

    get ipRepSource(): string {
        return this.data && this.data[LocalizedAlert._ipRepSource.fieldName];
    }

    static get _ipRepSource(): EntityProperty {
        return { fieldName: 'irs', columnName: 'IPRepSource', filterType: 'string', displayName: 'Threat Source' };
    }

    get eventName(): string {
        return this.data && this.data[LocalizedAlert._eventName.fieldName];
    }

    static get _eventName(): EntityProperty {
      return { fieldName: 'evtn', columnName: 'EventName', filterType: 'string', displayName: 'Event Name', fieldLength: 64 };
    }

    get count(): string {
        return this.data && this.data[LocalizedAlert._count.fieldName];
    }

    static get _count(): EntityProperty {
        return { fieldName: 'cnt', columnName: 'Count', filterType: 'number', displayName: 'Count' };
    }

    get protocol(): string {
        return this.data && this.data[LocalizedAlert._protocol.fieldName];
    }

    static get _protocol(): EntityProperty {
      return { fieldName: 'ptcl', columnName: 'Protocol', filterType: 'string', displayName: 'Protocol', fieldLength: 10 };
    }

    get Disposition(): string {
        return this.data && this.data[LocalizedAlert._disposition.fieldName];
    }

    static get _disposition(): EntityProperty {
        return { fieldName: 'dis', columnName: 'Disposition', filterType: 'disposition', displayName: 'Allow/Deny', placeholder: 'i.e. Allow' };
    }

    get event(): string {
        return this.data && this.data[LocalizedAlert._event.fieldName];
    }

    static get _event(): EntityProperty {
      return { fieldName: 'evt', columnName: 'Event', filterType: 'string', displayName: 'Threat Event', fieldLength: 64 };
    }

    get sourceName(): string {
        return this.data && this.data[LocalizedAlert._sourceName.fieldName];
    }

    static get _sourceName(): EntityProperty {
      return { fieldName: 'sn', columnName: 'srcName', filterType: 'string', displayName: 'Target Asset', fieldLength: 64 };
    }

    get hostName(): string {
        return this.data && this.data[LocalizedAlert._hostName.fieldName];
    }

    static get _hostName(): EntityProperty {
      return { fieldName: 'hstn', columnName: 'HostName', filterType: 'string', displayName: 'Reporting Host Name', fieldLength: 64 };
    }

    get url(): string {
        return this.data && this.data[LocalizedAlert._url.fieldName];
    }

    static get _url(): EntityProperty {
      return { fieldName: 'url', columnName: 'Url', filterType: 'string', displayName: 'URL', fieldLength: 128 };
    }

    get clientID(): string {
      return this.data && this.data[LocalizedAlert._clientID.fieldName];
    }

    static get _clientID(): EntityProperty {
      return { fieldName: 'cid', columnName: 'ClientID', filterType: 'string', displayName: 'Client', fieldLength: 15 };
    }

    get totalEventsCount(): string {
        return this.data && this.data[LocalizedAlert._totalEventsCount.fieldName];
    }

    static get _totalEventsCount(): EntityProperty {
        return { fieldName: 'adct', columnName: 'TotalEventsCount', filterType: 'number', displayName: 'Total Events Count' };
    }

    get rateOfChange(): string {
        return this.data && this.data[LocalizedAlert._rateOfChange.fieldName];
    }

    static get _rateOfChange(): EntityProperty {
        return { fieldName: 'adr', columnName: 'RateOfChange', filterType: 'number', displayName: 'Rate Of Change' };
    }

    get commonality(): string {
        return this.data && this.data[LocalizedAlert._commonality.fieldName];
    }

    static get _commonality(): EntityProperty {
        return { fieldName: 'adcm', columnName: 'Commonality', filterType: 'string', displayName: 'Commonality' };
    }

  get alertRating(): number {
    return this.data && this.data[LocalizedAlert._alertRating.fieldName];
  }

  static get _alertRating(): EntityProperty {
    return { fieldName: 'ar', columnName: 'AlertRating', filterType: 'number', displayName: 'Rating' };
  }

  get correlatedAlertID(): string {
    return this.data && this.data[LocalizedAlert._correlatedAlertID.fieldName];
  }

  static get _correlatedAlertID(): EntityProperty {
    return { fieldName: 'caid', columnName: 'CorrelationAlertID', filterType: 'string', displayName: 'Correlatated ID' };
  }

  get caseId(): string {
    return this.data && this.data[LocalizedAlert._caseId.fieldName];
  }

  static get _caseId(): EntityProperty {
    return { fieldName: 'csid', columnName: 'CaseId', filterType: 'string', displayName: 'Case Number' };
  }

  get caseSysId(): string {
    return this.data && this.data[LocalizedAlert._caseSysId.fieldName];
  }

  static get _caseSysId(): EntityProperty {
    return { fieldName: 'csyid', columnName: 'CaseSysId', filterType: 'string', displayName: 'Case SysId' };
  }

  get tags(): string {
    return this.data && this.data[LocalizedAlert._tags.fieldName];
  }

  static get _tags(): EntityProperty {
      return { fieldName: 'tags', columnName: 'Tags', filterType: 'string', displayName: 'Tags' };
  }

    // format to fix ag-Grid grouping issues with hacky ad alerts
    static getFormattedBlob(blob: any) {
        if (!blob) {
            return null;
        }

        const subjectUserKey = LocalizedAlert._subjectUser.fieldName;
        const targetUserKey = LocalizedAlert._targetUser.fieldName;
        const sourceIpKey = LocalizedAlert._sourceIP.fieldName;
        const destinationIpKey = LocalizedAlert._destinationIP.fieldName;
        const sourceCountryKey = LocalizedAlert._sourceCountry.fieldName;
        const destinationCountryKey = LocalizedAlert._destinationCountry.fieldName;
        const sourcePortKey = LocalizedAlert._sourcePort.fieldName;
        const destinationPortKey = LocalizedAlert._destinationPort.fieldName;
        const protocolKey = LocalizedAlert._protocol.fieldName;
        const threatEventKey = LocalizedAlert._event.fieldName;

        blob[subjectUserKey] = this.getFormattedMultiValue(blob[subjectUserKey]);
        blob[targetUserKey] = this.getFormattedMultiValue(blob[targetUserKey]);
        blob[sourceIpKey] = this.getFormattedMultiValue(blob[sourceIpKey]);
        blob[destinationIpKey] = this.getFormattedMultiValue(blob[destinationIpKey]);
        blob[sourceCountryKey] = this.getFormattedMultiValue(blob[sourceCountryKey], false, 'UNK');
        blob[destinationCountryKey] = this.getFormattedMultiValue(blob[destinationCountryKey], false, 'UNK');
        blob[sourcePortKey] = blob[sourcePortKey] == -1 ? '' : this.getFormattedMultiValue(blob[sourcePortKey]);
        blob[destinationPortKey] = blob[destinationPortKey] == -1 ? '' : this.getFormattedMultiValue(blob[destinationPortKey]);
        blob[protocolKey] = this.getFormattedMultiValue(blob[protocolKey]);
        blob[threatEventKey] = this.getFormattedMultiValue(blob[threatEventKey]);

        return blob;
    }

    private static getFormattedMultiValue(value: any, allowMulti = true, defaultValue: any = ''): string {
        if (value) {
            var stringValue = value.toString();
            if (value == '*' || (stringValue.includes('*') && stringValue.split(',').length == 2)) {
              return defaultValue;
            } else if (!isNaN(value) && value < 0) {
              return allowMulti ? 'Multi' : defaultValue;
            } else if (stringValue.includes(',')) {
              return allowMulti ? 'Multi' : defaultValue;
            }
        }

        return value || defaultValue;
    }
}
