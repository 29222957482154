import { Injectable, Inject } from "@angular/core";
import { Title, DomSanitizer } from "@angular/platform-browser";
import { DOCUMENT } from "@angular/common";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BrandService {
    private imagePath = new BehaviorSubject<string>('assets/img/sod_logo_horizontal.svg');
    private imageStyles = new BehaviorSubject<string>('');

    imagePath$ = this.imagePath.asObservable();
    imageStyles$ = this.imageStyles.asObservable();

    logoImage: any;

    constructor(
        private titleService: Title,
        private sanitizer: DomSanitizer,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    setLogoImage(image: Blob) {
        if (!image || !image.size) {
            return;
        }

        this.logoImage = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(image));
        this.imagePath.next(this.logoImage);
    }

    setTitle(title: string) {
        if (!title || !title.length || title === 'ThreatWatch') {
            return;
        }

        this.titleService.setTitle(title);
        this.document.body.classList.add('light');
    }
}
