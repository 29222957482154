export class keyValueState {

  constructor(options?: { key: string; value: any; selected: boolean }) {
      if (options) {
          this.key = options.key;
          this.value = options.value;
          this.selected = options.selected;
      }
  }

  key: string;
  value: any;
  selected: boolean;
}