import { Action } from "@ngrx/store";

export enum CoreActionTypes {
    RouteNavigation = '[Core] Route Navigation'
};

export class RouteNavigation implements Action {
    public readonly type = CoreActionTypes.RouteNavigation;
}

export type CoreActions = RouteNavigation;
