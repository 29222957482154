import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { AuthService } from './_services';
import { environment } from '../environments/environment';
import { ThemeService } from './+home/_services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  
  constructor(
    public router: Router, 
    public authService: AuthService,
    public themeService: ThemeService,
    private renderer: Renderer2
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        
      }
    });
  }

  ngOnInit() {
    this.uptrendsMonitoring();
  }

  private uptrendsMonitoring() {
    switch(environment.type) {
      case 'staging':
        window['_urconfig'] = { sid: "6a74a78c-d8e9-41d2-9c5a-e78b4fa4e125", aip: 0, usePageProtocol: false };
        break;
      case 'production':
        window['_urconfig'] = { sid: "74d6225e-04e8-473c-8b50-0660c7a02568", aip: 0, usePageProtocol: false };
        break;
    }

    if (window['_urconfig']) {
      const rumScript = document.createElement('script');
      rumScript.type = 'text/javascript';
      rumScript.innerHTML = '!function(e,t){var n=e.createElement(t),a=e.getElementsByTagName(t)[0];n.src="https://hit.uptrendsdata.com/rum.min.js",n.async="async",a.parentNode.insertBefore(n,a)}(document,"script");';

      document.querySelector('head').appendChild(rumScript);
    }
  }
}
