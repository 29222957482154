import { LocalizedAlertsActions, LocalizedAlertsActionTypes, SetChartGroupingOption } from "../actions/localized-alerts.actions";
import { initialLocalizedAlertsState, LocalizedAlertsState } from "../state/localized-alerts.state";
import { StoredConciergeService } from "../../_services/stored-concierge/stored-concierge.service";

export function localizedAlertsReducers(
    state: LocalizedAlertsState = initialLocalizedAlertsState,
    action: LocalizedAlertsActions
) {
    const currentSliceKey = location.pathname;
    switch (action.type) { 
        case LocalizedAlertsActionTypes.PreloadConcierge:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                },
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.drilldownDateRange,
                },
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload.filterConditions,
                },
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.chartGroupingOption,
                }
            };

        case LocalizedAlertsActionTypes.SetDateRange:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                }
            };
        case LocalizedAlertsActionTypes.SetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload,
                }
            };
        case LocalizedAlertsActionTypes.ResetDrilldownDateRange:
            return {
                ...state,
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.drilldownDateRange[action.sliceKey ?? currentSliceKey]
                }
            };
        case LocalizedAlertsActionTypes.SetSummaryDateRange:
            return {
                ...state,
                summaryDateRange: {
                    ...state.summaryDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRange,
                },
                summaryDateRangeOption: {
                    ...state.summaryDateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload.dateRangeOption,
                }
            };
        case LocalizedAlertsActionTypes.SetSummaryDrilldownDateRange:
            return {
                ...state,
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: action.payload,
                }
            };
        case LocalizedAlertsActionTypes.ResetSummaryDrilldownDateRange:
            return {
                ...state,
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryDrilldownDateRange[action.sliceKey ?? currentSliceKey],
                }
            };   

        case LocalizedAlertsActionTypes.AddFilters:
            const filters = state.filterConditions[action.sliceKey ?? currentSliceKey].concat(action.payload);
            return {
                ...state,
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: StoredConciergeService.getDistinctConfiguredFilters(filters),
                }
        };
        case LocalizedAlertsActionTypes.RemoveFilter:
            return {
                ...state,
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: state.filterConditions[action.sliceKey ?? currentSliceKey].filter(c => c !== action.payload)
                }
            };
        case LocalizedAlertsActionTypes.OverwriteFilters:
            return {
                ...state,
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };
        case LocalizedAlertsActionTypes.AddSummaryFilters:
            const summaryFilters = state.summaryFilterConditions[action.sliceKey ?? currentSliceKey].concat(action.payload);
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: StoredConciergeService.getDistinctConfiguredFilters(summaryFilters),
                }
            };
        case LocalizedAlertsActionTypes.RemoveSummaryFilter:
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: state.summaryFilterConditions[action.sliceKey ?? currentSliceKey].filter(c => c !== action.payload)
                }
            };
        case LocalizedAlertsActionTypes.OverwriteSummaryFilters:
            return {
                ...state,
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };

        case LocalizedAlertsActionTypes.FetchCount:
            return {
                ...state,
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchCountSuccess:
            return { 
                ...state, 
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                },
            };
        case LocalizedAlertsActionTypes.FetchCountFailure:
            return {
                ...state,
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryCount:
            return {
                ...state,
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryCountSuccess:
            return { 
                ...state, 
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                },
            };
        case LocalizedAlertsActionTypes.FetchSummaryCountFailure:
            return {
                ...state,
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: 0
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };

        case LocalizedAlertsActionTypes.SetChartGroupingOption:
            return {
                ...state,
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                } 
            };
        case LocalizedAlertsActionTypes.FetchChart:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.chartData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };
        case LocalizedAlertsActionTypes.FetchChartSuccess:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                } 
            };
        case LocalizedAlertsActionTypes.FetchChartFailure:
            return {
                ...state,
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                } 
            };

        case LocalizedAlertsActionTypes.SetSummaryEntities:
            return {
                ...state,
                summaryEntities: {
                    ...state.summaryEntities,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };
        case LocalizedAlertsActionTypes.FetchSummary:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchSummarySuccess:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryFailure:
            return {
                ...state,
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };    
        case LocalizedAlertsActionTypes.SetSummaryGridState:
            return {
                ...state,
                summaryGridState: {
                    ...state.summaryGridState,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                } 
            };

        case LocalizedAlertsActionTypes.FetchLocalizedAlerts:
            return { 
                ...state, 
                isLoadingLocalizedAlerts: {
                    ...state.isLoadingLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isLocalizedAlertsLoaded: {
                    ...state.isLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchLocalizedAlertsSuccess:
            return { 
                ...state, 
                localizedAlerts: {
                    ...state.localizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingLocalizedAlerts: {
                    ...state.isLoadingLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLocalizedAlertsLoaded: {
                    ...state.isLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                }
            };
        case LocalizedAlertsActionTypes.FetchLocalizedAlertsFailure:
            return { 
                ...state, 
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                localizedAlerts: {
                    ...state.localizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingLocalizedAlerts: {
                    ...state.isLoadingLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLocalizedAlertsLoaded: {
                    ...state.isLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.ResetFetchedLocalizedAlertsSuccess:
            return {
                ...state,
                localizedAlerts: {
                    ...state.localizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.localizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isLoadingLocalizedAlerts: {
                    ...state.isLoadingLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isLocalizedAlertsLoaded: {
                    ...state.isLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryLocalizedAlerts:
            return { 
                ...state, 
                isLoadingSummaryLocalizedAlerts: {
                    ...state.isLoadingSummaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: true
                },
                isSummaryLocalizedAlertsLoaded: {
                    ...state.isSummaryLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryLocalizedAlertsSuccess:
            return { 
                ...state, 
                summaryLocalizedAlerts: {
                    ...state.summaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                isLoadingSummaryLocalizedAlerts: {
                    ...state.isLoadingSummaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLocalizedAlertsLoaded: {
                    ...state.isSummaryLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: true
                }
            };
        case LocalizedAlertsActionTypes.FetchSummaryLocalizedAlertsFailure:
            return { 
                ...state, 
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                },
                summaryLocalizedAlerts: {
                    ...state.summaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: null
                },
                isLoadingSummaryLocalizedAlerts: {
                    ...state.isLoadingSummaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLocalizedAlertsLoaded: {
                    ...state.isSummaryLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.ResetFetchedSummaryLocalizedAlertsSuccess:
            return {
                ...state,
                summaryLocalizedAlerts: {
                    ...state.summaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryLocalizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryLocalizedAlerts: {
                    ...state.isLoadingSummaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: false
                },
                isSummaryLocalizedAlertsLoaded: {
                    ...state.isSummaryLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: false
                }
            };
        case LocalizedAlertsActionTypes.SetGridState:
            return {
                ...state,
                gridState: {
                    ...state.gridState,
                    [action.sliceKey ?? currentSliceKey]: action.payload
                }
            };

        case LocalizedAlertsActionTypes.ResetState:
            return {
                ...state,
                dateRange: {
                    ...state.dateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.dateRange[action.sliceKey ?? currentSliceKey]
                },
                dateRangeOption: {
                    ...state.dateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.dateRangeOption[action.sliceKey ?? currentSliceKey]
                },
                drilldownDateRange: {
                    ...state.drilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.drilldownDateRange[action.sliceKey ?? currentSliceKey]
                },
                filterConditions: {
                    ...state.filterConditions,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.filterConditions[action.sliceKey ?? currentSliceKey]
                },
                count: {
                    ...state.count,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.count[action.sliceKey ?? currentSliceKey]
                },
                isLoadingCount: {
                    ...state.isLoadingCount,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingCount[action.sliceKey ?? currentSliceKey]
                },
                isCountLoaded: {
                    ...state.isCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isCountLoaded[action.sliceKey ?? currentSliceKey]
                },
                chartGroupingOption: {
                    ...state.chartGroupingOption,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.chartGroupingOption[action.sliceKey ?? currentSliceKey]
                },
                chartData: {
                    ...state.chartData,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.chartData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingChart: {
                    ...state.isLoadingChart,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingChart[action.sliceKey ?? currentSliceKey]
                },
                isChartLoaded: {
                    ...state.isChartLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isChartLoaded[action.sliceKey ?? currentSliceKey]
                },
                localizedAlerts: {
                    ...state.localizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.localizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isLoadingLocalizedAlerts: {
                    ...state.isLoadingLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingLocalizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isLocalizedAlertsLoaded: {
                    ...state.isLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLocalizedAlertsLoaded[action.sliceKey ?? currentSliceKey]
                },
                gridState: {
                    ...state.gridState,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.gridState[action.sliceKey ?? currentSliceKey]
                },
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.error[action.sliceKey ?? currentSliceKey]
                }
            };
        case LocalizedAlertsActionTypes.ResetSummaryState:
            return {
                ...state,
                summaryDateRange: {
                    ...state.summaryDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryDateRange[action.sliceKey ?? currentSliceKey]
                },
                summaryDateRangeOption: {
                    ...state.summaryDateRangeOption,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryDateRangeOption[action.sliceKey ?? currentSliceKey]
                },
                summaryDrilldownDateRange: {
                    ...state.summaryDrilldownDateRange,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryDrilldownDateRange[action.sliceKey ?? currentSliceKey]
                },
                summaryFilterConditions: {
                    ...state.summaryFilterConditions,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryFilterConditions[action.sliceKey ?? currentSliceKey]
                },
                summaryCount: {
                    ...state.summaryCount,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryCount[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryCount: {
                    ...state.isLoadingSummaryCount,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingSummaryCount[action.sliceKey ?? currentSliceKey]
                },
                isSummaryCountLoaded: {
                    ...state.isSummaryCountLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isSummaryCountLoaded[action.sliceKey ?? currentSliceKey]
                },
                summaryEntities: {
                    ...state.summaryEntities,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryEntities[action.sliceKey ?? currentSliceKey]
                },
                summaryData: {
                    ...state.summaryData,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryData[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummary: {
                    ...state.isLoadingSummary,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingSummary[action.sliceKey ?? currentSliceKey]
                },
                isSummaryLoaded: {
                    ...state.isSummaryLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isSummaryLoaded[action.sliceKey ?? currentSliceKey]
                },
                summaryGridState: {
                    ...state.summaryGridState,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryGridState[action.sliceKey ?? currentSliceKey]
                },
                summaryLocalizedAlerts: {
                    ...state.summaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.summaryLocalizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isLoadingSummaryLocalizedAlerts: {
                    ...state.isLoadingSummaryLocalizedAlerts,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isLoadingSummaryLocalizedAlerts[action.sliceKey ?? currentSliceKey]
                },
                isSummaryLocalizedAlertsLoaded: {
                    ...state.isSummaryLocalizedAlertsLoaded,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.isSummaryLocalizedAlertsLoaded[action.sliceKey ?? currentSliceKey]
                },
                error: {
                    ...state.error,
                    [action.sliceKey ?? currentSliceKey]: initialLocalizedAlertsState.error[action.sliceKey ?? currentSliceKey]
                }
            };

        default:
            return state;
    }
}
