import { Action } from "@ngrx/store";
import { AlertScan, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption } from "../../_models";

export enum AlertScansActionTypes {
    SetDateRange = '[Alert Scans] Set Date Range',
    SetDrilldownDateRange = '[Alert Scans] Set Drilldown Date Range',
    ResetDrilldownDateRange = '[Alert Scans] Reset Drilldown Date Range',
    
    AddFilters = '[Alert Scans] Add Filters',
    RemoveFilter = '[Alert Scans] Remove Filter',
    OverwriteFilters = '[Alert Scans] Overwrite Filters',
    IncludeActiveScans = '[Alert Scans] Include Active Scans',
    IncludePenTestScans = '[Alert Scans] Include Pen Test Scans',

    FetchCount = '[Alert Scans] Fetch Count',
    FetchCountSuccess = '[Alert Scans] Fetch Count SUCCESS',
    FetchCountFailure = '[Alert Scans] Fetch Count FAILURE',

    SetChartGroupingOption = '[Localized Alerts] Set Chart Grouping Option',
    FetchChart = '[Alert Scans] Fetch Chart',
    FetchChartSuccess = '[Alert Scans] Fetch Chart SUCCESS',
    FetchChartFailure = '[Alert Scans] Fetch Chart FAILURES',

    FetchAlertScans = '[Alert Scans] Fetch Alert Scans',
    FetchAlertScansSuccess = '[Alert Scans] Fetch Alert Scans SUCCESS',
    FetchAlertScansFailure = '[Alert Scans] Fetch Alert Scans FAILURE',
    ResetFetchedAlertScans = '[Alert Scans] Clear Fetched Alert Scans',
    ResetFetchedAlertScansSuccess = '[Alert Scans] Clear Fetched Alert Scans SUCCESS',

    ResetState = '[Alert Scans] Clear State'
};

export class SetDateRange implements Action {
    public readonly type = AlertScansActionTypes.SetDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetDrilldownDateRange implements Action {
    public readonly type = AlertScansActionTypes.SetDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetDrilldownDateRange implements Action {
    public readonly type = AlertScansActionTypes.ResetDrilldownDateRange;
}

export class AddFilters implements Action {
    public readonly type = AlertScansActionTypes.AddFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveFilter implements Action {
    public readonly type = AlertScansActionTypes.RemoveFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteFilters implements Action {
    public readonly type = AlertScansActionTypes.OverwriteFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class IncludeActiveScans implements Action {
    public readonly type = AlertScansActionTypes.IncludeActiveScans;
    constructor(readonly payload: boolean) {}
}
export class IncludePenTestScans implements Action {
    public readonly type = AlertScansActionTypes.IncludePenTestScans;
    constructor(readonly payload: boolean) {}
}

export class FetchCount implements Action {
    public readonly type = AlertScansActionTypes.FetchCount;
}
export class FetchCountSuccess implements Action {
    public readonly type = AlertScansActionTypes.FetchCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchCountFailure implements Action {
    public readonly type = AlertScansActionTypes.FetchCountFailure;
    constructor(readonly payload: Error) {}
}

export class SetChartGroupingOption implements Action {
    public readonly type = AlertScansActionTypes.SetChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchChart implements Action {
    public readonly type = AlertScansActionTypes.FetchChart;
}
export class FetchChartSuccess implements Action {
    public readonly type = AlertScansActionTypes.FetchChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchChartFailure implements Action {
    public readonly type = AlertScansActionTypes.FetchChartFailure;
    constructor(readonly payload: Error) {}
}

export class FetchAlertScans implements Action {
    public readonly type = AlertScansActionTypes.FetchAlertScans;
    constructor(readonly payload?: boolean) {}
}
export class FetchAlertScansSuccess implements Action {
    public readonly type = AlertScansActionTypes.FetchAlertScansSuccess;
    constructor(readonly payload: AlertScan[]) {}
}
export class FetchAlertScansFailure implements Action {
    public readonly type = AlertScansActionTypes.FetchAlertScansFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedAlertScans implements Action {
    public readonly type = AlertScansActionTypes.ResetFetchedAlertScans;
}
export class ResetFetchedAlertScansSuccess implements Action {
    public readonly type = AlertScansActionTypes.ResetFetchedAlertScansSuccess;
}

export class ResetState implements Action {
    public readonly type = AlertScansActionTypes.ResetState;
}

export type AlertScansActions = SetDateRange | SetDrilldownDateRange | ResetDrilldownDateRange
    | AddFilters | RemoveFilter | OverwriteFilters | IncludeActiveScans | IncludePenTestScans
    | FetchCount | FetchCountSuccess | FetchCountFailure
    | SetChartGroupingOption | FetchChart | FetchChartSuccess | FetchChartFailure
    | FetchAlertScans | FetchAlertScansSuccess | FetchAlertScansFailure
    | ResetFetchedAlertScans | ResetFetchedAlertScansSuccess
    | ResetState;