export class AnomalyDetectionAlert {
    adAlertID: number;
    analysisGMTStart: Date;
    clientID: string;
    sourceIP: string[];
    destinationIP: string[];
    destinationPort: number[];
    sourceCountry: string[];
    destinationCountry: string[];
    direction: number[];
    disposition: number[];
    protocol: string[];
    subjectUser: string[];
    targetUser: string[];
    deviceId: string[];
    eventName: string[];
    signature: string[];

    constructor(data: AnomalyDetectionAlertServerResponse) {
        this.adAlertID = data.adAlertID;
        this.analysisGMTStart = new Date(data.analysisGMTStart + 'Z');
        this.clientID = data.clientID;
        this.sourceIP = this.getArrayOfValues(data.sourceIP);
        this.destinationIP = this.getArrayOfValues(data.destinationIP);
        this.destinationPort = this.getArrayOfValues(data.destinationPort);
        this.sourceCountry = this.getArrayOfValues(data.sourceCountry);
        this.destinationCountry = this.getArrayOfValues(data.destinationCountry);
        this.direction = this.getArrayOfValues(data.direction);
        this.disposition = this.getArrayOfValues(data.disposition);
        this.protocol = this.getArrayOfValues(data.protocol);
        this.subjectUser = this.getArrayOfValues(data.subjectUser);
        this.targetUser = this.getArrayOfValues(data.targetUser);
        this.deviceId = this.getArrayOfValues(data.deviceId);
        this.eventName = this.getArrayOfValues(data.eventName);
        this.signature = this.getArrayOfValues(data.signature);
    }

    private getArrayOfValues(values: string): any[] {
        if (!values || values == '*') {
            return [];
        }

        values = values
            // .replace(' ', '')
               .replace('*,', '');


        return values
            .split(',')
            .map(v => v.trim())
            .filter(v => v != '*')
            .filter((v, i, a) => a.indexOf(v) === i);
    }
};

interface AnomalyDetectionAlertServerResponse {
    adAlertID: number;
    analysisGMTStart: string;
    clientID: string;
    destinationCountry: string;
    destinationIP: string;
    destinationPort: string;
    direction: string;
    disposition: string;
    protocol: string;
    sourceCountry: string;
    sourceIP: string;
    subjectUser: string;
    targetUser: string;
    deviceId: string;
    eventName: string;
    signature: string;
};
