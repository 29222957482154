import { Action } from "@ngrx/store";
import { Log, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, EntityProperty, GridState } from "../../_models";

export enum LogsActionTypes {
    PreloadConcierge = '[Logs] Preload Concierge',

    SetDateRange = '[Logs] Set Date Range',
    SetDrilldownDateRange = '[Logs] Set Drilldown Date Range',
    ResetDrilldownDateRange = '[Logs] Reset Drilldown Date Range',

    AddFilters = '[Logs] Add Filters',
    RemoveFilter = '[Logs] Remove Filter',
    OverwriteFilters = '[Logs] Overwrite Filters',

    SetBehaviorType = '[Logs] Set Behavior Type',
    SetUsers = '[Logs] Set Users',
    SetAssets = '[Logs] Set Assets',
    ResetBehaviorProperties = '[Logs] Reset Behavior Properties',

    SetSummaryDateRange = '[Logs] Set Summary Date Range',
    SetSummaryDrilldownDateRange = '[Logs] Set Summary Drilldown Date Range',
    ResetSummaryDrilldownDateRange = '[Logs] Reset Summary Drilldown Date Range',

    AddSummaryFilters = '[Logs] Add Summary Filters',
    RemoveSummaryFilter = '[Logs] Remove Summary Filter',
    OverwriteSummaryFilters = '[Logs] Overwrite Summary Filters',

    FetchCount = '[Logs] Fetch Count',
    FetchCountSuccess = '[Logs] Fetch Count SUCCESS',
    FetchCountFailure = '[Logs] Fetch Count FAILURE',
    FetchSummaryCount = '[Logs] Fetch Summary Count',
    FetchSummaryCountSuccess = '[Logs] Fetch Summary Count SUCCESS',
    FetchSummaryCountFailure = '[Logs] Fetch Summary Count FAILURE',
    FetchFullCount = '[Logs] Fetch Full Count',
    FetchFullCountSuccess = '[Logs] Fetch Full Count Success',
    FetchFullCountFailure = '[Logs] Fetch Full Count Failure',

    SetSummaryEntities = '[Logs] Set Summary Entities',
    FetchSummary = '[Logs] Fetch Summary',
    FetchSummarySuccess = '[Logs] Fetch Summary SUCCESS',
    FetchSummaryFailure = '[Logs] Fetch Summary FAILURE',
    
    SetChartGroupingOption = '[Logs] Set Chart Grouping Option',
    FetchChart = '[Logs] Fetch Chart',
    FetchChartSuccess = '[Logs] Fetch Chart SUCCESS',
    FetchChartFailure = '[Logs] Fetch Chart FAILURES',
    FetchFullChart = '[Logs] Fetch Full Chart',
    FetchFullChartSuccess = '[Logs] Fetch Full Chart SUCCESS',
    FetchFullChartFailure = '[Logs] Fetch Full Chart FAILURES',

    FetchLogs = '[Logs] Fetch Logs',
    FetchLogsSuccess = '[Logs] Fetch Logs SUCCESS',
    FetchLogsFailure = '[Logs] Fetch Logs FAILURE',
    ResetFetchedLogs = '[Logs] Clear Fetched Logs',
    ResetFetchedLogsSuccess = '[Logs] Clear Fetched Logs SUCCESS',
    FetchSummaryLogs = '[Logs] Fetch Summary Logs',
    FetchSummaryLogsSuccess = '[Logs] Fetch Summary Logs SUCCESS',
    FetchSummaryLogsFailure = '[Logs] Fetch Summary Logs FAILURE',
    ResetFetchedSummaryLogs = '[Logs] Clear Fetched Summary Logs',
    ResetFetchedSummaryLogsSuccess = '[Logs] Clear Fetched Summary Logs SUCCESS',
    FetchFullLogs = '[Logs] Fetch Full Logs',
    FetchFullLogsSuccess = '[Logs] Fetch Full Logs SUCCESS',
    FetchFullLogsFailure = '[Logs] Fetch Full Logs FAILURE',
    ResetFetchedFullLogs = '[Logs] Clear Fetched Full Logs',
    ResetFetchedFullLogsSuccess = '[Logs] Clear Fetched Full Logs SUCCESS',
    SetGridState = '[Logs] Set Grid State',
    SetSummaryGridState = '[Logs] Set Summary Grid State',
    SetMasterDetailExpanded = '[Logs] Set Master Detail Expanded State',

    ResetState = '[Logs] Clear State',
    ResetSummaryState = '[Logs] Clear Summary State'
};

export class PreloadConcierge implements Action {
    public readonly type = LogsActionTypes.PreloadConcierge;
    constructor(
        readonly payload: { 
            dateRange: DateRange, 
            dateRangeOption: DateRangeOption,
            drilldownDateRange?: DateRange,
            filterConditions: ConfiguredFilter[],
            chartGroupingOption?: ChartGroupingOption
        },
        readonly sliceKey?: string
    ) {}
}

export class SetDateRange implements Action {
    public readonly type = LogsActionTypes.SetDateRange;
    constructor(
        readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption},
        readonly sliceKey?: string
    ) {}
}
export class SetDrilldownDateRange implements Action {
    public readonly type = LogsActionTypes.SetDrilldownDateRange;
    constructor(readonly payload: DateRange, readonly sliceKey?: string) {}
}
export class ResetDrilldownDateRange implements Action {
    public readonly type = LogsActionTypes.ResetDrilldownDateRange;
    constructor(readonly sliceKey?: string) {}
}

export class AddFilters implements Action {
    public readonly type = LogsActionTypes.AddFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}
export class RemoveFilter implements Action {
    public readonly type = LogsActionTypes.RemoveFilter;
    constructor(readonly payload: ConfiguredFilter, readonly sliceKey?: string) {}
}
export class OverwriteFilters implements Action {
    public readonly type = LogsActionTypes.OverwriteFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}

export class SetBehaviorType implements Action {
    public readonly type = LogsActionTypes.SetBehaviorType;
    constructor(readonly payload: string, readonly sliceKey?: string) {}
}
export class SetUsers implements Action {
    public readonly type = LogsActionTypes.SetUsers;
    constructor(readonly payload: string[], readonly sliceKey?: string) {}
}
export class SetAssets implements Action {
    public readonly type = LogsActionTypes.SetAssets;
    constructor(readonly payload: string[], readonly sliceKey?: string) {}
}
export class ResetBehaviorProperties implements Action {
    public readonly type = LogsActionTypes.ResetBehaviorProperties;
}

export class SetSummaryDateRange implements Action {
    public readonly type = LogsActionTypes.SetSummaryDateRange;
    constructor(
        readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption},
        readonly sliceKey?: string
    ) {}
}
export class SetSummaryDrilldownDateRange implements Action {
    public readonly type = LogsActionTypes.SetSummaryDrilldownDateRange;
    constructor(readonly payload: DateRange, readonly sliceKey?: string) {}
}
export class ResetSummaryDrilldownDateRange implements Action {
    public readonly type = LogsActionTypes.ResetSummaryDrilldownDateRange;
    constructor(readonly sliceKey?: string) {}
}

export class AddSummaryFilters implements Action {
    public readonly type = LogsActionTypes.AddSummaryFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}
export class RemoveSummaryFilter implements Action {
    public readonly type = LogsActionTypes.RemoveSummaryFilter;
    constructor(readonly payload: ConfiguredFilter, readonly sliceKey?: string) {}
}
export class OverwriteSummaryFilters implements Action {
    public readonly type = LogsActionTypes.OverwriteSummaryFilters;
    constructor(readonly payload: ConfiguredFilter[], readonly sliceKey?: string) {}
}

export class FetchCount implements Action {
    public readonly type = LogsActionTypes.FetchCount;
    constructor(readonly sliceKey?: string, readonly overrideWildcards?: boolean) {}
}
export class FetchCountSuccess implements Action {
    public readonly type = LogsActionTypes.FetchCountSuccess;
    constructor(readonly payload: number, readonly sliceKey?: string) {}
}
export class FetchCountFailure implements Action {
    public readonly type = LogsActionTypes.FetchCountFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class FetchSummaryCount implements Action {
    public readonly type = LogsActionTypes.FetchSummaryCount;
    constructor(readonly sliceKey?: string) {}
}
export class FetchSummaryCountSuccess implements Action {
    public readonly type = LogsActionTypes.FetchSummaryCountSuccess;
    constructor(readonly payload: number, readonly sliceKey?: string) {}
}
export class FetchSummaryCountFailure implements Action {
    public readonly type = LogsActionTypes.FetchSummaryCountFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class FetchFullCount implements Action {
    public readonly type = LogsActionTypes.FetchFullCount;
    constructor(readonly sliceKey?: string) {}
}
export class FetchFullCountSuccess implements Action {
    public readonly type = LogsActionTypes.FetchFullCountSuccess;
    constructor(readonly payload: number, readonly sliceKey?: string) {}
}
export class FetchFullCountFailure implements Action {
    public readonly type = LogsActionTypes.FetchFullCountFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}

export class SetSummaryEntities implements Action {
    public readonly type = LogsActionTypes.SetSummaryEntities;
    constructor(readonly payload: EntityProperty[], readonly sliceKey?: string) {}
}
export class FetchSummary implements Action {
    public readonly type = LogsActionTypes.FetchSummary;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchSummarySuccess implements Action {
    public readonly type = LogsActionTypes.FetchSummarySuccess;
    constructor(readonly payload: any[], readonly sliceKey?: string) {}
}
export class FetchSummaryFailure implements Action {
    public readonly type = LogsActionTypes.FetchSummaryFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class SetSummaryGridState implements Action {
    public readonly type = LogsActionTypes.SetSummaryGridState;
    constructor(readonly payload: GridState, readonly sliceKey?: string) {}
}

export class SetChartGroupingOption implements Action {
    public readonly type = LogsActionTypes.SetChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption, readonly sliceKey?: string) {}
}
export class FetchChart implements Action {
    public readonly type = LogsActionTypes.FetchChart;
    constructor(readonly payload?: boolean, readonly sliceKey?: string, readonly overrideWildcards?: boolean) {}
}
export class FetchChartSuccess implements Action {
    public readonly type = LogsActionTypes.FetchChartSuccess;
    constructor(readonly payload: any[], readonly sliceKey?: string) {}
}
export class FetchChartFailure implements Action {
    public readonly type = LogsActionTypes.FetchChartFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class FetchFullChart implements Action {
    public readonly type = LogsActionTypes.FetchFullChart;
    constructor(readonly sliceKey?: string) {}
}
export class FetchFullChartSuccess implements Action {
    public readonly type = LogsActionTypes.FetchFullChartSuccess;
    constructor(readonly payload: any[], readonly sliceKey?: string) {}
}
export class FetchFullChartFailure implements Action {
    public readonly type = LogsActionTypes.FetchFullChartFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}

export class FetchLogs implements Action {
    public readonly type = LogsActionTypes.FetchLogs;
    constructor(readonly payload?: boolean, readonly sliceKey?: string, readonly overrideWildcards?: boolean) {}
}
export class FetchLogsSuccess implements Action {
    public readonly type = LogsActionTypes.FetchLogsSuccess;
    constructor(readonly payload: Log[], readonly sliceKey?: string) {}
}
export class FetchLogsFailure implements Action {
    public readonly type = LogsActionTypes.FetchLogsFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class ResetFetchedLogs implements Action {
    public readonly type = LogsActionTypes.ResetFetchedLogs;
    constructor(readonly sliceKey?: string) {}
}
export class ResetFetchedLogsSuccess implements Action {
    public readonly type = LogsActionTypes.ResetFetchedLogsSuccess;
    constructor(readonly sliceKey?: string) {}
}
export class FetchSummaryLogs implements Action {
    public readonly type = LogsActionTypes.FetchSummaryLogs;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchSummaryLogsSuccess implements Action {
    public readonly type = LogsActionTypes.FetchSummaryLogsSuccess;
    constructor(readonly payload: Log[], readonly sliceKey?: string) {}
}
export class FetchSummaryLogsFailure implements Action {
    public readonly type = LogsActionTypes.FetchSummaryLogsFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class ResetFetchedSummaryLogs implements Action {
    public readonly type = LogsActionTypes.ResetFetchedSummaryLogs;
    constructor(readonly sliceKey?: string) {}
}
export class ResetFetchedSummaryLogsSuccess implements Action {
    public readonly type = LogsActionTypes.ResetFetchedSummaryLogsSuccess;
    constructor(readonly sliceKey?: string) {}
}

export class FetchFullLogs implements Action {
    public readonly type = LogsActionTypes.FetchFullLogs;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {}
}
export class FetchFullLogsSuccess implements Action {
    public readonly type = LogsActionTypes.FetchFullLogsSuccess;
    constructor(readonly payload: Log[], readonly sliceKey?: string) {}
}
export class FetchFullLogsFailure implements Action {
    public readonly type = LogsActionTypes.FetchFullLogsFailure;
    constructor(readonly payload: Error, readonly sliceKey?: string) {}
}
export class ResetFetchedFullLogs implements Action {
    public readonly type = LogsActionTypes.ResetFetchedFullLogs;
    constructor(readonly sliceKey?: string) {}
}
export class ResetFetchedFullLogsSuccess implements Action {
    public readonly type = LogsActionTypes.ResetFetchedFullLogsSuccess;
    constructor(readonly sliceKey?: string) {}
}

export class SetGridState implements Action {
    public readonly type = LogsActionTypes.SetGridState;
    constructor(readonly payload: GridState, readonly sliceKey?: string) {}
}

export class SetMasterDetailExpanded implements Action {
    public readonly type = LogsActionTypes.SetMasterDetailExpanded;
    constructor(readonly isExpanded: boolean, readonly sliceKey?: string) {}
}

export class ResetState implements Action {
    public readonly type = LogsActionTypes.ResetState;
    constructor(readonly payload?: boolean, readonly sliceKey?: string) {} // determine whether to skip wiping behavior properties
}
export class ResetSummaryState implements Action {
    public readonly type = LogsActionTypes.ResetSummaryState;
    constructor(readonly sliceKey?: string) {}
}

export type LogsActions = PreloadConcierge 
    | SetDateRange | SetDrilldownDateRange | ResetDrilldownDateRange
    | AddFilters | RemoveFilter | OverwriteFilters
    | SetBehaviorType | SetUsers | SetAssets | ResetBehaviorProperties
    | SetSummaryDateRange | SetSummaryDrilldownDateRange | ResetSummaryDrilldownDateRange
    | AddSummaryFilters | RemoveSummaryFilter | OverwriteSummaryFilters
    | FetchCount | FetchCountSuccess | FetchCountFailure
    | FetchSummaryCount | FetchSummaryCountSuccess | FetchSummaryCountFailure
    | FetchFullCount | FetchFullCountSuccess | FetchFullCountFailure
    | SetSummaryEntities | FetchSummary | FetchSummarySuccess | FetchSummaryFailure | SetSummaryGridState
    | SetChartGroupingOption | FetchChart | FetchChartSuccess | FetchChartFailure
    | FetchFullChart | FetchFullChartSuccess | FetchFullChartFailure
    | FetchLogs | FetchLogsSuccess | FetchLogsFailure 
    | SetGridState | SetMasterDetailExpanded
    | ResetFetchedLogs | ResetFetchedLogsSuccess
    | FetchSummaryLogs | FetchSummaryLogsSuccess | FetchSummaryLogsFailure
    | FetchFullLogs | FetchFullLogsSuccess | FetchFullLogsFailure
    | ResetFetchedSummaryLogs | ResetFetchedSummaryLogsSuccess 
    | ResetFetchedFullLogsSuccess | ResetFetchedFullLogs
    | ResetState | ResetSummaryState;
