import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ChartGroupingOption, DateRange, ConfiguredFilter, StoredFilterConfiguration, EntityProperty, SavedScrollState } from '../../_models';
import { DateTimeService } from '../datetime.service';
import { AppState } from '../../_store/state/app.state';
import { Store } from '@ngrx/store';

@Injectable({
    providedIn: 'root'
})
export class StoredConciergeService {

    private savedScrollPositions: SavedScrollState[] = [];
    private previousLogsFiltersJson: string = null;

    constructor(
        private dateTimeService: DateTimeService,
        private http: HttpClient
    ) {}

    static getDistinctConfiguredFilters(filters: ConfiguredFilter[]): ConfiguredFilter[] {
        const presentationFilters = filters.filter(f => !f.conditions && f.display.length > 0 );
        const validFilters = filters
            .filter(f => f.conditions &&  f.display.length > 0)
            .filter((value, index, self) => 
                self.map(x => x.display + x.conditions.map(c => c.value).join(','))
                    .indexOf(value.display + value.conditions.map(c => c.value).join(',')) === index);

        if (presentationFilters.length) {
            validFilters.push(presentationFilters[presentationFilters.length - 1]);
        }
        return validFilters;
    }

    addSavedScrollState(url: string) {
        if (!url) {
            return;
        }

        this.savedScrollPositions.push({
            url: url,
            xPosition: window.pageXOffset,
            yPosition: window.pageYOffset
        });
    }

    getSavedScrollState(url: string) {
        for (var i = 0; i < this.savedScrollPositions.length; i++) {
            let scrollPosition = this.savedScrollPositions[i];
            if (scrollPosition.url === url) {
                setTimeout(() => { window.scrollTo(scrollPosition.xPosition, scrollPosition.yPosition) }, 750);
                this.savedScrollPositions.splice(i, 1);
                break;
            }
        }
    }

    clearSavedScrollStates() {
        this.savedScrollPositions.length = 0;
    }

    getManagedAssets(): Observable<any> {
        return this.http.post('/Asset/GetManagedBySODAssets', '');
    }

    getConciergeRequestBody(dateRange: DateRange, filters: ConfiguredFilter[]): any {
        const params = {
            maxLimit: false,
            startDateUtc: dateRange.startDateUtc,
            endDateUtc: dateRange.endDateUtc,
            timePeriod: dateRange.timePeriod,
            label: dateRange.label,
            conditions: filters
                .filter((configuredFilter) => !!configuredFilter.conditions)
                .map((configuredFilter) => {
                    configuredFilter.conditions.forEach(c => {
                        if (c.operation === '≠') {
                            c.operation = '<>';
                        }
                    })

                    return configuredFilter.conditions;
                })
        };

        return params;
    }

    getLogsConciergeRequestBody(dateRange: DateRange, filters: ConfiguredFilter[], overrideWildcards?: boolean): any {
        let body = this.getConciergeRequestBody(dateRange, filters);
        let filtersJson = JSON.stringify(filters);

        if (overrideWildcards || this.previousLogsFiltersJson === filtersJson) {
            this.previousLogsFiltersJson = filtersJson;
            body.removeWildcardsFromQuery = true;
        }

        return body;
    }

    // HACK
    // Adding a new optional parameter, so we could have the start and end date on the clicked filter
    // when you remove them.
    getTimeFilterConditions(startDateUtc: any, endDateUtc: any, data?: any): ConfiguredFilter[] {
        return [
            {
                display: `*Time BETWEEN ${this.dateTimeService.formatDateString(this.dateTimeService.getOffsetDateTimeLocaleString(startDateUtc))} AND ${this.dateTimeService.formatDateString(this.dateTimeService.getOffsetDateTimeLocaleString(endDateUtc))}`,
                extraData: {
                        
                    startDateUtc,
                    endDateUtc,
                    timePeriod: data?.timePeriod,
                    label: data?.label
                },
                conditions: null
            }
        ];
    } 

    getDrilldownParameters(dateRange: DateRange, selectedDate: Date, chartGrouping: ChartGroupingOption, selectedValue: string): { dateRange: DateRange, filters: ConfiguredFilter[] } {
        let startDateUtc = new Date(selectedDate);
        let endDateUtc = new Date(selectedDate);
        let timePeriod = dateRange.timePeriod;

        switch (dateRange.timePeriod) {
            case 'second':
                return;
            case 'minute':
                endDateUtc.setUTCSeconds(59);
                timePeriod = 'second';
                break;
            case 'hour':
                endDateUtc.setUTCSeconds(59);
                endDateUtc.setUTCMinutes(59);
                timePeriod = 'minute';
                break;
            case 'day':
                endDateUtc.setUTCHours(endDateUtc.getUTCHours() + 23);
                endDateUtc.setUTCMinutes(59);
                endDateUtc.setUTCSeconds(59);
                timePeriod = 'hour';
                break;
            case 'month':
                endDateUtc.setUTCDate(endDateUtc.getUTCDate() + 29);
                endDateUtc.setUTCHours(endDateUtc.getUTCHours() + 23);
                endDateUtc.setUTCMinutes(59);
                endDateUtc.setUTCSeconds(59);
                timePeriod = 'day';
                break;
        }

        const drilldownDateRange = {
            startDateUtc: this.dateTimeService.toString(startDateUtc),
            endDateUtc: this.dateTimeService.toString(endDateUtc),
            timePeriod,
            label: timePeriod.charAt(0).toUpperCase() + timePeriod.slice(1) + 's',
            fullLabel: () => `${timePeriod.charAt(0).toUpperCase()}${timePeriod.slice(1)}s: ${this.dateTimeService.formatDateString(this.dateTimeService.getOffsetDateTimeLocaleString(startDateUtc))} - ${this.dateTimeService.formatDateString(this.dateTimeService.getOffsetDateTimeLocaleString(endDateUtc))}`
        };

        const filters = this.getTimeFilterConditions(startDateUtc, endDateUtc, drilldownDateRange);

        if (chartGrouping && chartGrouping.value) {
            filters.push({
                display: chartGrouping.name,
                conditions: [
                    { 
                        filterType: chartGrouping.value.filterType,
                        operation: '=',
                        columns: [chartGrouping.value.columnName],
                        value: selectedValue
                    }
                ]
            })
        };

        return {
            dateRange: drilldownDateRange,
            filters
        };
    }

    getFilteredConfiguredFiltersSelector(store: Store<AppState>, selector: any, defaultFilters: ConfiguredFilter[]) {
        const defaultFilterDisplayStrings = defaultFilters.map(d => d.display);
        return store.select(selector).pipe(
            map((configuredFilters: ConfiguredFilter[]) => configuredFilters.filter(c => !defaultFilterDisplayStrings.includes(c.display)))
        );
    }

    getFilteredFilterConfiguration(filterConfigurations: StoredFilterConfiguration[], defaultFilters: ConfiguredFilter[]): StoredFilterConfiguration[] {
        const defaultFilterDisplayStrings = defaultFilters.map(d => d.display);
        return filterConfigurations.filter(c => !c.entity || !defaultFilterDisplayStrings.includes(c.entity.displayName));
    }

    setInitialSummaryEntities(initialEntities: EntityProperty[], summaryEntities: any[]) {
        initialEntities.forEach((entity) => {
            const match = summaryEntities.find((summaryEntity) => summaryEntity.entity.columnName == entity.columnName);
            match.initial = true;
        });
    }

    getConfiguredFilterFromEntity(entity: EntityProperty, operation: string, value: any): ConfiguredFilter {
        return {
            display: entity.displayName,
            conditions: [{
              columns: [entity.columnName],
              operation,
              value,
              filterType: entity.filterType
            }]
        };
    }
  
}
