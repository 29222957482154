import { Component } from '@angular/core';

@Component({
  template: 'Pong'
})
export class PingComponent {

  constructor() {
  }

}
