import { Directive, ViewContainerRef } from '@angular/core';
import { ModalService } from '../_services/modal.service';

@Directive({
  selector: '[appModalContainer]'
})
export class ModalContainerDirective {

  private service: ModalService;

  constructor(viewContainerRef: ViewContainerRef, service: ModalService) {
    this.service = service;
    this.service.setViewContainer(viewContainerRef);
  }

}
