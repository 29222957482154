import { Action } from "@ngrx/store";
import { ThreatWatchAlert, DateRange, DateRangeOption, ConfiguredFilter, ChartGroupingOption, CategoryCounts, LocalizedAlert, Log, BehavioralResource, BehaviorData, Behavior } from "../../_models";

export enum UserBehaviorActionTypes {
    SetSeedResource = '[User Behavior] Set Seed Resource',
    SetAssociatedResources = '[User Behavior] Set Associated Resources',
    FetchAssociatedResources = '[User Behavior] Fetch Associated Resources',
    FetchAssociatedResourcesSuccess = '[User Behavior] Fetch Associated Resources SUCCESS',
    FetchAssociatedResourcesFailure = '[User Behavior] Fetch Associated Resources FAILURE',
    
    SetDashboardIndex = '[User Behavior] Set Dashboard Index',
    FetchDashboard = '[User Behavior] Fetch Dashboard',
    FetchDashboardSuccess = '[User Behavior] Fetch Dashboard SUCCESS',
    FetchDashboardFailure = '[User Behavior] Fetch Dashboard Failure',

    SetDateRange = '[User Behavior] Set Date Range',

    FetchTimeline = '[User Behavior] Fetch Timeline',
    FetchTimelineSuccess = '[User Behavior] Fetch Timeline SUCCESS',
    FetchTimelineFailure = '[User Behavior] Fetch Timeline FAILURE',

    SetAnalyticsDateRange = '[User Behavior] Set Analytics Date Range',
    SetAnalyticsDrilldownDateRange = '[User Behavior] Set Analytics Drilldown Date Range',
    ResetAnalyticsDrilldownDateRange = '[User Behavior] Reset Analytics Drilldown Date Range',
    AddAnalyticsFilters = '[User Behavior] Add Analytics Filters',
    RemoveAnalyticsFilter = '[User Behavior] Remove Analytics Filter',
    OverwriteAnalyticsFilters = '[User Behavior] Overwrite Analytics Filters',
    FetchAnalyticsCount = '[User Behavior] Fetch Analytics Count',
    FetchAnalyticsCountSuccess = '[User Behavior] FetchAnalytics  Count SUCCESS',
    FetchAnalyticsCountFailure = '[User Behavior] Fetch Analytics Count FAILURE',
    FetchAnalyticsCounts = '[User Behavior] Fetch Analytics Counts',
    FetchAnalyticsCountsSuccess = '[User Behavior] Fetch Analytics Counts SUCCESS',
    FetchAnalyticsCountsFailure = '[User Behavior] Fetch Analytics Counts FAILURE',
    SetAnalyticsChartGroupingOption = '[User Behavior] Set Analytics Chart Grouping Option',
    FetchAnalyticsChart = '[User Behavior] Fetch Analytics Chart',
    FetchAnalyticsChartSuccess = '[User Behavior] Fetch Analytics Chart SUCCESS',
    FetchAnalyticsChartFailure = '[User Behavior] Fetch Analytics Chart FAILURES',
    FetchThreatWatchAlerts = '[User Behavior] Fetch ThreatWatch Alerts',
    FetchThreatWatchAlertsSuccess = '[User Behavior] Fetch ThreatWatch Alerts SUCCESS',
    FetchThreatWatchAlertsFailure = '[User Behavior] Fetch ThreatWatch Alerts FAILURE',
    ResetFetchedThreatWatchAlerts = '[User Behavior] Clear Fetched ThreatWatch Alerts',
    ResetFetchedThreatWatchAlertsSuccess = '[User Behavior] Clear Fetched ThreatWatch Alerts SUCCESS',
    ResetAnalyticsState = '[User Behavior] Clear Analytics State',

    SetLocalizedAlertsDateRange = '[User Behavior] Set Localized Alerts Date Range',
    SetLocalizedAlertsDrilldownDateRange = '[User Behavior] Set Localized Alerts Drilldown Date Range',
    ResetLocalizedAlertsDrilldownDateRange = '[User Behavior] Reset Localized Alerts Drilldown Date Range',
    AddLocalizedAlertsFilters = '[User Behavior] Add Localized Alerts Filters',
    RemoveLocalizedAlertsFilter = '[User Behavior] Remove Localized Alerts Filter',
    OverwriteLocalizedAlertsFilters = '[User Behavior] Overwrite Localized Alerts Filters',
    FetchLocalizedAlertsCount = '[User Behavior] Fetch Localized Alerts Count',
    FetchLocalizedAlertsCountSuccess = '[User Behavior] Fetch Localized Alerts Count SUCCESS',
    FetchLocalizedAlertsCountFailure = '[User Behavior] Fetch Localized Alerts Count FAILURE',
    SetLocalizedAlertsChartGroupingOption = '[User Behavior] Set Localized Alerts Chart Grouping Option',
    FetchLocalizedAlertsChart = '[User Behavior] Fetch Localized Alerts Chart',
    FetchLocalizedAlertsChartSuccess = '[User Behavior] Fetch Localized Alerts Chart SUCCESS',
    FetchLocalizedAlertsChartFailure = '[User Behavior] Fetch Localized Alerts Chart FAILURES',
    FetchLocalizedAlerts = '[User Behavior] Fetch Localized Alerts',
    FetchLocalizedAlertsSuccess = '[User Behavior] Fetch Localized Alerts SUCCESS',
    FetchLocalizedAlertsFailure = '[User Behavior] Fetch Localized Alerts FAILURE',
    ResetFetchedLocalizedAlerts = '[User Behavior] Clear Fetched Localized Alerts',
    ResetFetchedLocalizedAlertsSuccess = '[User Behavior] Clear Fetched Localized Alerts SUCCESS',
    ResetLocalizedAlertsState = '[User Behavior] Clear Localized Alerts State',

    SetConnectionsDateRange = '[User Behavior] Set Connections Date Range',
    SetConnectionsDrilldownDateRange = '[User Behavior] Set Connections Drilldown Date Range',
    ResetConnectionsDrilldownDateRange = '[User Behavior] Reset Connections Drilldown Date Range',
    AddConnectionsFilters = '[User Behavior] Add Connections Filters',
    RemoveConnectionsFilter = '[User Behavior] Remove Connections Filter',
    OverwriteConnectionsFilters = '[Analytics] Overwrite Connections Filters',
    FetchConnectionsCount = '[User Behavior] Fetch Connections Count',
    FetchConnectionsCountSuccess = '[User Behavior] Fetch Connections Count SUCCESS',
    FetchConnectionsCountFailure = '[User Behavior] Fetch Connections Count FAILURE',
    SetConnectionsChartGroupingOption = '[User Behavior] Set Connections Chart Grouping Option',
    FetchConnectionsChart = '[User Behavior] Fetch Connections Chart',
    FetchConnectionsChartSuccess = '[User Behavior] Fetch Connections Chart SUCCESS',
    FetchConnectionsChartFailure = '[User Behavior] Fetch Connections Chart FAILURES',
    FetchConnections = '[User Behavior] Fetch Connections',
    FetchConnectionsSuccess = '[User Behavior] Fetch Connections SUCCESS',
    FetchConnectionsFailure = '[User Behavior] Fetch Connections FAILURE',
    ResetFetchedConnections = '[User Behavior] Clear Fetched Connections',
    ResetFetchedConnectionsSuccess = '[User Behavior] Clear Fetched Connections SUCCESS',
    ResetConnectionsState = '[User Behavior] Clear Connections State',

    SetEventsOfInterestDateRange = '[User Behavior] Set Events of Interest Date Range',
    SetEventsOfInterestDrilldownDateRange = '[User Behavior] Set Events of Interest Drilldown Date Range',
    ResetEventsOfInterestDrilldownDateRange = '[User Behavior] Reset Events of Interest Drilldown Date Range',
    AddEventsOfInterestFilters = '[User Behavior] Add Events of Interest Filters',
    RemoveEventsOfInterestFilter = '[User Behavior] Remove Events of Interest Filter',
    OverwriteEventsOfInterestFilters = '[Analytics] Overwrite Events of Interest Filters',
    FetchEventsOfInterestCount = '[User Behavior] Fetch Events of Interest Count',
    FetchEventsOfInterestCountSuccess = '[User Behavior] Fetch Events of Interest Count SUCCESS',
    FetchEventsOfInterestCountFailure = '[User Behavior] Fetch Events of Interest Count FAILURE',
    SetEventsOfInterestChartGroupingOption = '[User Behavior] Set Events of Interest Chart Grouping Option',
    FetchEventsOfInterestChart = '[User Behavior] Fetch Events of Interest Chart',
    FetchEventsOfInterestChartSuccess = '[User Behavior] Fetch Events of Interest Chart SUCCESS',
    FetchEventsOfInterestChartFailure = '[User Behavior] Fetch Events of Interest Chart FAILURES',
    FetchEventsOfInterest = '[User Behavior] Fetch Events Of Interest',
    FetchEventsOfInterestSuccess = '[User Behavior] Fetch Events Of Interest SUCCESS',
    FetchEventsOfInterestFailure = '[User Behavior] Fetch Events Of Interest FAILURE',
    ResetFetchedEventsOfInterest = '[User Behavior] Clear Fetched Events Of Interest',
    ResetFetchedEventsOfInterestSuccess = '[User Behavior] Clear Fetched Events Of Interest SUCCESS',
    ResetEventsOfInterestState = '[User Behavior] Clear Events of Interest State',
};

export class SetSeedResource implements Action {
    public readonly type = UserBehaviorActionTypes.SetSeedResource;
    constructor(readonly payload: string) {}
}
export class SetAssociatedResources implements Action {
    public readonly type = UserBehaviorActionTypes.SetAssociatedResources;
    constructor(readonly payload: BehavioralResource[]) {}
}

export class FetchAssociatedResources implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAssociatedResources;
    constructor(readonly payload: boolean) {}
}
export class FetchAssociatedResourcesSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAssociatedResourcesSuccess;
    constructor(readonly payload: BehavioralResource[]) {}
}
export class FetchAssociatedResourcesFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAssociatedResourcesFailure;
    constructor(readonly payload: any) {}
}

export class SetDashboardIndex implements Action {
    public readonly type = UserBehaviorActionTypes.SetDashboardIndex;
    constructor(readonly payload: number) {}
}
export class FetchDashboard implements Action {
    public readonly type = UserBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchDashboardSuccess;
    constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) {}
}
export class FetchDashboardFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchDashboardFailure;
    constructor(readonly payload: any) {}
}

export class FetchDashboardAlerts implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardAlertsSuccess implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardAlertsFailure implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}

export class FetchDashboardEvents implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardEventsSuccess implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardEventsFailure implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}

export class FetchDashboardConnections implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboard;
}
export class FetchDashboardConnectionsSuccess implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardSuccess;
  constructor(readonly payload: { dashboardData: BehaviorData, behaviors: Behavior[] }) { }
}
export class FetchDashboardConnectionsFailure implements Action {
  public readonly type = UserBehaviorActionTypes.FetchDashboardFailure;
  constructor(readonly payload: any) { }
}

export class SetDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}

export class FetchTimeline implements Action {
    public readonly type = UserBehaviorActionTypes.FetchTimeline;
}
export class FetchTimelineSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchTimelineSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchTimelineFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchTimelineFailure;
    constructor(readonly error: any[]) {}
}

export class SetAnalyticsDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetAnalyticsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetAnalyticsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetAnalyticsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetAnalyticsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.ResetAnalyticsDrilldownDateRange;
}
export class AddAnalyticsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.AddAnalyticsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveAnalyticsFilter implements Action {
    public readonly type = UserBehaviorActionTypes.RemoveAnalyticsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteAnalyticsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.OverwriteAnalyticsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchAnalyticsCount implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCount;
}
export class FetchAnalyticsCountSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchAnalyticsCountFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCountFailure;
    constructor(readonly payload: Error) {}
}
export class FetchAnalyticsCounts implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCounts;
}
export class FetchAnalyticsCountsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCountsSuccess;
    constructor(readonly payload: CategoryCounts) {}
}
export class FetchAnalyticsCountsFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsCountsFailure;
    constructor(readonly payload: Error) {}
}
export class SetAnalyticsChartGroupingOption implements Action {
    public readonly type = UserBehaviorActionTypes.SetAnalyticsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchAnalyticsChart implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsChart;
}
export class FetchAnalyticsChartSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchAnalyticsChartFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchAnalyticsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchThreatWatchAlerts implements Action {
    public readonly type = UserBehaviorActionTypes.FetchThreatWatchAlerts;
    constructor(readonly payload?: boolean) {}
}
export class FetchThreatWatchAlertsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchThreatWatchAlertsSuccess;
    constructor(readonly payload: ThreatWatchAlert[]) {}
}
export class FetchThreatWatchAlertsFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchThreatWatchAlertsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedThreatWatchAlerts implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedThreatWatchAlerts;
}
export class ResetFetchedThreatWatchAlertsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedThreatWatchAlertsSuccess;
}
export class ResetAnalyticsState implements Action {
    public readonly type = UserBehaviorActionTypes.ResetAnalyticsState;
}

export class SetLocalizedAlertsDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetLocalizedAlertsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetLocalizedAlertsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetLocalizedAlertsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetLocalizedAlertsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.ResetLocalizedAlertsDrilldownDateRange;
}
export class AddLocalizedAlertsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.AddLocalizedAlertsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveLocalizedAlertsFilter implements Action {
    public readonly type = UserBehaviorActionTypes.RemoveLocalizedAlertsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteLocalizedAlertsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.OverwriteLocalizedAlertsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchLocalizedAlertsCount implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsCount;
}
export class FetchLocalizedAlertsCountSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchLocalizedAlertsCountFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetLocalizedAlertsChartGroupingOption implements Action {
    public readonly type = UserBehaviorActionTypes.SetLocalizedAlertsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchLocalizedAlertsChart implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsChart;
}
export class FetchLocalizedAlertsChartSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchLocalizedAlertsChartFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchLocalizedAlerts implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlerts;
    constructor(readonly payload?: boolean) {}
}
export class FetchLocalizedAlertsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsSuccess;
    constructor(readonly payload: LocalizedAlert[]) {}
}
export class FetchLocalizedAlertsFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchLocalizedAlertsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedLocalizedAlerts implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedLocalizedAlerts;
}
export class ResetFetchedLocalizedAlertsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedLocalizedAlertsSuccess;
}
export class ResetLocalizedAlertsState implements Action {
    public readonly type = UserBehaviorActionTypes.ResetLocalizedAlertsState;
}

export class SetConnectionsDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetConnectionsDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetConnectionsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetConnectionsDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetConnectionsDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.ResetConnectionsDrilldownDateRange;
}
export class AddConnectionsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.AddConnectionsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveConnectionsFilter implements Action {
    public readonly type = UserBehaviorActionTypes.RemoveConnectionsFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteConnectionsFilters implements Action {
    public readonly type = UserBehaviorActionTypes.OverwriteConnectionsFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchConnectionsCount implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsCount;
}
export class FetchConnectionsCountSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchConnectionsCountFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetConnectionsChartGroupingOption implements Action {
    public readonly type = UserBehaviorActionTypes.SetConnectionsChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchConnectionsChart implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsChart;
}
export class FetchConnectionsChartSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchConnectionsChartFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchConnections implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnections;
    constructor(readonly payload?: boolean) {}
}
export class FetchConnectionsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsSuccess;
    constructor(readonly payload: Log[]) {}
}
export class FetchConnectionsFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchConnectionsFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedConnections implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedConnections;
}
export class ResetFetchedConnectionsSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedConnectionsSuccess;
}

export class ResetConnectionsState implements Action {
    public readonly type = UserBehaviorActionTypes.ResetConnectionsState;
}

export class SetEventsOfInterestDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetEventsOfInterestDateRange;
    constructor(readonly payload: {dateRange: DateRange, dateRangeOption: DateRangeOption}) {}
}
export class SetEventsOfInterestDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.SetEventsOfInterestDrilldownDateRange;
    constructor(readonly payload: DateRange) {}
}
export class ResetEventsOfInterestDrilldownDateRange implements Action {
    public readonly type = UserBehaviorActionTypes.ResetEventsOfInterestDrilldownDateRange;
}
export class AddEventsOfInterestFilters implements Action {
    public readonly type = UserBehaviorActionTypes.AddEventsOfInterestFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class RemoveEventsOfInterestFilter implements Action {
    public readonly type = UserBehaviorActionTypes.RemoveEventsOfInterestFilter;
    constructor(readonly payload: ConfiguredFilter) {}
}
export class OverwriteEventsOfInterestFilters implements Action {
    public readonly type = UserBehaviorActionTypes.OverwriteEventsOfInterestFilters;
    constructor(readonly payload: ConfiguredFilter[]) {}
}
export class FetchEventsOfInterestCount implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestCount;
}
export class FetchEventsOfInterestCountSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestCountSuccess;
    constructor(readonly payload: number) {}
}
export class FetchEventsOfInterestCountFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestCountFailure;
    constructor(readonly payload: Error) {}
}
export class SetEventsOfInterestChartGroupingOption implements Action {
    public readonly type = UserBehaviorActionTypes.SetEventsOfInterestChartGroupingOption;
    constructor(readonly payload: ChartGroupingOption) {}
}
export class FetchEventsOfInterestChart implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestChart;
}
export class FetchEventsOfInterestChartSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestChartSuccess;
    constructor(readonly payload: any[]) {}
}
export class FetchEventsOfInterestChartFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestChartFailure;
    constructor(readonly payload: Error) {}
}
export class FetchEventsOfInterest implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterest;
    constructor(readonly payload?: boolean) {}
}
export class FetchEventsOfInterestSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestSuccess;
    constructor(readonly payload: Log[]) {}
}
export class FetchEventsOfInterestFailure implements Action {
    public readonly type = UserBehaviorActionTypes.FetchEventsOfInterestFailure;
    constructor(readonly payload: Error) {}
}
export class ResetFetchedEventsOfInterest implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedEventsOfInterest;
}
export class ResetFetchedEventsOfInterestSuccess implements Action {
    public readonly type = UserBehaviorActionTypes.ResetFetchedEventsOfInterestSuccess;
}

export class ResetEventsOfInterestState implements Action {
    public readonly type = UserBehaviorActionTypes.ResetEventsOfInterestState;
}

export type UserBehaviorActions = SetSeedResource | SetAssociatedResources
    | FetchAssociatedResources | FetchAssociatedResourcesSuccess | FetchAssociatedResourcesFailure
    | SetDashboardIndex | FetchDashboard | FetchDashboardSuccess | FetchDashboardFailure
    | SetDateRange
    | FetchTimeline | FetchTimelineSuccess | FetchTimelineFailure

    | SetAnalyticsDateRange | SetAnalyticsDrilldownDateRange | ResetAnalyticsDrilldownDateRange
    | AddAnalyticsFilters | RemoveAnalyticsFilter | OverwriteAnalyticsFilters
    | FetchAnalyticsCount | FetchAnalyticsCountSuccess | FetchAnalyticsCountFailure 
    | FetchAnalyticsCounts | FetchAnalyticsCountsSuccess | FetchAnalyticsCountsFailure
    | SetAnalyticsChartGroupingOption | FetchAnalyticsChart | FetchAnalyticsChartSuccess | FetchAnalyticsChartFailure
    | FetchThreatWatchAlerts | FetchThreatWatchAlertsSuccess | FetchThreatWatchAlertsFailure
    | ResetFetchedThreatWatchAlerts | ResetFetchedThreatWatchAlertsSuccess
    | ResetAnalyticsState
    
    | SetLocalizedAlertsDateRange | SetLocalizedAlertsDrilldownDateRange | ResetLocalizedAlertsDrilldownDateRange
    | AddLocalizedAlertsFilters | RemoveLocalizedAlertsFilter | OverwriteLocalizedAlertsFilters
    | FetchLocalizedAlertsCount | FetchLocalizedAlertsCountSuccess | FetchLocalizedAlertsCountFailure
    | SetLocalizedAlertsChartGroupingOption | FetchLocalizedAlertsChart | FetchLocalizedAlertsChartSuccess | FetchLocalizedAlertsChartFailure
    | FetchLocalizedAlerts | FetchLocalizedAlertsSuccess | FetchLocalizedAlertsFailure
    | ResetFetchedLocalizedAlerts | ResetFetchedLocalizedAlertsSuccess
    | ResetLocalizedAlertsState
    
    | SetConnectionsDateRange | SetConnectionsDrilldownDateRange | ResetConnectionsDrilldownDateRange
    | AddConnectionsFilters | RemoveConnectionsFilter | OverwriteConnectionsFilters
    | FetchConnectionsCount | FetchConnectionsCountSuccess | FetchConnectionsCountFailure
    | SetConnectionsChartGroupingOption | FetchConnectionsChart | FetchConnectionsChartSuccess | FetchConnectionsChartFailure
    | FetchConnections | FetchConnectionsSuccess | FetchConnectionsFailure
    | ResetFetchedConnections | ResetFetchedConnectionsSuccess
    | ResetConnectionsState
    
    | SetEventsOfInterestDateRange | SetEventsOfInterestDrilldownDateRange | ResetEventsOfInterestDrilldownDateRange
    | AddEventsOfInterestFilters | RemoveEventsOfInterestFilter | OverwriteEventsOfInterestFilters
    | FetchEventsOfInterestCount | FetchEventsOfInterestCountSuccess | FetchEventsOfInterestCountFailure
    | SetEventsOfInterestChartGroupingOption | FetchEventsOfInterestChart | FetchEventsOfInterestChartSuccess | FetchEventsOfInterestChartFailure
    | FetchEventsOfInterest | FetchEventsOfInterestSuccess | FetchEventsOfInterestFailure
    | ResetFetchedEventsOfInterest | ResetFetchedEventsOfInterestSuccess
    | ResetEventsOfInterestState;
